import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMeeting from '../reducers/meeting.reducer';
import * as fromMeetingCategory from './../reducers/meeting.reducer';
import * as fromMeetingType from './../reducers/meeting.reducer';

import { adapter as endedAdapter } from '../reducers/meeting-ended.reducer';
import { adapter as idleAdapter } from '../reducers/meeting-idle.reducer';
import * as fromStartedMeeting from '../reducers/meeting-started.reducer';
import { adapter as actionableAdapter } from '../reducers/meeting-started.reducer';

export const selectMeetingState =
  createFeatureSelector<fromMeeting.MeetingState>(
    fromMeeting.meetingFeatureKey,
  );
export const selectMeetingCategoryState =
  createFeatureSelector<fromMeetingCategory.MeetingState>(
    fromMeetingCategory.meetingFeatureKey,
  );
export const selectMeetingTypeState =
  createFeatureSelector<fromMeetingType.MeetingState>(
    fromMeetingType.meetingFeatureKey,
  );

export const selectMeetingEndedState = createSelector(
  selectMeetingState,
  (meeting) => meeting.endedMeetings,
);

export const getStartedMeetingState = createSelector(
  selectMeetingState,
  (meeting) => meeting[fromStartedMeeting.startedMeetingsFeatureKey],
);

export const getEndedMeetingState = createSelector(
  selectMeetingState,
  (meeting) => meeting.endedMeetings,
);

export const getIdleMeetingState = createSelector(
  selectMeetingState,
  (meeting) => meeting.idleMeetings,
);

export const {
  selectIds: selectStartedIds,
  selectEntities: selectStartedEntities,
  selectAll: selectAllStarted,
  selectTotal: selectStartedTotal,
} = actionableAdapter.getSelectors(getStartedMeetingState);

export const {
  selectIds: selectEndedIds,
  selectEntities: selectEndedEntities,
  selectAll: selectAllEndedMeetings,
  selectTotal: selectEndedTotal,
} = endedAdapter.getSelectors(getEndedMeetingState);

export const {
  selectIds: selectIdleIds,
  selectEntities: selectIdleEntities,
  selectAll: selectAllIdleMeetings,
  selectTotal: selectIdleTotal,
} = idleAdapter.getSelectors(getIdleMeetingState);

// started
export const selectStartedMeetingsLoadingState = createSelector(
  getStartedMeetingState,
  (state) => state.loading,
);
export const selectStartedMeetingsLoadedState = createSelector(
  getStartedMeetingState,
  (state) => state.isLoaded,
);

// idle
export const selectIdleMeetingsLoadingState = createSelector(
  getIdleMeetingState,
  (state) => state.loading,
);
export const selectIdleMeetingsLoadedState = createSelector(
  getIdleMeetingState,
  (state) => state.isLoaded,
);

export const selectStartedMeeting = (id: string) =>
  createSelector(selectStartedEntities, (entities) => entities[id]);

// ended
export const selectEndedMeetingsLoadingState = createSelector(
  selectMeetingEndedState,
  (state) => state.loading,
);
export const selectEndedMeetingsLoadedState = createSelector(
  selectMeetingEndedState,
  (state) => state.isLoaded,
);
export const selectAllSortedEndedMeetings = createSelector(
  selectAllEndedMeetings,
  (meetings) => meetings.sort((a, b) => (a.endDate < b.endDate ? 1 : -1)),
);

export const selectAllMeetings = createSelector(
  selectAllEndedMeetings,
  selectAllIdleMeetings,
  selectAllStarted,
  (meetings) => meetings,
);

export const selectMeetingById = (id: string) =>
  createSelector(
    selectAllEndedMeetings,
    selectAllIdleMeetings,
    selectAllStarted,
    (meetings) => meetings.find((meeting) => meeting.id === id),
  );
