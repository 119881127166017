import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCore from '../reducers/core.reducer';

export const selectCoreState = createFeatureSelector<fromCore.CoreState>(
  fromCore.coreFeatureKey,
);

// CORE SELECTORS
export const selectCurrentModule = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.currentModule,
);
export const selectSurveyModule = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.surveyModule,
);

// FEATURE SELECTORS
export const selectmatrixOrgaRollupFF = createSelector(
  selectCoreState,
  (state) => state.company?.matrixOrgaRollupFF,
);

// USER SELECTORS
export const selectUser = createSelector(
  selectCoreState,
  // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
  (state) => state.user,
);
export const selectUserId = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return state.user?.id;
    } else {
      return state.userLegacy?.id;
    }
  },
);

export const selectUserSegments = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return state.user?.segments;
    }
  },
);

export const selectUserLocale = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return state.user?.locale;
    } else {
      return state.userLegacy?.locale;
    }
  },
);
export const selectUserEmail = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return state.user.email;
    } else {
      return state.userLegacy.email;
    }
  },
);
export const selectUserIsAuthenticated = createSelector(
  selectCoreState,
  (state) => state.isAuthenticated,
);
export const selectUserPermissions = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return state.user?.role?.permissions;
    } else {
      return state.userLegacy?.role?.permissions;
    }
  },
);

// COMPANY SELECTORS
export const selectCompany = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company,
);

export const selectCompanyId = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.id,
);

export const selectCompanyLogoEnabled = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company.logoEnabled,
);

export const selectCompanyLogo = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.companyLogo,
);

export const selectCompanyDefaultLocale = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.defaultLocale,
);
export const selectCompanyLocales = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.locales,
);
export const selectCompanyName = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.name,
);
export const selectCompanyDepartmentId = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.departmentId,
);
export const selectCompanyThreshold = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.company?.confidentialityThreshold,
);

// SEGMENTS SELECTORS
export const selectAllSegmentTypesLegacy = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.segments,
);
export const selectNonEmptySegmentTypes = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) =>
    state.segments.filter((type) => type.segments.length),
);

// ROLES
export const selectRoles = createSelector(
  selectCoreState,
  (state: fromCore.CoreState) => state.roles,
);

// COMBINED SELECTORS
export const selectLocales = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return [state.company?.defaultLocale, state.user?.locale];
    } else {
      return [state.company?.defaultLocale, state.userLegacy?.locale];
    }
  },
);

export const selectUserFingerprint = createSelector(
  selectCoreState,
  selectmatrixOrgaRollupFF,
  (state, matrixOrgaRollupFF) => {
    // TODO: matrixOrgaRollupFF : remove userLegacy when legacyStore 100% removed
    if (matrixOrgaRollupFF) {
      return {
        cid: state.company?.id,
        uid: state.user?.id,
        roleId: state.user?.roleId,
      };
    } else {
      return {
        cid: state.company?.id,
        uid: state.userLegacy?.id,
        roleId: state.userLegacy?.roleId,
      };
    }
  },
);
