import { BleexoLocale, i18nObject } from '@models/bleexo.models';
import { TeamMember } from '@models/user.model';
import { FeedbackParticipantRole } from './participant.model';
import {
  FeebackCoreQuestion,
  FeedbackTemplatingQuestion,
} from './question.model';

export type FeedbackSurveyStatus =
  | 'INVITATIONS'
  | 'READY'
  | 'POLLING'
  | 'REVISION'
  | 'ENDED'
  | 'CANCELLED';

export type FeedbackSurveyAnonymizationLevel = 'NONE' | 'SEMI' | 'FULL';

export enum FeedbackSurveyTarget {
  'MYSELF' = 'MYSELF',
  'OTHER' = 'OTHER',
  'CAMPAIGN' = 'CAMPAIGN',
}

export interface FeedbackSurveyBase {
  status: FeedbackSurveyStatus;
  locale: BleexoLocale;
  anonymizationLevel: FeedbackSurveyAnonymizationLevel;
  title: i18nObject;
  description: i18nObject;
  reviewersThreshold: {
    min: number;
    max: number | null;
  };
}

export interface FeedbackSurveyDates<T extends string | Date> {
  startDate?: T;
  endDate?: T;
  reminders?: T[];
}

export interface FeedbackSurvey
  extends FeedbackSurveyBase,
    FeedbackSurveyDates<string> {
  readonly id: number;
  creatorId: number;
  creator: TeamMember;
  campaignId: number | null;
  reviewee: TeamMember;
  coach: TeamMember | null;
  coachReportReadDate: Date | null;
  hasAnswered?: boolean;
  questionIds?: number[];
  questions?: FeebackCoreQuestion[];
  participation: {
    answeredCount: number;
    participantsCount: number;
    percentage: number;
  };
}

export interface FeedbackCreateBasePayload
  extends FeedbackSurveyBase,
    FeedbackSurveyDates<Date | string> {
  status: 'INVITATIONS';
  questions: Array<
    Omit<FeedbackTemplatingQuestion, 'id' | 'categoryId' | 'defaultLocale'>
  >;
}

export interface FeedbackSurveyPayload
  extends FeedbackCreateBasePayload,
    FeedbackSurveyDates<Date | string> {
  revieweeId: number;
}

export interface FeedbackSurveyAnswer {
  questionId: number;
  role?: FeedbackParticipantRole;
  user?: TeamMember;
  content:
    | FeedbackSurveyAnswerOpen
    | FeedbackSurveyAnswerSelect
    | FeedbackSurveyAnswerMulti;
}

export interface FeedbackSurveyAnswerOpen {
  comment: string;
}
export interface FeedbackSurveyAnswerSelect {
  option: {
    position: number;
    comment?: string;
  };
}
export interface FeedbackSurveyAnswerMulti {
  options: {
    position: number;
    comment?: string;
  }[];
}

export enum surveyStepEnum {
  'CREATED',
  'INVITATIONS',
  'READY',
  'POLLING',
  'REVISION',
  'ENDED',
  'CANCELLED',
}
