import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  FeedbackCampaign,
  FeedbackCampaignCreatePayload,
} from '@feedback/store/models/campaign.model';
import { ApiData, Meta } from '@models/API.models';
import { Store } from '@ngrx/store';
import { FiltersBaseService } from '@shared/services/filters-base.service';
import { FiltersLegacyService } from '@shared/services/filters.service';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

export enum CampaignsScopeEnum {
  ALL = 'ALL',
  CREATOR = 'CREATOR',
}

export interface CampaignOptions {
  scope: CampaignsScopeEnum;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackCampaignService extends FiltersBaseService {
  ENDPOINT_URL = `${environment.API_URL}/feedback/campaign/campaigns`;

  constructor(
    private http: HttpClient,
    private filtersLegacy: FiltersLegacyService,
    store: Store,
  ) {
    super(store);
  }

  loadChunk(
    options: CampaignOptions,
    skip = 0,
    limit = 20,
  ): Observable<{ campaigns: FeedbackCampaign[]; meta: Meta }> {
    if (this.matrixOrgaRollupFF) {
      return this.http
        .get<ApiData<FeedbackCampaign[], Meta>>(
          `${this.ENDPOINT_URL}?${qs.stringify({
            body: {
              ...(options.scope ? { scope: options.scope } : {}),
              ...this.filtersObject,
            },
            meta: {
              skip,
              limit,
              orderBy: { property: 'createdAt', direction: 'desc' },
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ campaigns: data, meta })),
        );
    } else {
      return this.http
        .get<ApiData<FeedbackCampaign[], Meta>>(
          `${this.ENDPOINT_URL}?${qs.stringify({
            body: options,
            meta: {
              skip,
              limit,
              orderBy: { property: 'createdAt', direction: 'desc' },
              filters: this.filtersLegacy.rawParams(),
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ campaigns: data, meta })),
        );
    }
  }

  getOne(campaignId: number): Observable<FeedbackCampaign> {
    if (this.matrixOrgaRollupFF) {
      return this.http
        .get<ApiData<FeedbackCampaign, Meta>>(
          `${this.ENDPOINT_URL}/${campaignId}?${qs.stringify({
            body: {
              ...this.filtersObject,
            },
          })}`,
        )
        .pipe(
          retry(1),
          map((apiRes) => apiRes.data),
        );
    } else {
      const meta = {
        filters: this.filtersLegacy.rawParams(),
      };

      return this.http
        .get<
          ApiData<FeedbackCampaign, Meta>
        >(`${this.ENDPOINT_URL}/${campaignId}?${qs.stringify({ meta })}`)
        .pipe(
          retry(1),
          map((apiRes) => apiRes.data),
        );
    }
  }

  create(campaignPayload: FeedbackCampaignCreatePayload): Observable<void> {
    return this.http
      .post<void>(`${this.ENDPOINT_URL}`, {
        ...campaignPayload,
      })
      .pipe(retry(1));
  }

  update(
    campaignId: number,
    campaignPayload: FeedbackCampaignCreatePayload,
  ): Observable<void> {
    return this.http
      .patch<void>(`${this.ENDPOINT_URL}/${campaignId}`, {
        ...campaignPayload,
      })
      .pipe(retry(1));
  }

  delete(campaignId: number): Observable<void> {
    return this.http
      .delete<void>(`${this.ENDPOINT_URL}/${campaignId}`)
      .pipe(retry(1));
  }

  forceStart(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/start`,
      {},
    );
  }

  forceStop(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/stop`,
      {},
    );
  }

  sendAnswersReminders(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/sendAnswersReminders`,
      {
        meta: {
          filters: this.filtersLegacy.rawParams(),
        },
      },
    );
  }

  sendInvitationsReminders(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/sendInvitationsReminders`,
      {
        meta: {
          filters: this.filtersLegacy.rawParams(),
        },
      },
    );
  }

  sendRevisionReminders(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/sendRevisionReminder`,
      {
        meta: {
          filters: this.filtersLegacy.rawParams(),
        },
      },
    );
  }

  shareReports(campaignId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${campaignId}/op/shareReport`,
      {
        meta: {
          filters: this.filtersLegacy.rawParams(),
        },
      },
    );
  }
}
