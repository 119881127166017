import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// HOW TO:

// extend this component:
// class MyComponent extends UnsubscribeComponent

// use takUntil(this._destroy$) in MyComponent

// WARNING:
// if MyComponent NEEDS to have an OnDestroy lifecycle it MUST call super in it:
// ngOnDestroy() {
//   super.ngOnDestroy()
// }

@Component({
  selector: 'unsubscribe',
  template: ``,
})
export class UnsubscribeComponent implements OnDestroy {
  _destroy$ = new Subject<void>();

  constructor() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
