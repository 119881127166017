import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IType } from '@meeting/store/models/meeting-type.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeetingTypeService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/types`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<IType[]> {
    return this.http.get<IType[]>(this._ENDPOINT_URL);
  }
}
