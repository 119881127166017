import {
  selectCompanyDefaultLocale,
  selectUserLocale,
} from '@core/store/selectors/core.selectors';
import { questionsFeatureKey } from '@feedback/store/reducers/question.reducer';
import { BleexoLocale } from '@models/bleexo.models';
import { createSelector } from '@ngrx/store';
import { IQuestion } from '../models/meeting-question.model';
import { meetingQuestionAdapter } from '../reducers/meeting-question.reducer';
import { selectMeetingState } from './meeting.selectors';

export const selectMeetingQuestionState = createSelector(
  selectMeetingState,
  (meeting) => meeting[questionsFeatureKey],
);

export const {
  selectIds: selectMeetingQuestionIds,
  selectEntities: selectMeetingQuestionEntities,
  selectAll: selectAllMeetingQuestions,
  selectTotal: selectMeetingQuestionsTotal,
} = meetingQuestionAdapter.getSelectors(selectMeetingQuestionState);

export const selectMeetingQuestionLoadingState = createSelector(
  selectMeetingQuestionState,
  (state) => state.loading,
);

export const selectMeetingQuestionIsLoadedState = createSelector(
  selectMeetingQuestionState,
  (state) => state.isLoaded,
);

export const getSelectedMeetingQuestionId = createSelector(
  selectMeetingQuestionState,
  (state) => state.selectedQuestionId,
);

export const selectMeetingQuestionsByCategory = (id: string) =>
  createSelector(selectAllMeetingQuestions, (questions) =>
    questions.filter((q) => q.categoryId === id),
  );

export const selectSortedMeetingQuestionsByCategory = (id: string) =>
  createSelector(
    selectMeetingQuestionsByCategory(id),
    selectCompanyDefaultLocale,
    selectUserLocale,
    (questions, companyLocale, userLocale) => {
      const sortedQuestions = questions.sort((a, b) => {
        if (a.textReviewee[userLocale]) {
          return _sortMeetingQuestionsByTextLocale(userLocale, a, b);
        } else if (a.textReviewee[companyLocale]) {
          return _sortMeetingQuestionsByTextLocale(companyLocale, a, b);
        }
        return;
      });

      return sortedQuestions;
    },
  );

export const selectFilteredAndSortedMeetingQuestionsByCategory = (
  id: string,
  searchTerm: string,
) =>
  createSelector(
    selectSortedMeetingQuestionsByCategory(id),
    selectCompanyDefaultLocale,
    selectUserLocale,
    (questions, companyLocale, userLocale) => {
      const filteredQuestions = questions.filter((q) => {
        const questionTextLocalized =
          q.textReviewee[userLocale] || q.textReviewee[companyLocale] || '';
        return questionTextLocalized
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      return filteredQuestions;
    },
  );

export const selectMeetingQuestionById = (id: string) =>
  createSelector(selectAllMeetingQuestions, (questions) => {
    return (questions ?? []).find((q) => q.id === id);
  });

export const selectMeetingQuestionsByIds = (id: string[]) =>
  createSelector(selectAllMeetingQuestions, (questions) => {
    return (questions ?? []).filter((q) => id.includes(q.id));
  });

const _sortMeetingQuestionsByTextLocale = (
  locale: BleexoLocale,
  qA: IQuestion,
  qB: IQuestion,
) => {
  return Intl.Collator(locale).compare(
    qA.textReviewee[locale],
    qB.textReviewee[locale],
  );
};
