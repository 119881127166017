import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotifications from '../reducers/notifications.reducer';
import { UserNotification } from '@models/notifications.model';

export const selectNotificationsState =
  createFeatureSelector<fromNotifications.NotificationsState>(
    fromNotifications.notificationsFeatureKey,
  );

export const selectNotifications = createSelector(
  selectNotificationsState,
  (state: fromNotifications.NotificationsState) => state.notifications,
);

export const selectNotification = createSelector(
  selectNotifications,
  (notifications: UserNotification[], id) =>
    notifications.find((n) => n.id === id),
);

export const selectUnread = createSelector(
  selectNotificationsState,
  (state: fromNotifications.NotificationsState) => state.unreadCount,
);
