import { createReducer, on } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
import * as fromAuth from '../../actions/auth.actions';
import * as fromFilter from '../../actions/filters.actions';
import { Segment } from '@models/segments.model';
import { AdminSegmentsSuccessActions } from '@admin/store/actions/admin-segments.actions';

export const segmentsFeatureKey = 'segments';

export interface SegmentState extends EntityState<Segment> {
  // additional entities state properties
}

export const segmentsAdapter: EntityAdapter<Segment> =
  createEntityAdapter<Segment>();

export const segmentInitialState: SegmentState =
  segmentsAdapter.getInitialState({
    // additional entity state properties
  });

export const segmentsReducer = createReducer(
  segmentInitialState,
  on(fromAuth.bootstrapAppSuccess, (state, { segments }) =>
    segmentsAdapter.setAll(segments, state),
  ),
  on(AdminSegmentsSuccessActions.addAdminSegmentSuccess, (state, { segment }) =>
    segmentsAdapter.addOne(segment, state),
  ),
  on(
    AdminSegmentsSuccessActions.updateAdminSegmentSuccess,
    (state, { segment }) => {
      return segmentsAdapter.updateOne(segment, state);
    },
  ),
  on(
    AdminSegmentsSuccessActions.updateAdminSegmentsSuccess,
    (state, { segments }) => segmentsAdapter.updateMany(segments, state),
  ),
  on(AdminSegmentsSuccessActions.deleteAdminSegmentSuccess, (state, { id }) =>
    segmentsAdapter.removeOne(id, state),
  ),
  on(
    fromFilter.FiltersSuccessActions.getSegmentsUsersCountSuccess,
    (state, { segmentsUsersCount }) => {
      return segmentsAdapter.updateMany(
        segmentsUsersCount.map((segmentUsersCount) => {
          const segmentUpdate: Update<Segment> = {
            id: segmentUsersCount.id,
            changes: {
              usersCount: segmentUsersCount.usersCount,
            },
          };
          return segmentUpdate;
        }),
        state,
      );
    },
  ),
);
