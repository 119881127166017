import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as questionActions from '../actions/question.actions';
import { FeedbackTemplatingQuestion } from '../models/question.model';

export const questionsFeatureKey = 'questions';

export interface State extends EntityState<FeedbackTemplatingQuestion> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FeedbackTemplatingQuestion> =
  createEntityAdapter<FeedbackTemplatingQuestion>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    questionActions.addQuestion,
    questionActions.upsertQuestion,
    questionActions.updateQuestion,
    questionActions.deleteQuestion,
    questionActions.loadQuestions,
    (state) => ({ ...state, loading: true }),
  ),
  on(questionActions.addQuestionSuccess, (state, action) =>
    adapter.addOne(action.question, { ...state, loading: false }),
  ),
  on(questionActions.upsertQuestionSuccess, (state, action) =>
    adapter.upsertOne(action.question, { ...state, loading: false }),
  ),
  on(questionActions.updateQuestionSuccess, (state, action) =>
    adapter.updateOne(action.question, { ...state, loading: false }),
  ),
  on(questionActions.deleteQuestionSuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(questionActions.loadQuestionsSuccess, (state, action) =>
    adapter.setAll(action.questions, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
);
