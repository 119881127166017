<ng-container *transloco="let t">
  <form [formGroup]="formGroup">
    <ng-container formArrayName="translations">
      <div @preventInitialChildAnimations
           *ngIf="translations && translations.controls.length">
        <ng-container *ngFor="let group of translations.controls; let i = index; let isLast = last">
          <div [class.mb-5]="!isLast"
               [@accordion]
               [formGroupName]="i">
            <p class="flex items-center mb-2.5">
              <label class="font-semibold text-grey-7">
                {{ t('GLOBAL.LANGUAGES_' + group.get('locale').value.toUpperCase()) }}
              </label>
              <span class="text-note ml-1.25" *ngIf="group.get('locale').value === formGroup.get('requiredLocale').value">
                <ng-container *ngIf="formGroup.get('requiredLocaleSource').value === 'COMPANY'; else requiredLocaleSourceIsSurvey">
                  ({{ t('COMPANY.DEFAULT_LANGUAGE') }})
                </ng-container>
                <ng-template #requiredLocaleSourceIsSurvey>
                  ({{ t('GLOBAL.DEFAULT_LANGUAGE') }})
                </ng-template>
              </span>
              <i *ngIf="group.get('locale').value !== formGroup.get('requiredLocale').value"
                 class="material-icons clear-btn ml-2.5"
                 (click)="removeTranslation(i, group.get('locale').value)">
                clear
              </i>
            </p>

            <div class="flex mb-2.5">
              <svg-icon *ngIf="displayQuotesIcon"
                        icon="quotes"
                        class="solid quotes text-base mr-1.25 text-grey-6 translate-y-[-8px]"></svg-icon>
              <mat-form-field appearance="outline"
                              class="grow pb-3.5">
                <mat-label>{{ t(placeholder) }}</mat-label>
                <textarea matInput
                          #content
                          formControlName="text"
                          type="text"
                          autocomplete="off"
                          autocorrect="off"
                          spellcheck="false"
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"
                          (keydown.enter)="false"
                          maxlength="{{maxLength}}"
                          class="custom-scrollbar"></textarea>
                <mat-hint *ngIf="displayHint && minLength"
                          align="start">{{'GLOBAL.MIN_CHARACTERS_SIZE' | transloco: {size: minLength} | capitalize
                  }}</mat-hint>
                <mat-hint *ngIf="displayHint"
                          align="end">{{content.textLength}} / {{maxLength}}</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="locales.value.length">
          <p class="my-5 inline-button"
             [matMenuTriggerFor]="menu">
            <i class="material-icons mr-2.5 text-xl">language</i>{{ t('MANAGE_QUESTIONS.ADD_TRANSLATION') }}
          </p>
          <mat-menu #menu="matMenu"
                    yPosition="below"
                    xPosition="after">
            <button *ngFor="let locale of locales.value"
                    mat-menu-item
                    class="flex items-center"
                    (click)="addTranslation(locale)">
              {{ t('GLOBAL.LANGUAGES_' + locale.toUpperCase()) }}
            </button>
          </mat-menu>
        </ng-container>
      </div>
    </ng-container>
  </form>
</ng-container>