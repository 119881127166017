import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ICreateTemplate,
  IPatchTemplate,
  ITemplateResult,
} from '../models/meeting-template.model';

export const AdminMeetingTemplatePageActions = createActionGroup({
  source: 'Admin Meeting Template Page',
  events: {
    'Load Meeting Template': emptyProps(),
    'Add Meeting Template': props<{
      template: ICreateTemplate;
    }>(),
    'Patch Meeting Template': props<{
      templateId: string;
      template: IPatchTemplate;
    }>(),
    'Delete Meeting Template': props<{ id: string }>(),
    'Select Meeting Template': props<{ id: string }>(),
    'Create Meeting Template U I': emptyProps(),
  },
});

export const MeetingTemplatesAPIActions = createActionGroup({
  source: 'Meeting Templates API',
  events: {
    'Load Meeting Templates Success': props<{
      templates: ITemplateResult[];
    }>(),
    'Add Meeting Template Success': props<{
      template: ITemplateResult;
    }>(),
    'Patch Meeting Template Success': props<{
      template: ITemplateResult;
    }>(),
    'Delete Meeting Template Success': props<{ id: string }>(),
  },
});

export const MeetingTemplatesErrorActions = createActionGroup({
  source: 'Meeting Templates [API]',
  events: {
    'Error Question': props<{ error: Error }>(),
  },
});

export const AdminMeetingTemplatesModalActions = createActionGroup({
  source: 'Admin Meeting Templates Modals',
  events: {
    'Show Delete Meeting Template Modal': props<{
      id: string;
    }>(),
  },
});
