import { SegmentType } from '@models/segments.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromAuth from '../../actions/auth.actions';
import { AdminSegmentTypesSuccessActions } from '@admin/store/actions/admin-segment-types.actions';

export const segmentTypesFeatureKey = 'segmentTypes';

export interface SegmentTypeState extends EntityState<SegmentType> {
  // additional entities state properties
}

export const segmentTypesAdapter: EntityAdapter<SegmentType> =
  createEntityAdapter<SegmentType>();

export const segmentTypeInitialState: SegmentTypeState =
  segmentTypesAdapter.getInitialState({
    // additional entity state properties
  });

export const segmentTypesReducer = createReducer(
  segmentTypeInitialState,
  on(fromAuth.bootstrapAppSuccess, (state, { segmentTypes }) =>
    segmentTypesAdapter.setAll(segmentTypes, state),
  ),
  on(
    AdminSegmentTypesSuccessActions.createSegmentTypeSuccess,
    (state, { segmentType }) => segmentTypesAdapter.addOne(segmentType, state),
  ),
  on(
    AdminSegmentTypesSuccessActions.updateSegmentTypeSuccess,
    (state, { segmentType }) =>
      segmentTypesAdapter.updateOne(
        { id: segmentType.id, changes: segmentType },
        state,
      ),
  ),
  on(
    AdminSegmentTypesSuccessActions.deleteSegmentTypeSuccess,
    (state, { id }) => segmentTypesAdapter.removeOne(id, state),
  ),
);
