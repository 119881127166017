import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as TemplateActions from '../actions/template.actions';
import { Feedback360Template } from '../models/template.model';

export const templatesFeatureKey = 'templates';

export interface State extends EntityState<Feedback360Template> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Feedback360Template> =
  createEntityAdapter<Feedback360Template>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    TemplateActions.addTemplate,
    TemplateActions.upsertTemplate,
    TemplateActions.updateTemplate,
    TemplateActions.deleteTemplate,
    TemplateActions.loadTemplates,
    (state) => ({ ...state, loading: true }),
  ),
  on(TemplateActions.addTemplateSuccess, (state, action) =>
    adapter.addOne(action.template, { ...state, loading: false }),
  ),
  on(TemplateActions.upsertTemplateSuccess, (state, action) =>
    adapter.upsertOne(action.template, { ...state, loading: false }),
  ),
  on(TemplateActions.updateTemplateSuccess, (state, action) =>
    adapter.updateOne(action.template, { ...state, loading: false }),
  ),
  on(TemplateActions.deleteTemplateSuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(TemplateActions.loadTemplatesSuccess, (state, action) =>
    adapter.setAll(action.templates, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
);
