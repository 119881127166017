import { Action } from '@models/actions.model';
import { Suggestion } from '@models/coaching.model';
import { HighlightSegment, PulseScore } from '@models/pulse.model';
import { WordCloud } from '@models/wordcloud.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PulseDriver } from '../models/driver.model';
import {
  PulseQuestionResult,
  PulseQuestionResultAndBenchmark,
} from '../models/pulse-question.models';

export const PulseDetailsPageActions = createActionGroup({
  source: 'Pulse Details Page',
  events: {
    'Load Drivers Last Survey Results': emptyProps(),
    'Get Selected Driver Additional Results': props<{
      driverId: number;
    }>(),
    'Get Selected Topic Additional Results': props<{
      topicId: number;
    }>(),
  },
});

export const DriversLastSurveyResultsAPIActions = createActionGroup({
  source: 'Drivers Last Survey Results API',
  events: {
    'Load Drivers Last Survey Results Success': props<{
      lastSurveyResults: Array<PulseQuestionResult & { driverId: number }>;
    }>(),
    'Get Selected Driver Results With Benchmark Success': props<{
      resultsAndBenchmark: PulseQuestionResultAndBenchmark & {
        driverId: number;
      };
    }>(),
    'Get Selected Topic Results With Benchmark Success': props<{
      resultsAndBenchmark: {
        topicId: number;
        mean: number;
        confidentialityThresholdReached: boolean;
        benchmark: {
          company: PulseScore | null;
          departments: PulseScore[] | null;
          department: PulseScore | null;
          bleexo: PulseScore | null;
        };
      };
    }>(),
    'Get Selected Driver Wordcloud And Count Success': props<{
      driverWordcloudAndCount: {
        wordcloud: WordCloud;
        commentsCount: number;
      };
    }>(),
    'Get Selected Driver Segment Highlights Success': props<{
      driverSegmentHighlights: {
        confidentialityThresholdReached: boolean;
        segmentHighlights: {
          strengths: HighlightSegment[];
          weaknesses: HighlightSegment[];
        };
      };
    }>(),
    'Get Selected Driver Surveys History Success': props<{
      allSurveyResults: Array<{
        surveys: PulseQuestionResult[];
        driver: PulseDriver & { color: string };
      }>;
    }>(),
    'Get Topic Related Drivers Surveys History Success': props<{
      allSurveyResults: Array<{
        surveys: PulseQuestionResult[];
        driver: PulseDriver & { color: string };
      }>;
    }>(),
    'Get Selected Topic Coaching Success': props<{
      coaching: {
        teamActions: Action[];
        userActions: Action[];
        pinnedUserSuggestions: Suggestion[];
      };
    }>(),
  },
});

export const DriversLastSurveyResultsErrorActions = createActionGroup({
  source: 'Driver Last Survey Results [API]',
  events: {
    'Error Benchmark': props<{ error: Error }>(),
    'Error Wordcloud': props<{ error: Error }>(),
    'Error Highlights': props<{ error: Error }>(),
    'Error History': props<{ error: Error }>(),
    'Error Coaching': props<{ error: Error }>(),
  },
});
