import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseStore } from '@core/store/legacy/baseStore';
import { selectUser } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import {
  DepthValue,
  FiltersLegacy,
  FiltersParamsLegacy,
  RawParams,
} from '@models/filters.model';
import { Permission } from '@models/permissions.model';
import { Segment } from '@models/segments.model';
import { UserFull } from '@models/user.model';
import { select, Store } from '@ngrx/store';
import * as qs from 'qs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FiltersLegacyService extends BaseStore<{
  filters: FiltersLegacy;
}> {
  private _populationCount$: BehaviorSubject<number> = new BehaviorSubject(
    null,
  );
  private _defaultDepth$: BehaviorSubject<DepthValue> = new BehaviorSubject(10);

  private _filtersInitialized = false;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    super({
      filters: { viewAsUser: null, depth: null, segments: new Map() },
    });
    this.store.pipe(select(selectUser)).subscribe((user) => {
      if (!user) {
        this._filtersInitialized = false;
      } else {
        this._defaultDepth$.next(
          user.role.permissions.includes(`scope:company` as Permission)
            ? -1
            : 10,
        );
        this.initFilters();
      }
    });
  }

  setViewAsUserAndDepth(viewAsUser: UserFull | null, depth: DepthValue): void {
    this.dispatch((state) => ({
      ...state,
      filters: {
        ...state.filters,
        viewAsUser,
        depth,
      },
    }));
  }

  setViewAsUser(viewAsUser: UserFull | null): void {
    this.dispatch((state) => ({
      ...state,
      filters: {
        ...state.filters,
        viewAsUser,
      },
    }));
  }

  get viewAsUser(): UserFull {
    return this.selectSync((state) => state.filters.viewAsUser);
  }

  setDepth(depth: DepthValue) {
    this.dispatch((state) => ({
      ...state,
      filters: { ...state.filters, depth },
    }));
  }
  get depth(): DepthValue {
    return this.selectSync((state) => state.filters.depth);
  }

  setSegments(segments: Map<number, Segment[]>) {
    this.dispatch((state) => ({
      ...state,
      filters: { ...state.filters, segments },
    }));
  }
  get segments(): Map<number, Segment[]> {
    return this.selectSync((state) => state.filters.segments);
  }

  setFilters(filters: FiltersLegacy) {
    this.dispatch((state) => ({ ...state, filters }));
  }

  updateFilters(filters: Partial<FiltersLegacy>) {
    this.dispatch((state) => ({ filters: { ...state.filters, ...filters } }));
  }

  get filters(): FiltersLegacy {
    return this.selectSync((state) => state.filters);
  }
  get filters$(): Observable<FiltersLegacy> {
    return this.select((state) => state.filters);
  }
  get filtersParams(): Partial<FiltersParamsLegacy> {
    let params: Partial<FiltersParamsLegacy>;
    this.selectSync((state) => {
      params = this.formatParams(state.filters);
    });
    return params;
  }

  formatSegments(segmentsFilters) {
    const formatted: string[] = [];
    segmentsFilters.forEach((segments, type) => {
      if (segments.length) {
        formatted.push(
          segments.map((segment) => segment.id.toString()).join(','),
        );
      }
    });
    return formatted;
  }

  getPopulationCount(): Observable<number> {
    const meta: { filters: RawParams } = {
      filters: this.rawParams(),
    };
    return this.http
      .get<{
        meta: {};
        data: number;
      }>(`${environment.API_URL}/admin/populations?${qs.stringify({ meta }, { arrayFormat: 'brackets' })}`)
      .pipe(
        retry(1),
        map((res) => Number(res.data)),
        tap((count) => this._populationCount$.next(count)),
      );
  }
  get populationCount$(): Observable<number> {
    return this._populationCount$;
  }

  get populationCount(): number {
    return this._populationCount$.getValue();
  }

  formatParams(filters: FiltersLegacy): Partial<FiltersParamsLegacy> {
    return {
      ...(filters.segments.size
        ? { 'segmentIds[]': this.formatSegments(filters.segments) }
        : {}),
      ...(filters.viewAsUser
        ? { viewAsUserId: filters.viewAsUser.id.toString() }
        : {}),
      ...(filters.depth ? { depth: filters.depth.toString() } : {}),
    };
  }

  rawParams(): RawParams {
    // used when we need to stringify filters with qs
    const raw: RawParams = {};
    if (this.filters.segments.size) {
      raw.segmentIds = this.formatSegments(this.filters.segments);
    }
    if (this.filters.depth) {
      raw.depth = this.filters.depth;
    }
    if (this.filters.viewAsUser) {
      raw.viewAsUserId = this.filters.viewAsUser.id;
    }
    return raw;
  }

  get defaultDepth(): DepthValue {
    return this._defaultDepth$.value;
  }

  get initialized(): boolean {
    return this._filtersInitialized;
  }

  initFilters() {
    this.setFilters({
      viewAsUser: null,
      depth: this.defaultDepth,
      segments: new Map(),
    });
    this._filtersInitialized = true;
  }

  resetFilters(): void {
    this.initFilters();
    this._populationCount$.next(null);
  }
}
