import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { FeedbackTemplateService } from '../../services/feedback-template.service';
import * as fromTemplate from '../actions/template.actions';

@Injectable()
export class FeedbackTemplatesEffects {
  constructor(
    private actions$: Actions,
    private templates: FeedbackTemplateService,
  ) {}

  loadTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplate.loadTemplates),
      exhaustMap((_) =>
        this.templates
          .loadAll()
          .pipe(
            map((templates) =>
              fromTemplate.loadTemplatesSuccess({ templates }),
            ),
          ),
      ),
    ),
  );
  addTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplate.addTemplate),
      exhaustMap((action) =>
        this.templates.create(action.template).pipe(
          map((id) =>
            fromTemplate.addTemplateSuccess({
              template: {
                id,
                ...action.template,
              },
            }),
          ),
        ),
      ),
    ),
  );
  upsertTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplate.upsertTemplate),
      exhaustMap(({ template }) => {
        if (template.id) {
          return this.templates.update(template).pipe(
            map((_) => {
              const changes = { ...template };
              delete changes.id;
              return fromTemplate.updateTemplateSuccess({
                template: { id: template.id, changes },
              });
            }),
          );
        } else {
          return this.templates.create(template).pipe(
            map((id) => {
              return fromTemplate.addTemplateSuccess({
                template: { ...template, id },
              });
            }),
          );
        }
      }),
    ),
  );
  deleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplate.deleteTemplate),
      exhaustMap(({ id }) =>
        this.templates
          .delete(id)
          .pipe(map((_) => fromTemplate.deleteTemplateSuccess({ id }))),
      ),
    ),
  );
}
