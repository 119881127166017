<ng-container *transloco="let t">
  <h1 *ngIf="!data?.category; else editTemplate"
      mat-dialog-title>{{ t('MEETING.CREATE_CATEGORY') }}</h1>
  <ng-template #editTemplate>
    <h1 mat-dialog-title>{{ t('MEETING.EDIT_CATEGORY') }}</h1>
  </ng-template>
  <form mat-dialog-content>
    <translations-handler-typed-form [formGroup]="categoryForm"
                                     [displayHint]="false"
                                     [placeholder]="'MEETING.CREATE_CATEGORY_LABEL'"></translations-handler-typed-form>
  </form>

  <mat-dialog-actions class="flex justify-between talent-theme">
    <button mat-button
            color="primary"
            mat-dialog-close>{{ t('GLOBAL.CANCEL') | uppercase }}</button>
    <app-button-with-spinner data-testid="button-dispatch"
                             (handleClick)="dispatch()"
                             [isLoading]="loading$ | async"
                             [isDisabled]="!isValid()"
                             buttonStyle="mat-raised-button">
      {{ (data.category?.id ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE')) | uppercase}}
    </app-button-with-spinner>
  </mat-dialog-actions>
</ng-container>
