import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[info-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div class="custom-toast info-toast flex items-start mb-2.5">
      <svg-icon
        icon="toast-info"
        class="solid text-base toast-icon mr-2.5"
      ></svg-icon>

      <div class="grow" style="overflow: hidden">
        <h5 class="mb-1.25">
          {{ title || 'STATUS.' + infoPresets.title | transloco }}
        </h5>
        <p *ngIf="message" class="flex items-end">
          <span>{{ message }}</span>
          <horizontal-loader></horizontal-loader>
        </p>
        <p *ngIf="additionalMessage">
          <span class="text-note">{{ additionalMessage }}</span>
        </p>
        <ng-content #download></ng-content>
      </div>
      <i
        class="material-icons remove-btn text-2xl bigger-hitbox"
        (click)="close($event)"
        >clear</i
      >
    </div>
  `,
  animations: [toastAnim],
})
export class InfoToastComponent extends Toast {
  @Input() toastId: number;
  @Input() icon: string;
  infoPresets: ToastPreset;
  additionalMessage = null;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.infoPresets = toastPresets.info;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
