import { Action, combineReducers } from '@ngrx/store';
import * as fromStartedCampaign from './campaign-started.reducer';
import * as fromCategory from './meeting-category.reducer';
import * as fromMeetingEnded from './meeting-ended.reducer';
import * as fromIdleMeeting from './meeting-idle.reducer';
import * as fromQuestion from './meeting-question.reducer';
import * as fromStartedMeeting from './meeting-started.reducer';
import * as fromMeetingTemplate from './meeting-template.reducer';
import * as fromMeetingType from './meeting-type.reducer';

export const meetingFeatureKey = 'meeting';
export interface MeetingState {
  [fromCategory.categoriesFeatureKey]: fromCategory.MeetingCategoryState;
  [fromQuestion.meetingQuestionsFeatureKey]: fromQuestion.MeetingQuestionState;
  [fromMeetingType.meetingTypesFeatureKey]: fromMeetingType.MeetingTypeState;
  [fromMeetingTemplate.meetingTemplatesFeatureKey]: fromMeetingTemplate.MeetingTemplateState;
  [fromMeetingEnded.endedMeetingsFeatureKey]: fromMeetingEnded.State;
  [fromStartedMeeting.startedMeetingsFeatureKey]: fromStartedMeeting.State;
  [fromStartedCampaign.startedCampaignsFeatureKey]: fromStartedCampaign.State;
  [fromIdleMeeting.idleMeetingsFeatureKey]: fromIdleMeeting.State;
}

export const meetingInitialState: MeetingState = {
  [fromCategory.categoriesFeatureKey]: fromCategory.meetingCategoryInitialState,
  [fromQuestion.meetingQuestionsFeatureKey]:
    fromQuestion.meetingQuestionInitialState,
  [fromMeetingType.meetingTypesFeatureKey]:
    fromMeetingType.meetingTypeInitialState,
  [fromMeetingTemplate.meetingTemplatesFeatureKey]:
    fromMeetingTemplate.meetingTemplateInitialState,
  [fromMeetingEnded.endedMeetingsFeatureKey]: fromMeetingEnded.initialState,
  [fromStartedMeeting.startedMeetingsFeatureKey]:
    fromStartedMeeting.initialState,
  [fromStartedCampaign.startedCampaignsFeatureKey]:
    fromStartedCampaign.initialState,
  [fromIdleMeeting.idleMeetingsFeatureKey]: fromIdleMeeting.initialState,
};

export function meetingReducers(
  state: MeetingState | undefined,
  action: Action,
) {
  return combineReducers({
    [fromCategory.categoriesFeatureKey]: fromCategory.meetingCategoryReducer,
    [fromQuestion.meetingQuestionsFeatureKey]:
      fromQuestion.meetingQuestionReducer,
    [fromMeetingType.meetingTypesFeatureKey]:
      fromMeetingType.meetingTypeReducer,
    [fromMeetingTemplate.meetingTemplatesFeatureKey]:
      fromMeetingTemplate.meetingTemplteReducer,
    [fromMeetingEnded.endedMeetingsFeatureKey]: fromMeetingEnded.reducer,
    [fromStartedMeeting.startedMeetingsFeatureKey]: fromStartedMeeting.reducer,
    [fromStartedCampaign.startedCampaignsFeatureKey]:
      fromStartedCampaign.reducer,
    [fromIdleMeeting.idleMeetingsFeatureKey]: fromIdleMeeting.reducer,
  })(state, action);
}
