import { Update } from '@ngrx/entity/src/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ICreateQuestionCategory,
  IPatchQuestionCategory,
  IQuestionCategory,
} from '../models/meeting-question-category.model';

/**
 * * All actions from component in admin categories bank *
 */
const MeetingCategoriesActions = createActionGroup({
  source: 'Meeting Categories [Compo]',
  events: {
    'Load Meeting Categories': emptyProps(),
    'Add Meeting Category': props<{ category: ICreateQuestionCategory }>(),
    'Update Meeting Category': props<{
      category: IPatchQuestionCategory & { id: string };
    }>(),
    'Delete Meeting Category': props<{ id: string }>(),
  },
});

/**
 * * All actions rised by api in admin categories bank *
 */
const MeetingCategoriesApiActions = createActionGroup({
  source: 'Meeting Categor(y)ies [API]',
  events: {
    'Load Meeting Categories Success': props<{
      categories: IQuestionCategory[];
    }>(),
    'Add Meeting Category Success': props<{
      category: IQuestionCategory;
    }>(),
    'Update Meeting Category Success': props<{
      category: Update<IQuestionCategory>;
    }>(),
    'Delete Meeting Category Success': props<{ id: string }>(),
  },
});

/**
 * * Action rised when error occured in api for admin categories bank *
 */
const MeetingCategoriesErrorAction = createActionGroup({
  source: 'Meeting Categor(y)ies [API]',
  events: {
    'Error Meeting Category': props<{ error: Error }>(),
  },
});

/**
 * * All actions from modals in admin categories bank *
 */
const AdminMeetingCategoriesModalActions = createActionGroup({
  source: 'Admin Meeting Modals',
  events: {
    'Show Add Meeting Category Modal': props<{
      category?: IQuestionCategory;
    }>(),
    'Show Delete Meeting Category Modal': props<{
      category?: IQuestionCategory;
    }>(),
  },
});

/**
 * * Export all actions in single object *
 */
export {
  AdminMeetingCategoriesModalActions,
  MeetingCategoriesActions,
  MeetingCategoriesApiActions,
  MeetingCategoriesErrorAction,
};
