<ng-container [ngSwitch]="buttonStyle">
  <button *ngSwitchCase="'mat-button'"
          mat-button
          [color]="color"
          (click)="onClick($event)"
          [disabled]="isLoading || isDisabled"
          class="relative hide-in-print mat-primary"
          [ngClass]="addClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <button *ngSwitchCase="'mat-flat-button'"
          mat-flat-button
          [color]="color"
          (click)="onClick($event)"
          [disabled]="isLoading || isDisabled"
          class="relative hide-in-print mat-primary"
          [ngClass]="addClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <button *ngSwitchCase="'mat-raised-button'"
          mat-raised-button
          [color]="color"
          (click)="onClick($event)"
          [disabled]="isLoading || isDisabled"
          class="relative hide-in-print mat-primary"
          [ngClass]="addClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>


<ng-template #content>
  <div class="flex items-center justify-center content"
       [class.is-loading]="isLoading">
    <ng-content></ng-content>
  </div>
  <div *ngIf="isLoading"
       class="spinner">
    <mat-spinner diameter="20"
                 strokeWidth="3"
                 color="primary">
    </mat-spinner>
  </div>
</ng-template>