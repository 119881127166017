<h1 mat-dialog-title>{{ 'GDPR.GDPR_CONSENT' | transloco }}</h1>

<div mat-dialog-content
     class="pb-5">
  <div class="flex justify-center items-center mb-2.5">
    <img class="bleexo-logo mx-2.5 mb-2.5"
         src="assets/img/logo/logo_B-color.svg"
         alt="bleexo logo"
         width="70px">
    <img class="bleexo-logo-text mx-2.5 mb-2.5"
         src="assets/img/logo/logo_bleexo-grey.svg"
         alt="bleexo brand"
         height="42px">
  </div>

  <display-markdown [text]="data.gdprText | localize"
                    className=text-body>
  </display-markdown>

</div>
<mat-dialog-actions class="justify-center">
  <app-button-with-spinner (handleClick)="giveConsent()"
                           [isLoading]="isLoading">
    {{ 'GLOBAL.ACCEPT' | transloco | uppercase}}
  </app-button-with-spinner>
</mat-dialog-actions>