import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BleexoRoute } from '@models/routes.model';

const routes: BleexoRoute[] = [
  {
    path: '404',
    loadChildren: () =>
      import('./core/components/404/404.module').then((m) => m.P404Module),
  },
  {
    path: 'expired',
    loadChildren: () =>
      import('./core/components/expired-link/expired-link.module').then(
        (m) => m.ExpiredLinkModule,
      ),
  },
  {
    path: 'exports',
    loadChildren: () =>
      import('./modules/exports/exports.module').then((m) => m.ExportsModule),
  },
  { path: '', redirectTo: 'meeting', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./core/components/shell/shell.module').then((m) => m.ShellModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./core/components/404/404.module').then((m) => m.P404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
