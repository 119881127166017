import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
@Injectable({
  providedIn: 'root',
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || !value || !value.length) {
      return;
    }
    return `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
  }
}
