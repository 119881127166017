import { FiltersSuccessActions } from '@core/store/actions/filters.actions';
import { createReducer, on } from '@ngrx/store';

export interface FiltersPopulationState {
  population: number;
}

export const filtersPopulationInitialState: FiltersPopulationState = {
  population: null,
};

export const filtersPopulationReducer = createReducer(
  filtersPopulationInitialState,
  on(
    FiltersSuccessActions.getFiltersPopulationCountSuccess,
    (state, { count }) => {
      return {
        ...state,
        population: count,
      };
    },
  ),
);
