import { Component, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { selectAllSegmentTypes as selectAllSegmentTypesLegacy } from '@core/store/selectors/segment-type-v1.selectors';
import {
  selectAllSegments,
  selectSegmentByTypeIdMap,
} from '@core/store/selectors/segments.selectors';
import {
  Segment,
  SegmentType,
  SegmentTypeLegacyNGRX,
} from '@models/segments.model';
import { Store, select } from '@ngrx/store';
import { accordion, fadeIn } from '@shared/animations/animations';
import { DatesService } from '@shared/services/dates.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export enum FeedbackCompareRoleFiltersEnum {
  Reviewee = 'REVIEWEE',
  Reviewer = 'REVIEWER',
  Coach = 'COACH',
}

export enum CompareTableFiltersEnum {
  Users = 'USERS',
  Segments = 'SEGMENTS',
}
@Component({
  templateUrl: './feedback-compare.component.html',
  styleUrls: [],
  animations: [accordion, fadeIn],
})
export class FeedbackCompareComponent implements OnDestroy {
  readonly segmentTypes$ = this.store.pipe(select(selectAllSegmentTypesLegacy));
  readonly ROLE_ENUM = FeedbackCompareRoleFiltersEnum;
  readonly TABLE_FILTERS_ENUM = CompareTableFiltersEnum;
  readonly pageFiltersForm = this.buildPageFiltersForm();
  displaySegmentsSelection = false;
  private _allSegments: Segment[] = [];

  allSegmentTypesWithSegments: Map<
    number,
    { segmentType: SegmentType; segments: Segment[] }
  > = new Map();
  selectedSegmentType: SegmentType | null = null;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private datesService: DatesService,
  ) {
    this.store
      .pipe(select(selectAllSegments), take(1), takeUntil(this._unsubscribe$))
      .subscribe((segments) => (this._allSegments = segments));
    this.store
      .pipe(
        select(selectSegmentByTypeIdMap),
        take(1),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(
        (segmentTypesWithSegments) =>
          (this.allSegmentTypesWithSegments = segmentTypesWithSegments),
      );
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  buildPageFiltersForm(): UntypedFormGroup {
    return this.fb.group({
      role: [this.ROLE_ENUM.Reviewee, Validators.required],
      from: [this.datesService.subMonths(this.today, 12)],
      to: [this.today],
      comparing: [this.TABLE_FILTERS_ENUM.Users, Validators.required],
      segmentType: [null],
      segments: [[]],
    });
  }

  resetDates(): void {
    this.pageFiltersForm.patchValue({ from: null, to: null });
  }

  resetSegmentsSelection() {
    this.displaySegmentsSelection = false;
    this.pageFiltersForm.patchValue({ segmentType: null, segments: [] });
  }

  selectSegments(segmentIds: number[]) {
    const segments = segmentIds.map((segmentId) =>
      this._allSegments.find((segment) => segment.id === segmentId),
    );

    this.pageFiltersForm.patchValue({ segments });
  }

  segmentTypeChanged(segmentType?: SegmentType) {
    this.selectedSegmentType = { ...segmentType } || null;
    this.displaySegmentsSelection = false;
    this.selectSegments([]);
    this.pageFiltersForm.patchValue({ segments: [] });
  }

  get today(): Date {
    return new Date();
  }

  get fromDate(): string | null {
    return this.pageFiltersForm.value.from
      ? this.datesService.formatISO(this.pageFiltersForm.value.from, 'date')
      : null;
  }

  get toDate(): string | null {
    return this.pageFiltersForm.value.from
      ? this.datesService.formatISO(this.pageFiltersForm.value.to, 'date')
      : null;
  }

  get selectedRole(): FeedbackCompareRoleFiltersEnum {
    return this.pageFiltersForm.value.role;
  }

  get compareType(): CompareTableFiltersEnum {
    return this.pageFiltersForm.value.comparing;
  }

  get segmentType(): SegmentTypeLegacyNGRX {
    return this.pageFiltersForm.value.segmentType;
  }

  get segments(): Segment[] {
    return this.pageFiltersForm.value.segments;
  }
}
