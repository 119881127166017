import { createAction, props } from '@ngrx/store';
import { UserRole } from '@models/permissions.model';

export const loadRoles = createAction('[Admin Roles] Load Roles');
export const loadRolesSuccess = createAction(
  '[Roles/API] Load Roles Success',
  props<{ roles: UserRole[] }>(),
);
// export const loadRolesFailure = createAction('[Admin Roles] Load Roles')

export const createRole = createAction(
  '[Admin Roles] Create Role',
  props<{ role: UserRole }>(),
);
export const createRoleSuccess = createAction(
  '[Roles/API] Create Role Success',
  props<{ role: UserRole }>(),
);

export const getRolesCount = createAction(
  '[Admin Roles] Count user in role',
  props<{ roleId: number }>(),
);
export const getRolesCountSuccess = createAction(
  '[Roles/API] Count user in role success',
  props<{
    roleId: number;
    count: number;
  }>(),
);

export const updateRole = createAction(
  '[Admin Roles] Update Role',
  props<{ role: UserRole }>(),
);
export const updateRoleSuccess = createAction(
  '[Roles/API] Update Role Success',
  props<{ role: UserRole }>(),
);

export const deleteRole = createAction(
  '[Admin Roles] Delete Role',
  props<{ id: number; fallbackId: number }>(),
);
export const deleteRoleSuccess = createAction(
  '[Roles/API] Delete Role Success',
  props<{ id: number }>(),
);
