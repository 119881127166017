import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FeedbackSurvey } from '../models/survey.model';
import * as SurveyActions from '../actions/feedback-survey.actions';

export const endedSurveysFeatureKey = 'endedSurveys';

export interface State extends EntityState<FeedbackSurvey> {
  // additional entities state properties
  total: number;
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FeedbackSurvey> =
  createEntityAdapter<FeedbackSurvey>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  total: null,
  skip: 0,
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(SurveyActions.loadEndedFeedbackSurveysChunk, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    SurveyActions.loadEndedFeedbackSurveysChunkSuccess,
    (state, { meta, surveys }) =>
      adapter.addMany(surveys, {
        ...state,
        total: meta.total,
        loading: false,
        isLoaded: true,
      }),
  ),
  on(SurveyActions.refreshEndedFeedbackSurveys, (state, { meta, surveys }) =>
    adapter.setAll(surveys, {
      ...state,
      total: meta.total,
      loading: false,
      isLoaded: true,
    }),
  ),
  on(SurveyActions.feedbackSurveyFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(SurveyActions.addEndedFeedbackSurvey, (state, action) =>
    adapter.addOne(action.survey, { ...state, loading: false }),
  ),
);
