import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: Error | HttpErrorResponse) {
    const notifications = this.injector.get(NotificationService);
    if (error instanceof HttpErrorResponse) {
      if (error.status >= 500) {
        notifications.showError('ALERTS.SERVER_DOWN');
      } else {
        notifications.showError('ALERTS.GENERIC_ERROR');
      }
    }
    // Always log the error
    console.error(error);
  }
}
