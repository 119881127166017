import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as topicActions from '../actions/topic.actions';
import { PulseTopic } from '../models/topic.model';

export const pulseTopicsFeatureKey = 'topics';

export interface PulseTopicState extends EntityState<PulseTopic> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<PulseTopic> =
  createEntityAdapter<PulseTopic>();

export const initialState: PulseTopicState = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(topicActions.addTopic, (state, action) =>
    adapter.addOne(action.topic, state),
  ),
  on(topicActions.upsertTopic, (state, action) =>
    adapter.upsertOne(action.topic, state),
  ),
  on(topicActions.addTopics, (state, action) =>
    adapter.addMany(action.topics, state),
  ),
  on(topicActions.upsertTopics, (state, action) =>
    adapter.upsertMany(action.topics, state),
  ),
  on(topicActions.updateTopic, (state, action) =>
    adapter.updateOne(action.topic, state),
  ),
  on(topicActions.updateTopics, (state, action) =>
    adapter.updateMany(action.topics, state),
  ),
  on(topicActions.deleteTopic, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(topicActions.deleteTopics, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(topicActions.loadTopicsSuccess, (state, { topics }) =>
    adapter.setAll(topics, { ...state, loading: false, loaded: true }),
  ),
  on(topicActions.clearTopics, (state) => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
