import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@appstore/app.reducers';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  bootstrapApp,
  bootstrapAppLegacySuccess,
  bootstrapAppSuccess,
  redirectAfterLogout,
} from '@core/store/actions/auth.actions';
import { selectUserIsAuthenticated } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import { User } from '@models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _isAuthenticated: boolean;
  private _ENDPOINT_URL = `${environment.API_URL}/core/users/me`;

  constructor(
    private jwtHelper: JwtHelperService,
    private store: Store<AppState>,
    private action$: Actions,
    private _httpClient: HttpClient,
  ) {
    this.store.pipe(select(selectUserIsAuthenticated)).subscribe((isAuth) => {
      this._isAuthenticated = isAuth;
    });
    this.action$
      .pipe(
        ofType(redirectAfterLogout),
        tap((_) => {
          window.location.href = environment.MY_SILAE_URL;
        }),
      )
      .subscribe();
  }

  isLoggedIn(): Observable<boolean> {
    const token = this.jwtHelper.tokenGetter();

    if (!token) {
      return of(false);
    }

    const tokenExpired = this.jwtHelper.isTokenExpired(token);

    if (tokenExpired) {
      return of(false);
    } else {
      if (this._isAuthenticated) {
        return of(true);
      } else {
        this.store.dispatch(bootstrapApp({ token }));

        return this.action$.pipe(
          ofType(bootstrapAppSuccess, bootstrapAppLegacySuccess),
          map((_) => true),
        );
      }
    }
  }

  getToken(): string | undefined {
    return this.jwtHelper.tokenGetter();
  }

  /**
   * Uses embedded bearer token from jwtModule to get related Talent user
   * @returns User | undefined
   */
  getUserFromBearerToken$(): Observable<User | undefined> {
    return this._httpClient.get<User>(`${this._ENDPOINT_URL}`);
  }

  decodeToken(token: string) {
    return this.jwtHelper.decodeToken(token);
  }

  isTokenExpired(token) {
    return this.jwtHelper.isTokenExpired(token);
  }

  storeToken(token: string): void {
    localStorage.setItem('token', token);
  }

  storeTokenAndConnectionMethod(token: string): void {
    localStorage.setItem('lastConnectionMethod', this.decodeToken(token).auth);
    localStorage.setItem('token', token);
  }

  deleteToken(): void {
    localStorage.removeItem('token');
  }
}
