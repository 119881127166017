import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PulseQuestionResult } from '../models/pulse-question.models';
import {
  PulseDetailsPageActions,
  DriversLastSurveyResultsAPIActions,
} from '../actions/driver-last-survey-results.actions';
export const driverLastSurveyResultsFeatureKey = 'driversLastSurveyResults';
export interface DriverLastSurveyResultsState
  extends EntityState<PulseQuestionResult & { driverId: number }> {
  loading: boolean;
}
// We need to provide a custom selector because the entity adapter needs a way to find the correct id
// The id in PulseQuestionResult id the SURVEY ID, not the driver id
export function selectDriverId(
  surveyResults: PulseQuestionResult & { driverId: number },
): number {
  return surveyResults.driverId;
}
export const driverLastSurveyResultsAdapter: EntityAdapter<
  PulseQuestionResult & { driverId: number }
> = createEntityAdapter<PulseQuestionResult & { driverId: number }>({
  selectId: selectDriverId,
});
export const initialState: DriverLastSurveyResultsState =
  driverLastSurveyResultsAdapter.getInitialState({
    loading: false,
  });
export const reducer = createReducer(
  initialState,
  on(PulseDetailsPageActions.loadDriversLastSurveyResults, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    DriversLastSurveyResultsAPIActions.loadDriversLastSurveyResultsSuccess,
    (state, action) =>
      driverLastSurveyResultsAdapter.setAll(action.lastSurveyResults, {
        ...state,
        loading: false,
      }),
  ),
);
