import { FiltersScopeTypeEnum } from '@models/filters.model';
import { User } from '@models/user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  filtersFeatureKey,
  FiltersState,
} from '../reducers/filters/filters.reducer';
import { FiltersScopeState } from '../reducers/filters/filters-scope.reducer';
import { FiltersSegmentationState } from '../reducers/filters/filters-segmentation.reducer';

export const selectFiltersState =
  createFeatureSelector<FiltersState>(filtersFeatureKey);

export const selectFiltersPopulationCount = createSelector(
  selectFiltersState,
  (state) => state.population.population,
);

export const selectScopeManager = createSelector(
  selectFiltersState,
  (state) => state.scope.viewAsUser as User,
);

export const selectFilters = createSelector(selectFiltersState, (state) => {
  if (state.scope.type === FiltersScopeTypeEnum.HIERARCHY) {
    return state;
  }
  return {
    type: state.scope.type,
    segmentation: state.segmentation,
  };
});

export const selectFiltersScope = createSelector(
  selectFiltersState,
  ({ scope }) => scope,
);

export const selectFiltersSegmentation = createSelector(
  selectFiltersState,
  ({ segmentation }) => segmentation,
);

export const selectFiltersForAPICalls = createSelector(
  selectFiltersScope,
  selectFiltersSegmentation,
  (
    scopeState: FiltersScopeState,
    segmentationState: FiltersSegmentationState,
  ) => {
    const segmentation = Object.entries(segmentationState).map(
      ([segmentTypeId, segmentIds]: [string, number[]]) => {
        if (segmentIds.length) {
          return {
            segmentTypeId: parseInt(segmentTypeId, 10),
            segmentIds,
          };
        }
      },
    );

    if (scopeState.type === FiltersScopeTypeEnum.HIERARCHY) {
      return {
        scope: {
          type: scopeState.type,
          viewAsUserId: scopeState.viewAsUser?.id,
          depth: scopeState.depth,
        },
        segmentation,
      };
    }
    return {
      scope: {
        type: scopeState.type,
      },
      segmentation,
    };
  },
);
export const selectFiltersSegmentationSegmentsCount = createSelector(
  selectFiltersState,
  ({ segmentation }) =>
    Object.values(segmentation).reduce((count, segmentIds) => {
      return count + segmentIds?.length || 0;
    }, 0),
);
