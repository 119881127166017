import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { BootstrapService } from '@core/services/bootstrap.service';
import { NotificationService } from '@core/services/notifications.service';
import { MeetingCategoriesActions } from '@meeting/store/actions/meeting-category.actions';
import { AdminMeetingQuestionBankPageActions } from '@meeting/store/actions/meeting-question.actions';
import { AdminMeetingTemplatePageActions } from '@meeting/store/actions/meeting-template.actions';
import { MeetingTypesActions } from '@meeting/store/actions/meeting-type.actions';
import {
  loadEndedMeetings,
  loadIdleMeetings,
  loadStartedMeetings,
} from '@meeting/store/actions/meeting.actions';
import { Company } from '@models/company.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from '@shared/services/company.service';
import { FiltersLegacyService } from '@shared/services/filters.service';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import * as fromAuthActions from '../actions/auth.actions';
import {
  loadNotificationsChunk,
  loadUnreadNotificationsCount,
} from '../actions/notifications.actions';
import { GDPRConsentPrompt } from '../actions/user.actions';
import { LegacyStore } from '../legacy/store.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private bootstrapService: BootstrapService,
    private legacyStore: LegacyStore,
    private filters: FiltersLegacyService,
    private notifs: NotificationService,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private _document: Document,
    public dialog: MatDialog,
    private _companyService: CompanyService,
  ) {}

  // Get Companyt first to know what features are enabled and if they have a logo
  bootstrapApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.bootstrapApp),
      switchMap(() => {
        return this.authService.getUserFromBearerToken$().pipe(
          switchMap((user) => {
            const userId = user.id;
            return this._companyService.getCompanyLocales(user.companyId).pipe(
              map((companyLocales) => {
                const companyWithLocales: Company = {
                  ...user.company,
                  locales:
                    companyLocales.length > 2 ? companyLocales : ['fr', 'en'],
                };

                return fromAuthActions.getInitialData({
                  userId,
                  company: companyWithLocales,
                });
              }),
            );
          }),
          catchError(() => {
            this.router.navigateByUrl('/404');
            return EMPTY;
          }),
        );
      }),
    ),
  );

  getInitialData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.getInitialData),
      exhaustMap(({ company, userId }) =>
        this.bootstrapService.getAdditionalData(company, userId).pipe(
          map(([user, segments, segmentTypes, companyLogo]) => ({
            user,
            company,
            segments,
            segmentTypes,
            companyLogo,
          })),
          tap(({ user }) => {
            if (company.gdprConsentEnabled && !user.gdprConsentGiven) {
              this.store.dispatch(
                GDPRConsentPrompt({
                  userId: user.id,
                  gdprText: company.gdprText,
                }),
              );
            }
            this._document.documentElement.lang = user.locale;
          }),
          tap(({ company }) => {
            this.legacyStore.setCompany({ ...company, roles: null });
          }),
          tap((_) =>
            this.store.dispatch(loadNotificationsChunk({ chunkSize: 15 })),
          ),
          tap((_) => this.store.dispatch(loadUnreadNotificationsCount())),
          tap((_) => {
            this.store.dispatch(loadIdleMeetings());
            this.store.dispatch(loadEndedMeetings());
            this.store.dispatch(loadStartedMeetings());
            this.store.dispatch(MeetingTypesActions.loadMeetingTypes()),
              this.store.dispatch(
                AdminMeetingTemplatePageActions.loadMeetingTemplate(),
              ),
              this.store.dispatch(
                AdminMeetingQuestionBankPageActions.loadMeetingQuestions(),
              ),
              this.store.dispatch(
                MeetingCategoriesActions.loadMeetingCategories(),
              );
          }),
          map(({ user, company, segments, segmentTypes, companyLogo }) => {
            return fromAuthActions.bootstrapAppSuccess({
              user,
              company,
              segments,
              segmentTypes,
              companyLogo,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(fromAuthActions.logoutAfterError({ error })),
          ),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromAuthActions.logoutFromNavbar,
        fromAuthActions.logoutFromMobile,
        fromAuthActions.logoutAfterError,
      ),
      map((_) => {
        this.legacyStore.logoutFromStore();
        this.notifs.hideAll();
        this.filters.resetFilters();
        this.authService.deleteToken();
        return fromAuthActions.resetStoreAfterLogout();
      }),
    ),
  );

  redirectAfterLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromAuthActions.logoutFromNavbar,
        fromAuthActions.logoutFromMobile,
        fromAuthActions.logoutAfterError,
      ),
      switchMap((_) =>
        this.actions$.pipe(ofType(fromAuthActions.resetStoreAfterLogout)),
      ),
      map((_) => fromAuthActions.redirectAfterLogout()),
    ),
  );
}
