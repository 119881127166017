import { FiltersActions } from '@core/store/actions/filters.actions';
import { createReducer, on } from '@ngrx/store';

export const filtersSegmentationFeatureKey = 'segmentation';
export const filtersPopulationKey = 'population';

export interface FiltersSegmentationState {
  // key: segmentTypeId, value: segmentId[]
  [key: number]: number[];
}

export const filtersSegmentationInitialState: FiltersSegmentationState = {
  // key: segmentTypeId, value: segmentId[]
};

export const filtersSegmentationReducer = createReducer(
  filtersSegmentationInitialState,
  on(
    FiltersActions.setSelectedSegmentsForType,
    (state, { segmentTypeId, segmentIds }) => {
      if (state[segmentTypeId]) {
        if (segmentIds.length) {
          return {
            ...state,
            [segmentTypeId]: segmentIds,
          };
        } else {
          const newState = { ...state };
          delete newState[segmentTypeId];
          return newState;
        }
      } else {
        return {
          ...state,
          [segmentTypeId]: segmentIds,
        };
      }
    },
  ),
  on(
    FiltersActions.resetSegmentsFilter,
    (_) => filtersSegmentationInitialState,
  ),
  on(FiltersActions.setSegmentsFiltersFromUrl, (state, { segmentation }) => {
    if (state[segmentation.segmentTypeId]) {
      return {
        ...state,
        [segmentation.segmentTypeId]: [
          ...state[segmentation.segmentTypeId],
          ...segmentation.segmentIds,
        ],
      };
    } else {
      return {
        ...state,
        [segmentation.segmentTypeId]: segmentation.segmentIds,
      };
    }
  }),
);
