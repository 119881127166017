import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { FeedbackQuestionService } from '../../services/feedback-question.service';
import * as fromQuestion from '../actions/question.actions';

@Injectable()
export class FeedbackQuestionsEffects {
  constructor(
    private actions$: Actions,
    private questions: FeedbackQuestionService,
  ) {}

  loadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestion.loadQuestions),
      exhaustMap((_) =>
        this.questions
          .loadAll()
          .pipe(
            map((questions) =>
              fromQuestion.loadQuestionsSuccess({ questions }),
            ),
          ),
      ),
    ),
  );
  getQuestionsForSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestion.getQuestionsForSurvey),
      exhaustMap(({ surveyId }) =>
        this.questions
          .getSurveyQuestions(surveyId)
          .pipe(
            map((questions) =>
              fromQuestion.getQuestionsForSurveySuccess({ questions }),
            ),
          ),
      ),
    ),
  );
  addQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestion.addQuestion),
      exhaustMap((action) =>
        this.questions.create(action.question).pipe(
          map(({ id, defaultLocale }) =>
            fromQuestion.addQuestionSuccess({
              question: {
                id,
                defaultLocale,
                ...action.question,
              },
            }),
          ),
        ),
      ),
    ),
  );
  upsertQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestion.upsertQuestion),
      exhaustMap(({ question }) => {
        if (question.id) {
          return this.questions.update(question).pipe(
            map(({ id, defaultLocale }) => {
              const changes = { ...question, defaultLocale };
              return fromQuestion.updateQuestionSuccess({
                question: { id, changes },
              });
            }),
          );
        } else {
          return this.questions.create(question).pipe(
            map(({ id, defaultLocale }) => {
              return fromQuestion.addQuestionSuccess({
                question: { id, ...question, defaultLocale },
              });
            }),
          );
        }
      }),
    ),
  );
  deleteQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuestion.deleteQuestion),
      exhaustMap(({ id }) =>
        this.questions
          .delete(id)
          .pipe(map((_) => fromQuestion.deleteQuestionSuccess({ id }))),
      ),
    ),
  );
}
