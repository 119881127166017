import { AdminMeetingCategoryModalComponent } from '@admin/components/admin-meeting-modals/admin-meeting-category-modal/admin-meeting-category-modal.component';
import { AdminMeetingDeleteCategoryModalComponent } from '@admin/components/admin-meeting-modals/admin-meeting-delete-category-modal/admin-meeting-delete-category-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { MeetingCategoryService } from '../../services/meeting-category.service';
import * as fromCategoriesActions from '../actions/meeting-category.actions';
import { IQuestionCategory } from '../models/meeting-question-category.model';

const MODAL_WIDTH = '600px';

@Injectable()
export class MeetingCategoryEffects {
  constructor(
    private actions$: Actions,
    private categoryService: MeetingCategoryService,
    private store: Store,
    public dialog: MatDialog,
  ) {}

  /**
   * * LOAD *
   */
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCategoriesActions.MeetingCategoriesActions.loadMeetingCategories,
      ),
      switchMap(() =>
        this.categoryService.getAll().pipe(
          map((categories: IQuestionCategory[]) =>
            fromCategoriesActions.MeetingCategoriesApiActions.loadMeetingCategoriesSuccess(
              { categories },
            ),
          ),
          catchError((error) =>
            of(
              fromCategoriesActions.MeetingCategoriesErrorAction.errorMeetingCategory(
                { error },
              ),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * * ADD *
   */

  addCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCategoriesActions.MeetingCategoriesActions.addMeetingCategory),
      exhaustMap(({ category }) =>
        this.categoryService.create(category).pipe(
          map(({ id, createdAt, companyId }) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.CREATE_CATEGORY_SUCCESS',
              }),
            );
            return fromCategoriesActions.MeetingCategoriesApiActions.addMeetingCategorySuccess(
              {
                category: { id, createdAt, companyId, ...category },
              },
            );
          }),
          catchError((error) => {
            this.store.dispatch(
              fromCategoriesActions.MeetingCategoriesErrorAction.errorMeetingCategory(
                { error },
              ),
            );

            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  /**
   * * UPSERT *
   */
  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCategoriesActions.MeetingCategoriesActions.updateMeetingCategory,
      ),
      exhaustMap(({ category }) => {
        const { id, ...categoryBody } = category;
        return this.categoryService.patch(category.id, categoryBody).pipe(
          map(({ id, name }) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.UPDATE_CATEGORY_SUCCESS',
              }),
            );

            return fromCategoriesActions.MeetingCategoriesApiActions.updateMeetingCategorySuccess(
              {
                category: {
                  id,
                  changes: { ...category, name },
                },
              },
            );
          }),
          catchError((error) => {
            this.store.dispatch(
              fromCategoriesActions.MeetingCategoriesErrorAction.errorMeetingCategory(
                { error },
              ),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  /**
   * * DELETE *
   */
  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCategoriesActions.MeetingCategoriesActions.deleteMeetingCategory,
      ),
      exhaustMap(({ id }) =>
        this.categoryService.delete(id).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.DELETE_CATEGORY_SUCCESS',
              }),
            );
            return fromCategoriesActions.MeetingCategoriesApiActions.deleteMeetingCategorySuccess(
              { id },
            );
          }),
          catchError((error) => {
            this.store.dispatch(
              fromCategoriesActions.MeetingCategoriesErrorAction.errorMeetingCategory(
                { error },
              ),
            );
            return of(showError({error: 'ALERTS.GENERIC_ERROR'}));
          }),
        ),
      ),
    ),
  );

  /**
   * * MODAL *
   */

  showAddOrUpdateCategoryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromCategoriesActions.AdminMeetingCategoriesModalActions
            .showAddMeetingCategoryModal,
        ),

        map((action) =>
          this.dialog.open(AdminMeetingCategoryModalComponent, {
            width: MODAL_WIDTH,
            data: {
              category: action.category,
              categoryId: action.category?.id,
            },
          }),
        ),
      ),
    { dispatch: false },
  );

  showDeleteCategoryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromCategoriesActions.AdminMeetingCategoriesModalActions
            .showDeleteMeetingCategoryModal,
        ),

        map((action) =>
          this.dialog.open(AdminMeetingDeleteCategoryModalComponent, {
            width: MODAL_WIDTH,
            data: {
              category: action.category,
            },
          }),
        ),
      ),
    { dispatch: false },
  );

  hideCategoryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromCategoriesActions.MeetingCategoriesApiActions
            .deleteMeetingCategorySuccess,
          fromCategoriesActions.MeetingCategoriesApiActions
            .updateMeetingCategorySuccess,
          fromCategoriesActions.MeetingCategoriesApiActions
            .addMeetingCategorySuccess,
        ),
        map(() => this.dialog.closeAll()),
      ),
    { dispatch: false },
  );
}
