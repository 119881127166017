import { AdminCompanyService } from '@admin/services/admin-company.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from '@shared/services/company.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import * as fromCompany from '../actions/company.actions';
import { showError, showSuccess } from '../actions/toast.actions';
import { LegacyStore } from '../legacy/store.service';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private company: CompanyService,
    private adminCompanyService: AdminCompanyService,
    private legacyStore: LegacyStore,
    private store: Store,
  ) {}

  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompany.getCompany),
      exhaustMap(({ companyId }) =>
        this.company.getCompany(companyId).pipe(
          tap((company) => this.legacyStore.setCompany(company)),
          map((company) => fromCompany.getCompanySuccess({ company })),
        ),
      ),
    ),
  );

  updateCompanySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCompany.updateCompanyGlobalSettings,
        fromCompany.updateCompanyWelcomeText,
      ),
      switchMap(({ settings }) =>
        this.adminCompanyService.patchCompany(settings).pipe(
          tap((_) =>
            this.store.dispatch(
              showSuccess({ successMessage: 'COMPANY.CONFIRM_UPDATE' }),
            ),
          ),
          map((_) =>
            fromCompany.updateCompanySettingsSuccess({
              companyUpdates: settings,
            }),
          ),
          catchError((_) => of(showError({ error: 'GENERIC_ERROR' }))),
        ),
      ),
    ),
  );
}
