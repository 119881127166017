import { User } from '@models/user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  segmentTypesAdapter,
  segmentTypesFeatureKey,
} from '../reducers/segmentation/segment-type.reducer';
import {
  segmentsAdapter,
  segmentsFeatureKey,
} from '../reducers/segmentation/segment.reducer';
import {
  segmentationFeatureKey,
  SegmentationState,
} from '../reducers/segmentation/segmentation.reducer';
import { selectUser, selectUserSegments } from './core.selectors';
import {
  SegmentType,
  Segment,
  SegmentTypeSegmentsOrderTypeEnum,
} from '@models/segments.model';

export const selectSegmentationState = createFeatureSelector<SegmentationState>(
  segmentationFeatureKey,
);

// TYPES
export const selectSegmentTypesState = createSelector(
  selectSegmentationState,
  (segmentation) => segmentation[segmentTypesFeatureKey],
);

export const {
  selectIds: selectSegmentTypeIds,
  selectEntities: selectSegmentTypeEntities,
  selectAll: selectAllSegmentTypes,
  selectTotal: selectSegmentTypesTotal,
} = segmentTypesAdapter.getSelectors(selectSegmentTypesState);

// SEGMENTS
export const selectSegmentsState = createSelector(
  selectSegmentationState,
  (segmentation) => segmentation[segmentsFeatureKey],
);

export const {
  selectIds: selectSegmentIds,
  selectEntities: selectSegmentEntities,
  selectAll: selectAllSegments,
  selectTotal: selectSegmentsTotal,
} = segmentsAdapter.getSelectors(selectSegmentsState);

export const selectSegmentsBySegmentTypeId = (id) =>
  createSelector(
    selectAllSegments,
    selectAllSegmentTypes,

    (segments, segmentTypes) => {
      const segmentType: SegmentType = segmentTypes.find(
        (type) => type.id === id,
      );
      segments = segments.filter((segment) => segment.segmentTypeId === id);
      switch (segmentType?.segmentsOrderType) {
        case SegmentTypeSegmentsOrderTypeEnum.ALPHA_ASC:
          return segments.sort((a, b) => a.name.localeCompare(b.name));
        case SegmentTypeSegmentsOrderTypeEnum.ALPHA_DESC:
          return segments.sort((a, b) => b.name.localeCompare(a.name));
        case SegmentTypeSegmentsOrderTypeEnum.MANUAL:
          return segments.sort((a, b) => a.order - b.order);
        default:
          return segments.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
  );

export const selectSegmentationStateLoadingSegments = createSelector(
  selectSegmentationState,
  (state) => state.loadingSegments,
);

export const selectUserSegmentsMap = createSelector(
  // TODO matrixOrgaRollupFF remove Type casting when selector is not conditional anymore
  selectUser,
  (user: User) => {
    const userSegmentsMap = {};
    user.segments.forEach((s) => {
      (userSegmentsMap[s.segmentTypeId] =
        userSegmentsMap[s.segmentTypeId] || []).push(s);
    });
    return userSegmentsMap;
  },
);
export const selectManagedSegmentsMap = createSelector(
  // TODO matrixOrgaRollupFF remove Type casting when selector is not conditional anymore
  selectUser,
  (user: User) => {
    const managedSegmentsMap: { [typeId: number]: Segment[] } = {};
    user.managedSegments.forEach((s) => {
      (managedSegmentsMap[s.segmentTypeId] =
        managedSegmentsMap[s.segmentTypeId] || []).push(s);
    });
    return managedSegmentsMap;
  },
);

export const selectSegmentById = (id) => {
  return createSelector(selectSegmentEntities, (entities) => entities[id]);
};

export const selectSegmentsByIds = (ids) => {
  return createSelector(selectSegmentEntities, (entities) =>
    ids.map((id) => entities[id]),
  );
};

export const selectSegmentsDepartmentsBySegmentTypeMap = createSelector(
  selectAllSegments,
  selectAllSegmentTypes,
  selectUserSegments,
  (segments, types, userSegments) => {
    const segmentByTypeIdMap: Map<
      number,
      { segmentType: SegmentType; segments: Segment[] }
    > = new Map();

    const userSegmentIds = userSegments.map((s) => s.id);

    types.forEach((type) => {
      if (type.isDepartment) {
        const segmentsInDepartmentSegmentType = segments.filter(
          (segment) => segment.segmentTypeId === type.id,
        );

        const userSegmentsPartOfDepartment =
          segmentsInDepartmentSegmentType.filter((s) =>
            userSegmentIds.includes(s.id),
          );

        if (userSegmentsPartOfDepartment?.length) {
          segmentByTypeIdMap.set(type.id, {
            segmentType: type,
            segments: userSegmentsPartOfDepartment,
          });
        }
      }
    });
    return segmentByTypeIdMap;
  },
);

export const selectUserSegmentsDepartmentsBySegmentTypeMap = createSelector(
  selectAllSegments,
  selectAllSegmentTypes,
  selectUserSegments,
  (segments, types, userSegments) => {
    const segmentByTypeIdMap: Map<
      number,
      { segmentType: SegmentType; segments: Segment[] }
    > = new Map();
    types.forEach((type) => {
      if (type.isDepartment) {
        if (
          segments &&
          segments.filter(
            (segment) =>
              segment.segmentTypeId === type.id &&
              userSegments.map((s) => s.id).includes(segment.id),
          ).length > 0
        ) {
          segmentByTypeIdMap.set(type.id, {
            segmentType: type,
            segments: segments.filter(
              (segment) =>
                segment.segmentTypeId === type.id &&
                userSegments.map((s) => s.id).includes(segment.id),
            ),
          });
        }
      }
    });
    return segmentByTypeIdMap;
  },
);

export const selectUserManagedSegmentsDepartmentsBySegmentTypeMap =
  createSelector(
    selectUser,
    selectAllSegments,
    selectAllSegmentTypes,
    (user, segments, types) => {
      // TODO remove forcedCast when matrixOrgaRollupFF is removed
      const castedUser: User = user as User;
      const segmentByTypeIdMap: Map<
        number,
        { segmentType: SegmentType; segments: Segment[] }
      > = new Map();
      types.forEach((type) => {
        if (type.isDepartment) {
          if (
            segments &&
            segments.filter(
              (segment) =>
                segment.segmentTypeId === type.id &&
                castedUser.managedSegments
                  .map((s) => s.id)
                  .includes(segment.id),
            ).length > 0
          ) {
            segmentByTypeIdMap.set(type.id, {
              segmentType: type,
              segments: segments.filter(
                (segment) =>
                  segment.segmentTypeId === type.id &&
                  castedUser.managedSegments
                    .map((s) => s.id)
                    .includes(segment.id),
              ),
            });
          }
        }
      });
      return segmentByTypeIdMap;
    },
  );

export const selectSegmentByTypeIdMap = createSelector(
  selectAllSegments,
  selectAllSegmentTypes,
  (segments, types) => {
    const segmentByTypeIdMap: Map<
      number,
      { segmentType: SegmentType; segments: Segment[] }
    > = new Map();
    types.forEach((type) => {
      segmentByTypeIdMap.set(type.id, {
        segmentType: type,
        segments: segments.filter(
          (segment) => segment.segmentTypeId === type.id,
        ),
      });
    });
    return segmentByTypeIdMap;
  },
);

export const selectSegmentsIdsFromSegmentTypeId = (id) =>
  createSelector(selectAllSegments, (segments) =>
    segments
      .filter((segment) => segment.segmentTypeId === id)
      .map((segment) => segment.id),
  );
