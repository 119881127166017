import {
  PulseParticipation,
  PulseSurveyFull,
  PulseSurveyFullWithStatus,
  PulseSurveyResults,
} from '@models/pulse.model';
import { WordCloud } from '@models/wordcloud.model';
import { createAction, props } from '@ngrx/store';

export const getPulseSurveysForSurveysPage = createAction(
  '[Pulse Surveys Page] Get Surveys',
  props<{ status?: 'finished' | 'inProgress'; limit: number }>(),
);

export const getPulseSurveysForSurveysPageSuccess = createAction(
  '[API/Pulse Surveys] Get Surveys For Surveys Page Success',
  props<{
    surveys: Array<PulseSurveyFull & { status: string }>;
    participation: PulseParticipation[];
  }>(),
);

export const getPulseSurveyResultsForSurveysPage = createAction(
  '[Pulse Surveys Page] Get Survey Results',
  props<{ surveyId: number }>(),
);

export const getPulseSurveyResultsForSurveysPageSuccess = createAction(
  '[API/Pulse Surveys] Get Surveys Rsults For Surveys Page Success',
  props<{
    wordCloud: WordCloud;
    commentsCount: number;
    surveyResults: PulseSurveyResults;
  }>(),
);

export const getPulseSurveyParticipationForSurveysPage = createAction(
  '[Pulse Surveys Page] Get Survey Participation',
  props<{ surveyId: number }>(),
);

export const getPulseSurveyParticipationForSurveysPageSuccess = createAction(
  '[Pulse Surveys Page] Get Survey Participation Success',
  props<{ participation: PulseParticipation }>(),
);

export const getPulseSurveysForExcelReportPage = createAction(
  '[Pulse Surveys Excel Report Page] Get Surveys List',
  props<{ limit: number }>(),
);

// TODO refacto when possible as getSurveys_1_1_0 allows status 'inProgress'
export const getPulseSurveysForExcelReportPageSuccess = createAction(
  '[API/Pulse Surveys Excel Report Page] Get Surveys List Success',
  props<{
    surveys: Array<PulseSurveyFull & { status: 'inProgress' | 'finished' }>;
  }>(),
);

export const loadPulseSurveysForAdmin = createAction(
  '[Admin Pulse Surveys] Load Surveys',
);

export const loadPulseSurveysForAdminSuccess = createAction(
  '[API/PulseSurvey] Load Surveys for Admin Success',
  props<{ surveys: PulseSurveyFullWithStatus[] }>(),
);

export const loadPulseSurveysForComments = createAction(
  '[Pulse Commments] Load Surveys',
);

export const loadPulseSurveysForDiagnostics = createAction(
  '[Pulse Diagnostics] Load Surveys',
);

export const loadPulseSurveysForExcel = createAction(
  '[Pulse Excel] Load Surveys',
);

export const loadPulseSurveysForPowerpoint = createAction(
  '[Pulse Powerpoint] Load Surveys',
);

export const loadPulseSurveysForPDF = createAction('[Pulse PDF] Load Surveys');

export const loadPulseSurveysSuccess = createAction(
  '[API/PulseSurvey] Load Surveys Success',
  props<{ surveys: PulseSurveyFullWithStatus[] }>(),
);
