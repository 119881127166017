import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { GdprConsentModalComponent } from '@core/components/gdpr-consent-modal/gdpr-consent-modal.component';
import { GdprRulesModalComponent } from '@core/components/gdpr-rules-modal/gdpr-rules-modal.component';
import { DEFAULT_MODAL_WIDTH } from '@core/constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { UsersService } from '@shared/services/users.service';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getCompany } from '../actions/company.actions';
import { showError } from '../actions/toast.actions';
import * as UserActions from '../actions/user.actions';
import { LegacyStore } from '../legacy/store.service';
import { selectCompany, selectUserId } from '../selectors/core.selectors';
import { SegmentsService } from '@shared/services/segments.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private users: UsersService,
    private store: Store,
    private legacyStore: LegacyStore,
    private transloco: TranslocoService,
    private dateAdapter: DateAdapter<Date>,
    private segments: SegmentsService,
    public dialog: MatDialog,
  ) {}

  updateCurrentUserSegmentIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserSegments),
      switchMap(({ segmentIds }) => {
        return this.segments.getSegments().pipe(
          map((segments) => {
            const filteredSegments = segments.filter((segment) =>
              segmentIds.includes(segment.id),
            );
            return UserActions.updateUserSegmentsSuccess({
              segments: filteredSegments,
            });
          }),
        );
      }),
    ),
  );

  updateUserLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserLocale),
      concatMap(({ locale }) =>
        of(locale).pipe(
          withLatestFrom(
            this.store.pipe(select(selectUserId)),
            this.store.pipe(select(selectCompany)),
          ),
        ),
      ),
      exhaustMap(([locale, userId, company]) => {
        return this.users.update(userId, { locale }).pipe(
          tap((_) => this.legacyStore.updateCurrentUser({ locale })),
          map((_) => {
            this.store.dispatch(getCompany({ companyId: company.id }));
            this.transloco.setActiveLang(locale);
            this.dateAdapter.setLocale(locale);
            return UserActions.updateUserLocaleSuccess({ locale });
          }),
        );
      }),
    ),
  );

  gdprConsentPrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GDPRConsentPrompt),
        map(({ userId, gdprText }) =>
          this.dialog.open(GdprConsentModalComponent, {
            disableClose: true,
            autoFocus: false,
            width: DEFAULT_MODAL_WIDTH,
            data: { userId, gdprText },
          }),
        ),
      ),
    { dispatch: false },
  );

  gdprReminder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.showGDPRReminder),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(selectCompany))),
          ),
        ),
        map(([_, company]) =>
          this.dialog.open(GdprRulesModalComponent, {
            width: DEFAULT_MODAL_WIDTH,
            autoFocus: false,
            data: { gdprText: company.gdprText },
          }),
        ),
      ),
    { dispatch: false },
  );

  gdprGiveConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GDPRGiveConsent),
      exhaustMap(({ userId }) =>
        this.users.update(userId, { gdprConsentGiven: true }).pipe(
          map((_) => UserActions.GDPRGiveConsentSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(
              UserActions.updateUserFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserFailure),
      map((_) => showError({ error: 'ALERTS.GENERIC_ERROR' })),
    ),
  );
}
