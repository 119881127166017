import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  ICreateQuestionCategory,
  IPatchQuestionCategory,
  IQuestionCategory,
} from '../store/models/meeting-question-category.model';

@Injectable({
  providedIn: 'root',
})
export class MeetingCategoryService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/questionCategories`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<IQuestionCategory[]> {
    return this.http.get<IQuestionCategory[]>(this._ENDPOINT_URL);
  }

  create(category: ICreateQuestionCategory): Observable<IQuestionCategory> {
    return this.http.post<IQuestionCategory>(this._ENDPOINT_URL, category);
  }

  patch(
    categoryId: string,
    categoryToPatch: IPatchQuestionCategory,
  ): Observable<IQuestionCategory> {
    return this.http.patch<IQuestionCategory>(
      `${this._ENDPOINT_URL}/${categoryId}`,
      categoryToPatch,
    );
  }
  delete(categoryId: string): Observable<void> {
    return this.http.delete<void>(`${this._ENDPOINT_URL}/${categoryId}`);
  }
}
