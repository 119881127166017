import { Action, combineReducers } from '@ngrx/store';
import * as fromDriver from './driver.reducer';
import * as fromTopic from './topic.reducer';
import * as fromCommentsPage from './comments-page.reducer';
import * as fromPulseSurvey from './pulse-survey.reducer';
import * as fromDriverLastSurveyResults from './driver-last-survey-results.reducer';

export const pulseFeatureKey = 'pulse';

export interface PulseState {
  [fromDriver.pulseDriversFeatureKey]: fromDriver.PulseDriverState;
  [fromTopic.pulseTopicsFeatureKey]: fromTopic.PulseTopicState;
  [fromCommentsPage.commentsPageFeatureKey]: fromCommentsPage.CommentsPageState;
  [fromPulseSurvey.pulseSurveysFeatureKey]: fromPulseSurvey.PulseSurveyState;
  [fromDriverLastSurveyResults.driverLastSurveyResultsFeatureKey]: fromDriverLastSurveyResults.DriverLastSurveyResultsState;
}

export const initialState: PulseState = {
  [fromDriver.pulseDriversFeatureKey]: fromDriver.initialState,
  [fromTopic.pulseTopicsFeatureKey]: fromTopic.initialState,
  [fromCommentsPage.commentsPageFeatureKey]: fromCommentsPage.initialState,
  [fromPulseSurvey.pulseSurveysFeatureKey]:
    fromPulseSurvey.pulseSurveyInitialState,
  [fromDriverLastSurveyResults.driverLastSurveyResultsFeatureKey]:
    fromDriverLastSurveyResults.initialState,
};

export function reducers(state: PulseState | undefined, action: Action) {
  return combineReducers({
    [fromDriver.pulseDriversFeatureKey]: fromDriver.reducer,
    [fromTopic.pulseTopicsFeatureKey]: fromTopic.reducer,
    [fromCommentsPage.commentsPageFeatureKey]: fromCommentsPage.reducers,
    [fromPulseSurvey.pulseSurveysFeatureKey]:
      fromPulseSurvey.pulseSurveyReducer,
    [fromDriverLastSurveyResults.driverLastSurveyResultsFeatureKey]:
      fromDriverLastSurveyResults.reducer,
  })(state, action);
}
