import { XOR } from '@models/API.models';
import { BleexoLocale, i18nObject } from '@models/bleexo.models';
import { Company } from '@models/company.model';
import { createAction, props } from '@ngrx/store';

export const getCompany = createAction(
  'Get Company',
  props<{ companyId: number }>(),
);
export const getCompanySuccess = createAction(
  'Get Company Success',
  props<{ company: Company }>(),
);

export const updateCompanyGlobalSettings = createAction(
  '[Admin Company] Update Global Settings',
  props<{
    settings: { defaultLocale: BleexoLocale; departmentId?: number };
  }>(),
);

export const updateCompanyWelcomeText = createAction(
  '[Admin Welcome Text] Update Text',
  props<{
    settings: { welcomeText: i18nObject };
  }>(),
);

export const updateCompanySettingsSuccess = createAction(
  '[Admin/API] Update Company Settings Success',
  props<{
    companyUpdates: XOR<
      { defaultLocale: BleexoLocale; departmentId?: number },
      { welcomeText: i18nObject }
    >;
  }>(),
);
