import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as categoryActions from 'src/app/modules/meeting/store/actions/meeting-category.actions';
import { selectMeetingCategoryLoadingState } from 'src/app/modules/meeting/store/selector/meeting-category.selector';

@Component({
  selector: 'blx-admin-meeting-delete-category-modal',
  templateUrl: './admin-meeting-delete-category-modal.component.html',
})
export class AdminMeetingDeleteCategoryModalComponent {
  /**
   * * OBSERVABLES *
   */
  loading$: Observable<boolean> = this.store.pipe(
    select(selectMeetingCategoryLoadingState),
  );

  constructor(
    public dialogRef: MatDialogRef<AdminMeetingDeleteCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store,
  ) {}

  /**
   * * PROTECTED *
   */
  protected delete(): void {
    this.store.dispatch(
      categoryActions.MeetingCategoriesActions.deleteMeetingCategory({
        id: this.data.category.id,
      }),
    );
  }
}
