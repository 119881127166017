<h1 mat-dialog-title>{{ 'GDPR.GDPR_RULES' | transloco }}</h1>

<div mat-dialog-content
     class="pb-5">
  <div class="flex justify-center items-center mb-2.5">
    <img class="bleexo-logo mx-2.5 mb-2.5"
         src="assets/img/logo/logo_B-color.svg"
         alt="bleexo logo"
         width="70px">
    <img class="bleexo-logo-text mx-2.5 mb-2.5"
         src="assets/img/logo/logo_bleexo-grey.svg"
         alt="bleexo brand"
         height="42px">
  </div>

  <display-markdown [text]="data.gdprText | localize"
                    className="text-body">
  </display-markdown>

  <mat-dialog-actions class="justify-center">
    <button mat-button
            color="primary"
            mat-dialog-close>{{ 'GLOBAL.CLOSE' | transloco | uppercase }}</button>
  </mat-dialog-actions>