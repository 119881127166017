import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { MeetingService } from '@meeting/services/meeting.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  EDataMutation,
  PostHogService,
} from '@shared/services/posthog.service';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromMeetings from '../actions/meeting.actions';
import { EMeetingStatuses } from '../models/meeting.model';
import { selectStartedMeeting } from '../selector/meeting.selectors';

@Injectable()
export class MeetingsEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _meetingService: MeetingService,
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
    private readonly postHogService: PostHogService,
  ) {}

  loadStartedMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.loadStartedMeetings),
      exhaustMap((_) =>
        this._meetingService._meetings$.pipe(
          map((meetings) => {
            const startedMeetings = meetings.filter((m) =>
              [
                EMeetingStatuses.STARTED,
                EMeetingStatuses.PREPARED_BY_REVIEWEE,
                EMeetingStatuses.PREPARED_BY_REVIEWER,
                EMeetingStatuses.PREPARED_BY_BOTH,
                EMeetingStatuses.READY_TO_SIGN,
                EMeetingStatuses.FINISHED_BY_REVIEWEE,
                EMeetingStatuses.FINISHED_BY_REVIEWER,
              ].includes(m.status),
            );
            return fromMeetings.loadStartedMeetingsSuccess({
              meetings: startedMeetings,
            });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  loadIdleMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.loadIdleMeetings),
      exhaustMap((_) =>
        this._meetingService._meetings$.pipe(
          map((meetings) => {
            const idleMeetings = meetings.filter(
              (m) => m.status === EMeetingStatuses.CREATED,
            );
            return fromMeetings.loadIdleMeetingsSuccess({
              meetings: idleMeetings,
            });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  loadEndedMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.loadEndedMeetings),
      exhaustMap((_) =>
        this._meetingService._meetings$.pipe(
          map((meetings) => {
            const endedMeetings = meetings.filter(
              (m) => m.status === EMeetingStatuses.CLOSED_BY_BOTH,
            );
            return fromMeetings.loadEndedMeetingsSuccess({
              meetings: endedMeetings,
            });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  getMeeting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.getMeeting),
      exhaustMap(({ meetingId }) =>
        this._meetingService.getOne(meetingId).pipe(
          map((meeting) => fromMeetings.getMeetingSuccess({ meeting })),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  loadMultipleMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.loadMultipleMeetings),
      filter((action) => !!action.ids.length),
      exhaustMap(({ ids }) =>
        this._meetingService
          .getMultiple(ids)
          .pipe(
            map((meetings) =>
              fromMeetings.loadStartedMeetingsSuccess({ meetings }),
            ),
          ),
      ),
    ),
  );
  createMeeting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.createMeeting),
      exhaustMap(({ meeting: createMeeting }) =>
        this._meetingService.create(createMeeting).pipe(
          map((meeting) => {
            this.postHogService.emitMeetingEvent$$.next({
              mutation: EDataMutation.CREATED,
              meeting,
            });

            return fromMeetings.createMeetingSuccess({ meeting });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  createCampaign$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.createCampaign),
      exhaustMap(({ campaign }) =>
        this._meetingService.createCampaign(campaign).pipe(
          map((createdCampaign) => {
            this.postHogService.emitCampaignEvent$$.next({
              mutation: EDataMutation.CREATED,
              campaign: createdCampaign,
            });

            return fromMeetings.createCampaignSuccess({
              campaign: createdCampaign,
            });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.campaignFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  editMeeting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.updateMeeting),
      mergeMap(({ meetingId, meeting }) => {
        return this._meetingService.patch(meetingId, meeting).pipe(
          map((editedMeeting) => {
            const updatedMeeting = {
              id: meetingId,
              changes: editedMeeting,
            };
            return fromMeetings.updateMeetingSuccess({
              updatedMeeting,
              meeting: editedMeeting,
            });
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  deleteMeeting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.deleteMeeting),
      exhaustMap(({ id }) =>
        this._meetingService.delete(id).pipe(
          map((meeting) => {
            this._store.dispatch(
              showSuccess({ successMessage: 'MEETING.DELETE_MEETING_SUCCESS' }),
            );

            this.postHogService.emitMeetingEvent$$.next({
              mutation: EDataMutation.DELETED,
              meeting,
            });

            return fromMeetings.deleteMeetingSuccess({ id });
          }),
          tap(() => this._dialog.closeAll()),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  refreshStartedMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fromMeetings.createMeetingSuccess,
        fromMeetings.updateMeetingSuccess,
        fromMeetings.deleteMeetingSuccess,
      ),
      tap(() => this._meetingService.refreshMeetings()),
      map(() => fromMeetings.loadStartedMeetings()),
    ),
  );

  refreshIdleMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fromMeetings.createMeetingSuccess,
        fromMeetings.updateMeetingSuccess,
        fromMeetings.deleteMeetingSuccess,
      ),
      tap(() => this._meetingService.refreshMeetings()),
      map(() => fromMeetings.loadIdleMeetings()),
    ),
  );

  refreshEndedMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fromMeetings.updateMeetingSuccess,
        fromMeetings.deleteMeetingSuccess,
      ),
      tap(() => this._meetingService.refreshMeetings()),
      map(() => fromMeetings.loadEndedMeetings()),
    ),
  );

  updateEndedMeetings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetings.getMeetingUpdatedPropertiesSuccess),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this._store.pipe(select(selectStartedMeeting(action.id))),
          ),
        ),
      ),
      filter(
        ([_, meeting]) => meeting?.status === EMeetingStatuses.CLOSED_BY_BOTH,
      ),
      mergeMap(([_, meeting]) => of(fromMeetings.addEndedMeeting({ meeting }))),
    ),
  );

  forceStartMeeting$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fromMeetings.forceStartMeeting),
      exhaustMap(({ meetingId }) =>
        this._meetingService.startOne(meetingId).pipe(
          map(() => {
            this._store.dispatch(
              showSuccess({ successMessage: 'MEETING.FORCE_START_SUCCESS' }),
            );

            return fromMeetings.forceStartMeetingSuccess();
          }),
          catchError((error: Error) => {
            this._store.dispatch(fromMeetings.meetingFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    );
  });

  forceStartMeetingSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fromMeetings.forceStartMeetingSuccess),
      switchMap(() => [
        fromMeetings.loadIdleMeetings(),
        fromMeetings.loadStartedMeetings(),
      ]),
      catchError((error: Error) => {
        this._store.dispatch(fromMeetings.meetingFailure({ error }));
        return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
      }),
    );
  });
}
