import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiData, Meta } from '@models/API.models';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { FeedbackCategory } from '../store/models/category.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackCategoryService {
  ENDPOINT = `${environment.API_URL}/feedback/templating/categories`;

  constructor(private http: HttpClient) {}

  loadAll(): Observable<FeedbackCategory[]> {
    const URL = `${this.ENDPOINT}?${qs.stringify({
      meta: {
        orderBy: { property: 'createdAt', direction: 'asc' },
      },
    })}`;
    return this.http.get<ApiData<FeedbackCategory[], Meta>>(URL).pipe(
      retry(1),
      map((apiRes) => apiRes.data),
    );
  }
  create(
    category: Omit<FeedbackCategory, 'id'>,
  ): Observable<Pick<FeedbackCategory, 'id' | 'defaultLocale'>> {
    return this.http
      .post<
        ApiData<Pick<FeedbackCategory, 'id' | 'defaultLocale'>, Meta>
      >(this.ENDPOINT, category)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  update(
    categoryToUpdate: FeedbackCategory,
  ): Observable<Pick<FeedbackCategory, 'id' | 'defaultLocale'>> {
    const { id, ...category } = categoryToUpdate;
    delete category.defaultLocale;
    return this.http
      .patch<
        ApiData<Pick<FeedbackCategory, 'id' | 'defaultLocale'>, Meta>
      >(`${this.ENDPOINT}/${id}`, category)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.ENDPOINT}/${id}`).pipe(retry(1));
  }
}
