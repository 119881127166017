import { createAction, props } from '@ngrx/store';
import { Solicitation } from '@models/solicitation.model';

export const loadSolicitations = createAction(
  '[Solicitations] Load Solicitations',
);

export const loadSolicitationsSuccess = createAction(
  '[Solicitations] Load Solicitations Success',
  props<{ solicitations: Solicitation[] }>(),
);

export const loadSolicitationsFailure = createAction(
  '[Solicitations] Load Solicitations Failure',
  props<{ error: any }>(),
);
