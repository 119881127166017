import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '@core/services/notifications.service';
import { showError, showSuccess } from '../actions/toast.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class ToastsEffects {
  constructor(
    private actions$: Actions,
    private notifications: NotificationService,
  ) {}

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showError),
        map(({ error }) => this.notifications.showError(error)),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSuccess),
        map(({ successMessage: successMEssage }) =>
          this.notifications.showSuccess(successMEssage),
        ),
      ),
    { dispatch: false },
  );
}
