import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ICreateQuestion,
  IPatchQuestion,
  IQuestionResult,
} from '../models/meeting-question.model';

export const AdminMeetingQuestionBankPageActions = createActionGroup({
  source: 'Admin Meeting Question Bank Page',
  events: {
    'Load Meeting Questions': emptyProps(),
    'Add Meeting Question': props<{
      question: ICreateQuestion;
    }>(),
    'Patch Meeting Question': props<{
      questionId: string;
      question: IPatchQuestion;
    }>(),
    'Delete Meeting Question': props<{ id: string }>(),
    'Select Meeting Question': props<{ id: string }>(),
    'Create Meeting Question U I': emptyProps(),
  },
});

export const MeetingQuestionBankAPIActions = createActionGroup({
  source: 'Meeting Questions API',
  events: {
    'Load Meeting Questions Success': props<{
      questions: IQuestionResult[];
    }>(),
    'Add Meeting Question Success': props<{
      question: IQuestionResult;
    }>(),
    'Patch Meeting Question Success': props<{
      question: IQuestionResult;
    }>(),
    'Delete Meeting Question Success': props<{ id: string }>(),
  },
});

export const MeetingQuestionsErrorActions = createActionGroup({
  source: 'Meeting Questions [API]',
  events: {
    'Error Question': props<{ error: Error }>(),
  },
});

export const AdminMeetingQuestionsModalActions = createActionGroup({
  source: 'Admin Meeting Questions Modals',
  events: {
    'Show Delete Meeting Question Modal': props<{
      id: string;
    }>(),
  },
});
