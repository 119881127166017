import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { PulseDriver } from '../models/driver.model';

export const loadDrivers = createAction('[Pulse Component] Load Drivers');
export const loadDriversSuccess = createAction(
  '[Driver/API] Load Drivers Success',
  props<{ drivers: PulseDriver[] }>(),
);

export const addDriver = createAction(
  '[Driver/API] Add Driver',
  props<{ driver: PulseDriver }>(),
);

export const upsertDriver = createAction(
  '[Driver/API] Upsert Driver',
  props<{ driver: PulseDriver }>(),
);

export const addDrivers = createAction(
  '[Driver/API] Add Drivers',
  props<{ drivers: PulseDriver[] }>(),
);

export const upsertDrivers = createAction(
  '[Driver/API] Upsert Drivers',
  props<{ drivers: PulseDriver[] }>(),
);

export const updateDriver = createAction(
  '[Driver/API] Update Driver',
  props<{ driver: Update<PulseDriver> }>(),
);

export const updateDrivers = createAction(
  '[Driver/API] Update Drivers',
  props<{ drivers: Update<PulseDriver>[] }>(),
);

export const deleteDriver = createAction(
  '[Driver/API] Delete Driver',
  props<{ id: number }>(),
);

export const deleteDrivers = createAction(
  '[Driver/API] Delete Drivers',
  props<{ ids: number[] }>(),
);

export const clearDrivers = createAction('[Driver/API] Clear Drivers');
