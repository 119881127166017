import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as driverActions from '../actions/driver.actions';
import { PulseDriver } from '../models/driver.model';

export const pulseDriversFeatureKey = 'drivers';

export interface PulseDriverState extends EntityState<PulseDriver> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<PulseDriver> =
  createEntityAdapter<PulseDriver>();

export const initialState: PulseDriverState = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(driverActions.addDriver, (state, action) =>
    adapter.addOne(action.driver, state),
  ),
  on(driverActions.upsertDriver, (state, action) =>
    adapter.upsertOne(action.driver, state),
  ),
  on(driverActions.addDrivers, (state, action) =>
    adapter.addMany(action.drivers, state),
  ),
  on(driverActions.upsertDrivers, (state, action) =>
    adapter.upsertMany(action.drivers, state),
  ),
  on(driverActions.updateDriver, (state, action) =>
    adapter.updateOne(action.driver, state),
  ),
  on(driverActions.updateDrivers, (state, action) =>
    adapter.updateMany(action.drivers, state),
  ),
  on(driverActions.deleteDriver, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(driverActions.deleteDrivers, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(driverActions.loadDriversSuccess, (state, { drivers }) =>
    adapter.setAll(drivers, { ...state, loading: false, loaded: true }),
  ),
  on(driverActions.clearDrivers, (state) => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
