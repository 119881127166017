import { FiltersScopeLayout } from '@models/filters.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LayoutActions = createActionGroup({
  source: 'Layout',
  events: {
    'Show Banner': emptyProps(),
    'Hide Banner': emptyProps(),
    'Set Mobile View': props<{ isMobile: boolean }>(),
    'Save Scope': props<{ scope: FiltersScopeLayout }>(),
    'Get Scope': emptyProps(),
  },
});

export const LayoutSuccessActions = createActionGroup({
  source: 'Layout Success',
  events: {
    'Save Scope Success': props<{ scope: FiltersScopeLayout }>(),
    'Get Scope Success': props<{ scope: FiltersScopeLayout }>(),
  },
});
