import { createSelector } from '@ngrx/store';
import {
  segmentTypesAdapter,
  segmentTypesFeatureKey,
} from '../reducers/segmentation-legacy/segment-type-v1.reducer';
import { selectCoreState } from './core.selectors';
import { selectSegmentationState } from './segmentation-v1.selectors';

export const selectSegmentTypesState = createSelector(
  selectSegmentationState,
  (segmentation) => segmentation[segmentTypesFeatureKey],
);

export const {
  selectIds: selectSegmentTypeIds,
  selectEntities: selectSegmentTypeEntities,
  selectAll: selectAllSegmentTypesUnfiltered,
  selectTotal: selectSegmentTypesTotal,
} = segmentTypesAdapter.getSelectors(selectSegmentTypesState);

export const selectSegmentTypeById = (id) =>
  createSelector(selectSegmentTypeEntities, (entities) => entities[id]);

export const selectAllSegmentTypes = createSelector(
  selectAllSegmentTypesUnfiltered,
  (segmentTypes) => {
    return segmentTypes.filter((segmentType) => segmentType.segmentIds?.length);
  },
);

export const selectCompanyDepartmentType = createSelector(
  selectAllSegmentTypes,
  selectCoreState,
  (segmentTypes, coreState) => {
    return segmentTypes.find(
      (segmentType) => segmentType.id === coreState.company.departmentId,
    );
  },
);
