import { createSelector } from '@ngrx/store';
import { selectFeedbackState } from './feedback.selectors';
import { adapter as actionableAdapter } from '../reducers/feedback-survey-actionable.reducer';
import { adapter as endedAdapter } from '../reducers/feedback-survey-ended.reducer';
import * as fromActionableSurvey from '../reducers/feedback-survey-actionable.reducer';
import { selectUserId } from '@core/store/selectors/core.selectors';

export const getActionableFeedbackSurveyState = createSelector(
  selectFeedbackState,
  (feedback) => feedback[fromActionableSurvey.actionableSurveysFeatureKey],
);

export const getEndedFeedbackSurveyState = createSelector(
  selectFeedbackState,
  (feedback) => feedback.endedSurveys,
);

export const {
  selectIds: selectActionableIds,
  selectEntities: selectActionableEntities,
  selectAll: selectAllActionable,
  selectTotal: selectActionableTotal,
} = actionableAdapter.getSelectors(getActionableFeedbackSurveyState);

export const {
  selectIds: selectEndedIds,
  selectEntities: selectEndedEntities,
  selectAll: selectAllEndedSurveys,
  selectTotal: selectEndedTotal,
} = endedAdapter.getSelectors(getEndedFeedbackSurveyState);

// actionable
export const selectActionableFeedbackSurveysLoadingState = createSelector(
  getActionableFeedbackSurveyState,
  (state) => state.loading,
);
export const selectActionableFeedbackSurveysLoadedState = createSelector(
  getActionableFeedbackSurveyState,
  (state) => state.isLoaded,
);

export const selectTodosFeedbackSurveys = createSelector(
  selectAllActionable,
  selectUserId,
  (surveys, userId) =>
    surveys.filter(
      (s) =>
        s.status === 'INVITATIONS' ||
        (s.status === 'POLLING' &&
          s.hasOwnProperty('hasAnswered') &&
          s.hasAnswered === false) ||
        (s.status === 'REVISION' && s.coach?.id === userId),
    ),
);

export const selectIdleFeedbackSurveys = createSelector(
  selectAllActionable,
  selectUserId,
  (surveys, userId) =>
    surveys.filter(
      (s) =>
        (s.status === 'READY' && s.reviewee.id === userId) ||
        (s.status === 'POLLING' &&
          s.hasOwnProperty('hasAnswered') &&
          s.hasAnswered === true) ||
        (s.status === 'POLLING' &&
          s.coach?.id === userId &&
          !s.hasOwnProperty('hasAnswered')) ||
        (s.status === 'REVISION' && s.reviewee.id === userId),
    ),
);

export const selectActionableFeedbackSurvey = (id: number) =>
  createSelector(selectActionableEntities, (entities) => entities[id]);

// ended
export const selectEndedFeedbackSurveysLoadingState = createSelector(
  getEndedFeedbackSurveyState,
  (state) => state.loading,
);
export const selectEndedFeedbackSurveysLoadedState = createSelector(
  getEndedFeedbackSurveyState,
  (state) => state.isLoaded,
);
export const selectAllSortedEndedSurveys = createSelector(
  selectAllEndedSurveys,
  (surveys) => surveys.sort((a, b) => (a.endDate < b.endDate ? 1 : -1)),
);
