import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  styleUrls: ['button-with-spinner.component.scss'],
})
export class ButtonWithSpinnerComponent implements OnChanges {
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() addClass: string;
  @Input() buttonStyle: 'mat-button' | 'mat-flat-button' | 'mat-raised-button' =
    'mat-raised-button';
  @Input() color: 'primary' | 'secondary' | 'warn' = 'primary';
  @Output() handleClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      this.isDisabled = changes.isDisabled.currentValue;
    }
  }

  onClick($event) {
    this.handleClick.emit($event);
  }
}
