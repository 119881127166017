import { Action, combineReducers } from '@ngrx/store';
import * as fromCommentsByDriver from './comments-by-driver.reducer';
import * as fromCommentsForm from './comments-form.reducer';

export const commentsPageFeatureKey = 'commentsPage';

export interface CommentsPageState {
  [fromCommentsByDriver.commentsByDriversFeatureKey]: fromCommentsByDriver.CommentsByDriverState;
  [fromCommentsForm.commentsFormFeatureKey]: fromCommentsForm.CommentsFormState;
}

export const initialState: CommentsPageState = {
  [fromCommentsByDriver.commentsByDriversFeatureKey]:
    fromCommentsByDriver.commentsByDriverInitialState,
  [fromCommentsForm.commentsFormFeatureKey]:
    fromCommentsForm.commentsFormInitialState,
};

export function reducers(state: CommentsPageState | undefined, action: Action) {
  return combineReducers({
    [fromCommentsByDriver.commentsByDriversFeatureKey]:
      fromCommentsByDriver.commentsByDriverReducer,
    [fromCommentsForm.commentsFormFeatureKey]:
      fromCommentsForm.commentsFormReducer,
  })(state, action);
}
