import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { FeedbackCompareRoleFiltersEnum } from '@feedback/pages/feedback-compare/feedback-compare.component';
import { ApiData, Meta, OrderBy } from '@models/API.models';
import { BleexoLocale } from '@models/bleexo.models';
import { CompareOrderDirections } from '@models/compare.model';
import { Store } from '@ngrx/store';
import { FiltersBaseService } from '@shared/services/filters-base.service';
import { FiltersLegacyService } from '@shared/services/filters.service';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map, mergeMap, retry } from 'rxjs/operators';
import {
  FeedbackSurvey,
  FeedbackSurveyAnswer,
  FeedbackSurveyDates,
  FeedbackSurveyPayload,
  FeedbackSurveyStatus,
  surveyStepEnum,
} from '../store/models/survey.model';

export interface FeedbackSurveyCreateReponse
  extends FeedbackSurveyDates<string> {
  id: number;
  locale: BleexoLocale;
  questionIds: number[];
  revieweeId: number;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackSurveyService extends FiltersBaseService {
  ENDPOINT_URL = `${environment.API_URL}/feedback/core/surveys`;
  MANAGE_ENDPOINT_URL = `${environment.API_URL}/feedback/management/surveys`;
  COMPARE_ENDPOINT_URL = `${environment.API_URL}/feedback/compare/surveys`;

  constructor(
    private http: HttpClient,
    private filtersLegacy: FiltersLegacyService,
    store: Store,
  ) {
    super(store);
  }

  loadAll(statuses: FeedbackSurveyStatus[]): Observable<FeedbackSurvey[]> {
    return this.http
      .get<
        ApiData<FeedbackSurvey[], Meta>
      >(`${this.ENDPOINT_URL}?${qs.stringify({ body: { statuses } })}`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  loadChunk(
    statuses: FeedbackSurveyStatus[],
    mySurveyRoles: Array<'REVIEWEE' | 'REVIEWER' | 'COACH'> = [
      'REVIEWEE',
      'REVIEWER',
      'COACH',
    ],
    skip = 0,
    limit = 10,
  ): Observable<{
    meta: { total: number };
    endedSurveys: FeedbackSurvey[];
  }> {
    const URL = `${this.ENDPOINT_URL}?${qs.stringify({
      body: { statuses, mySurveyRoles },
      meta: {
        skip,
        limit,
        orderBy: { property: 'endDate', direction: 'desc' },
      },
    })}`;

    return this.http.get<ApiData<FeedbackSurvey[], Meta>>(URL).pipe(
      retry(1),
      map(({ meta, data }) => ({ meta, endedSurveys: data })),
    );
  }
  getOne(surveyId: number): Observable<FeedbackSurvey> {
    return this.http
      .get<ApiData<FeedbackSurvey, Meta>>(`${this.ENDPOINT_URL}/${surveyId}`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  getMultiple(ids: number[]): Observable<FeedbackSurvey[]> {
    return this.http
      .get<
        ApiData<FeedbackSurvey[], Meta>
      >(`${this.ENDPOINT_URL}?${qs.stringify({ body: { ids } })}`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  getUpdatedProperties(
    id: number,
    fields: Array<keyof FeedbackSurvey>,
  ): Observable<Partial<FeedbackSurvey>> {
    return this.http
      .get<ApiData<Partial<FeedbackSurvey>, Meta>>(
        `${this.ENDPOINT_URL}/${id}?${qs.stringify({
          meta: { filters: fields },
        })}`,
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  create(
    survey: FeedbackSurveyPayload & {
      revieweeId: number;
    },
  ): Observable<FeedbackSurvey> {
    return this.http
      .post<
        ApiData<FeedbackSurveyCreateReponse, Meta>
      >(this.ENDPOINT_URL, survey)
      .pipe(
        retry(1),
        mergeMap((apiRes) => {
          return this.getOne(apiRes.data.id);
        }),
      );
  }
  update(
    surveyId: number,
    surveyToUpdate: FeedbackSurveyPayload,
  ): Observable<FeedbackSurvey> {
    return this.http
      .patch<void>(`${this.ENDPOINT_URL}/${surveyId}`, surveyToUpdate)
      .pipe(
        retry(1),
        mergeMap((_) => {
          return this.getOne(surveyId);
        }),
      );
  }
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.ENDPOINT_URL}/${id}`).pipe(retry(1));
  }

  forceStart(id: number): Observable<void> {
    return this.http.post<void>(`${this.ENDPOINT_URL}/${id}/op/start`, {});
  }

  forceStop(id: number): Observable<void> {
    return this.http.post<void>(`${this.ENDPOINT_URL}/${id}/op/stop`, {});
  }

  forceReminders(id: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${id}/op/sendAnswersReminders`,
      {},
    );
  }

  // ---------------
  // COACH
  // ---------------
  addCoach(surveyId: number, coachId: number): Observable<void> {
    return this.http.put<void>(`${this.ENDPOINT_URL}/${surveyId}/coach`, {
      userId: coachId,
    });
  }
  deleteCoach(surveyId: number): Observable<void> {
    return this.http.delete<void>(`${this.ENDPOINT_URL}/${surveyId}/coach`, {});
  }
  shareReport(
    surveyId: number,
  ): Observable<{ coachReportReadDate: Date; status: FeedbackSurveyStatus }> {
    return this.http
      .post<
        ApiData<
          { coachReportReadDate: Date; status: FeedbackSurveyStatus },
          Meta
        >
      >(`${this.ENDPOINT_URL}/${surveyId}/op/shareReport`, {})
      .pipe(map((res) => res.data));
  }
  sendCoachReminder(surveyId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${surveyId}/op/sendRevisionReminder`,
      {},
    );
  }

  // ---------------
  // ANSWERS
  // ---------------
  postAnswers({ surveyId, answers }): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${surveyId}/answers`,
      answers,
    );
  }
  patchAnswers({ surveyId, answers }): Observable<void> {
    return this.http.put<void>(
      `${this.ENDPOINT_URL}/${surveyId}/answers`,
      answers,
    );
  }
  getAnswers(surveyId): Observable<FeedbackSurveyAnswer[]> {
    return this.http
      .get<
        ApiData<FeedbackSurveyAnswer[], Meta>
      >(`${this.ENDPOINT_URL}/${surveyId}/answers`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  getMyAnswers(surveyId: number): Observable<FeedbackSurveyAnswer[]> {
    // bypasses the survey status check, used to edit answers
    return this.http
      .get<
        ApiData<FeedbackSurveyAnswer[], Meta>
      >(`${this.ENDPOINT_URL}/${surveyId}/answers/my`)
      .pipe(
        retry(1),
        map((res) => res.data),
      );
  }

  // ---------------
  // CAMPAIGN
  // ---------------
  getSurveyFromCampaign(campaignId: number): Observable<FeedbackSurvey> {
    const matrixOrgaURL = `${this.MANAGE_ENDPOINT_URL}?${qs.stringify({
      body: { campaignIds: [campaignId], ...this.filtersObject },
      meta: { limit: 1, skip: 0 },
    })}`;
    const legacyURL = `${this.MANAGE_ENDPOINT_URL}?${qs.stringify({
      body: { campaignIds: [campaignId] },
      meta: { limit: 1, skip: 0, filters: { depth: -1 } },
    })}`;

    const URL = this.matrixOrgaRollupFF ? matrixOrgaURL : legacyURL;

    return this.http
      .get<
        ApiData<
          FeedbackSurvey[],
          { orderBy: OrderBy[]; skip: number; limit: number; total: number }
        >
      >(URL)
      .pipe(
        retry(1),
        map(({ data }) => (data.length ? data[0] : null)),
      );
  }
  // ---------------
  // MANAGE
  // ---------------
  loadManageSurveysChunk(
    options: {
      statuses?: FeedbackSurveyStatus[];
      reviewee?: string;
      coach?: string;
      pollingDate?: { from?: string; to?: string };
      campaignIds?: number[];
    },
    metaOptions: {
      orderBy?: {
        property: string;
        direction: CompareOrderDirections;
      }[];
      skip?: number;
      limit?: number;
      onlyCount?: boolean;
    },
  ): Observable<{
    meta: { orderBy: OrderBy[]; skip: number; limit: number; total: number };
    surveys: FeedbackSurvey[];
  }> {
    if (this.matrixOrgaRollupFF) {
      const URL = `${this.MANAGE_ENDPOINT_URL}?${qs.stringify({
        body: { ...options, ...this.filtersObject },
        meta: {
          ...metaOptions,
        },
      })}`;

      return this.http
        .get<
          ApiData<
            FeedbackSurvey[],
            { orderBy: OrderBy[]; skip: number; limit: number; total: number }
          >
        >(URL)
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ meta, surveys: data })),
        );
    } else {
      const URL = `${this.MANAGE_ENDPOINT_URL}?${qs.stringify({
        body: options,
        meta: {
          ...metaOptions,
          filters: this.filtersLegacy.rawParams(),
        },
      })}`;

      return this.http
        .get<
          ApiData<
            FeedbackSurvey[],
            { orderBy: OrderBy[]; skip: number; limit: number; total: number }
          >
        >(URL)
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ meta, surveys: data })),
        );
    }
  }

  getCompareSurveys(
    endDate: { from?: string; to?: string },
    role: FeedbackCompareRoleFiltersEnum,
    limit?: number,
  ): Observable<
    ApiData<
      FeedbackSurvey[],
      { orderBy: OrderBy[]; skip: number; limit: number; total: number }
    >
  > {
    const URL = `${this.COMPARE_ENDPOINT_URL}?${qs.stringify({
      body: { roles: [role], endDate },
      meta: {
        filters: this.filtersLegacy.rawParams(),
        limit,
      },
    })}`;

    return this.http
      .get<
        ApiData<
          FeedbackSurvey[],
          { orderBy: OrderBy[]; skip: number; limit: number; total: number }
        >
      >(URL)
      .pipe(retry(1));
  }

  getCompareSurveysFromSelectedSegments(
    dates: { from?: string; to?: string },
    role: FeedbackCompareRoleFiltersEnum,
    segmentIds: number[],
    limit?: number,
  ): Observable<
    ApiData<
      FeedbackSurvey[],
      { orderBy: OrderBy[]; skip: number; limit: number; total: number }
    >
  > {
    const URL = `${this.COMPARE_ENDPOINT_URL}?${qs.stringify({
      body: { roles: [role], endDate: dates },
      meta: {
        filters: {
          ...this.filtersLegacy.rawParams(),
          segmentIds,
        },
        limit,
      },
    })}`;

    return this.http
      .get<
        ApiData<
          FeedbackSurvey[],
          { orderBy: OrderBy[]; skip: number; limit: number; total: number }
        >
      >(URL)
      .pipe(retry(1));
  }

  sendRevieweeReminder(surveyId: number): Observable<void> {
    return this.http.post<void>(
      `${this.ENDPOINT_URL}/${surveyId}/op/sendInvitationsReminders`,
      {},
    );
  }
}

// ---------------
// HELPERS
// ---------------
export const getDeadlineHelper = (survey: FeedbackSurvey): string | null => {
  switch (surveyStepEnum[survey.status]) {
    case surveyStepEnum.INVITATIONS:
    case surveyStepEnum.READY:
      return survey.startDate || null;
    case surveyStepEnum.POLLING:
      return survey.endDate || null;
    default:
      return null;
  }
};
