import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'display-markdown',
  templateUrl: './display-markdown.component.html',
  styleUrls: ['./display-markdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayMarkdownComponent implements OnChanges {
  @Input() className: string | null = null;
  @Input() text: string | null = null;

  constructor() {}

  ngOnChanges(): void {
    if (typeof this.text !== 'string') {
      throw new Error(
        '[DisplayMarkdown]: text is required and should be a string',
      );
    }
  }
}
