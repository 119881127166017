import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FeedbackSurvey, surveyStepEnum } from '../models/survey.model';
import * as SurveyActions from '../actions/feedback-survey.actions';

const getNextDeadline = (survey: FeedbackSurvey) => {
  switch (surveyStepEnum[survey.status]) {
    case surveyStepEnum.INVITATIONS:
    case surveyStepEnum.READY:
      return survey.startDate || null;
    case surveyStepEnum.POLLING:
      return survey.endDate || null;
    default:
      return null;
  }
};

export const actionableSurveysFeatureKey = 'actionableSurveys';

export interface State extends EntityState<FeedbackSurvey> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FeedbackSurvey> =
  createEntityAdapter<FeedbackSurvey>({
    sortComparer: (a, b) => {
      const deadlineA = getNextDeadline(a);
      const deadlineB = getNextDeadline(b);

      if (deadlineA === deadlineB) {
        return surveyStepEnum[a.status] < surveyStepEnum[b.status] ? 1 : -1;
      } else if (deadlineA === null) {
        return 1;
      } else if (deadlineB === null) {
        return -1;
      }
      return deadlineA < deadlineB ? -1 : 1;
    },
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    SurveyActions.createFeedbackSurvey,
    SurveyActions.updateFeedbackSurvey,
    SurveyActions.deleteFeedbackSurvey,
    SurveyActions.loadActionableFeedbackSurveys,
    SurveyActions.forceStartFeedbackSurvey,
    SurveyActions.forceStopFeedbackSurvey,
    SurveyActions.forceRemindersFeedbackSurvey,
    SurveyActions.updateCoachForFeedbackSurvey,
    SurveyActions.deleteCoachForFeedbackSurvey,
    SurveyActions.shareReportForFeedbackSurvey,
    (state) => ({ ...state, loading: true }),
  ),
  on(SurveyActions.createSelfFeedbackSurveySuccess, (state, action) =>
    adapter.addOne(action.survey, { ...state, loading: false }),
  ),
  on(SurveyActions.updateSelfFeedbackSurveySuccess, (state, action) =>
    adapter.updateOne(action.updatedSurvey, { ...state, loading: false }),
  ),
  on(SurveyActions.deleteFeedbackSurveySuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(
    SurveyActions.updateCoachForFeedbackSurveySuccess,
    SurveyActions.deleteCoachForFeedbackSurveySuccess,
    SurveyActions.getFeedbackSurveyUpdatedPropertiesSuccess,
    SurveyActions.shareReportForFeedbackSurveySuccess,
    (state, action) => adapter.updateOne(action, { ...state, loading: false }),
  ),
  on(SurveyActions.markReportReadForFeedbackSurvey, (state, action) =>
    adapter.updateOne(
      { id: action.surveyId, changes: { coachReportReadDate: new Date() } },
      { ...state },
    ),
  ),
  on(SurveyActions.loadActionableFeedbackSurveysSuccess, (state, action) =>
    adapter.setAll(action.surveys, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
  on(
    SurveyActions.feedbackSurveyFailure,
    SurveyActions.forceStartFeedbackSurveySuccess,
    SurveyActions.forceStopFeedbackSurveySuccess,
    SurveyActions.forceRemindersFeedbackSurveySuccess,
    SurveyActions.createOtherFeedbackSurveySuccess,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
);
