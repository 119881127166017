import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Solicitation } from '@models/solicitation.model';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { ApiData, Meta } from '@models/API.models';
import { environment } from '@env/environment';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root',
})
export class SolicitationsService {
  ENDPOINT_URL = `${environment.API_URL}/my/solicitations`;

  constructor(private http: HttpClient) {}

  loadAll(): Observable<Solicitation[]> {
    // TODO: solicitations should be stored in the store and called by chunks
    return this.http
      .get<ApiData<Solicitation[], Meta>>(
        `${this.ENDPOINT_URL}?${qs.stringify({
          meta: {
            limit: 100,
          },
        })}`,
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
}
