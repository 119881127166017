import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  FeedbackTemplatingQuestion,
  FeebackCoreQuestion,
} from '../models/question.model';
import { UpdateNum } from '@ngrx/entity/src/models';

export const loadQuestions = createAction(
  '[Feedback Questions] Load Questions',
);
export const loadQuestionsSuccess = createAction(
  '[Question/API] Load Questions Success',
  props<{ questions: FeedbackTemplatingQuestion[] }>(),
);

export const getQuestionsForSurvey = createAction(
  '[Feedback Questions] Get Questions For Survey',
  props<{ surveyId: number }>(),
);
export const getQuestionsForSurveySuccess = createAction(
  '[Question/API] Get Questions For Survey Success',
  props<{ questions: FeebackCoreQuestion[] }>(),
);

export const addQuestion = createAction(
  '[Feedback Questions] Add Question',
  props<{ question: Omit<FeedbackTemplatingQuestion, 'id'> }>(),
);
export const addQuestionSuccess = createAction(
  '[Question/API] Add Question Success',
  props<{ question: FeedbackTemplatingQuestion }>(),
);

export const upsertQuestion = createAction(
  '[Feedback Questions] Upsert Question',
  props<{ question: FeedbackTemplatingQuestion }>(),
);
export const upsertQuestionSuccess = createAction(
  '[Question/API] Upsert Question Success',
  props<{ question: FeedbackTemplatingQuestion }>(),
);

export const updateQuestion = createAction(
  '[Feedback Questions] Update Question',
  props<{ question: Update<FeedbackTemplatingQuestion> }>(),
);
export const updateQuestionSuccess = createAction(
  '[Question/API] Update Question Success',
  props<{ question: UpdateNum<FeedbackTemplatingQuestion> }>(),
);

export const deleteQuestion = createAction(
  '[Feedback Questions] Delete Question',
  props<{ id: number }>(),
);
export const deleteQuestionSuccess = createAction(
  '[Question/API] Delete Question Success',
  props<{ id: number }>(),
);
