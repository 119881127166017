import { bootstrapAppSuccess } from '@core/store/actions/auth.actions';
import {
  FiltersActions,
  FiltersSuccessActions,
} from '@core/store/actions/filters.actions';
import { FiltersScopeTypeEnum, FiltersDepthEnum } from '@models/filters.model';
import { TeamMember } from '@models/user.model';
import { createReducer, on } from '@ngrx/store';

export const filtersScopeFeatureKey = 'scope';

export interface FiltersScopeState {
  type: FiltersScopeTypeEnum | null;
  viewAsUser: TeamMember | null;
  depth: FiltersDepthEnum;
  defaultScope: FiltersScopeTypeEnum | null;
  defaultDepth: FiltersDepthEnum | null;
  defaultUser: TeamMember | null;
  userCanResetScope: boolean;
  userHasNoPopulation: boolean;
}

export const filtersScopeInitialState: FiltersScopeState = {
  type: null,
  viewAsUser: null,
  depth: FiltersDepthEnum.TEAMS,
  defaultScope: null,
  defaultDepth: null,
  defaultUser: null,
  userCanResetScope: true,
  userHasNoPopulation: false,
};

export const filtersScopeReducer = createReducer(
  filtersScopeInitialState,
  on(FiltersActions.setScopeToDefaultSuccess, (state, { scope }) => {
    return {
      ...state,
      type: scope?.type ?? state.defaultScope,
      depth: scope?.depth ?? state.defaultDepth,
      viewAsUser: scope?.viewAsUser ?? state.defaultUser,
    };
  }),
  on(FiltersActions.setScopeType, (state, { scopeType }) => ({
    ...state,
    type: scopeType,
    ...(scopeType === FiltersScopeTypeEnum.COMPANY
      ? { depth: FiltersDepthEnum.N1 }
      : {}),
  })),
  on(FiltersActions.setScopeDepth, (state, { depth }) => ({
    ...state,
    depth,
  })),
  on(FiltersActions.setScopeManager, (state, { user }) => ({
    ...state,
    viewAsUser: user,
  })),
  on(
    FiltersSuccessActions.setScopeFiltersFromUrlSuccess,
    (state, { scope }) => {
      if (scope.type === FiltersScopeTypeEnum.COMPANY) {
        return {
          ...state,
          type: FiltersScopeTypeEnum.COMPANY,
          viewAsUser: null,
          depth: FiltersDepthEnum.N1,
        };
      } else if (scope.type === FiltersScopeTypeEnum.HIERARCHY) {
        return {
          ...state,
          type: FiltersScopeTypeEnum.HIERARCHY,
          viewAsUser: scope.viewAsUser,
          depth: scope.depth,
        };
      } else if (scope.type === FiltersScopeTypeEnum.MATRIX) {
        return {
          ...state,
          type: FiltersScopeTypeEnum.MATRIX,
        };
      }
    },
  ),

  on(bootstrapAppSuccess, (state, { user }) => {
    // TODO matrixOrgaRollupFF => ne pas écouter le bootstrapAppSuccess et plutot dispatch une action d'initialisation des filtres ?

    let defaultScope = null;
    if (user.role.permissions.includes('scope:company')) {
      defaultScope = FiltersScopeTypeEnum.COMPANY;
    } else if (user.isManager) {
      defaultScope = FiltersScopeTypeEnum.HIERARCHY;
    } else if (user.managedSegments.length) {
      defaultScope = FiltersScopeTypeEnum.MATRIX;
    }

    const defaultDepth =
      defaultScope === FiltersScopeTypeEnum.COMPANY ||
      (defaultScope === FiltersScopeTypeEnum.HIERARCHY &&
        user.isManagerOfManagers)
        ? FiltersDepthEnum.TEAMS
        : FiltersDepthEnum.N1;

    let userCanResetScope = true;
    if (
      (defaultScope === FiltersScopeTypeEnum.HIERARCHY &&
        defaultDepth === FiltersDepthEnum.N1 &&
        !user.managedSegments.length) ||
      defaultScope === FiltersScopeTypeEnum.MATRIX ||
      defaultScope === null
    ) {
      userCanResetScope = false;
    }

    return {
      ...state,
      type: defaultScope,
      depth: defaultDepth,
      viewAsUser: user,
      defaultScope,
      defaultDepth,
      defaultUser: user,
      userCanResetScope,
      userHasNoPopulation: defaultScope === null,
    };
  }),
);
