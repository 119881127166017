import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BleexoLocale } from '@models/bleexo.models';
import { TranslationsFormGroup } from '@models/translations-form-group.model';
import {
  accordion,
  preventInitialChildAnimations,
} from '@shared/animations/animations';
import { NoWhitespaceValidator } from '@shared/validators/validators';

@Component({
  selector: 'translations-handler-typed-form',
  templateUrl: './translations-handler-typed-form.component.html',
  animations: [accordion, preventInitialChildAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationsHandlerTypedFormComponent implements OnInit {
  /**
   * * INPUT / OUTPUT *
   */
  @Input() placeholder = '';
  @Input() displayQuotesIcon = false;
  @Input() displayHint = true;
  @Input() minLength = 100;
  @Input() maxLength = 30;
  @Input() formGroup: TranslationsFormGroup = new FormGroup({
    translations: new FormArray<
      FormGroup<{
        locale: FormControl<BleexoLocale>;
        text: FormControl<string>;
      }>
    >([]),
    requiredLocale: new FormControl<BleexoLocale>('fr'),
    requiredLocaleSource: new FormControl<string>('COMPANY'),
    locales: new FormControl<BleexoLocale[]>([]),
  });

  existingTranslations: { locale: BleexoLocale; text: string }[] = [];

  constructor() {}

  /**
   * * NG CYCLES *
   */

  ngOnInit(): void {
    this.existingTranslations = this.translations.value;
    this.orderLocales();
    this.translations.markAsUntouched();
  }

  /**
   * * PROTECTED *
   */

  protected addLocale(locale: BleexoLocale): void {
    this.locales.value.push(locale);
  }

  protected addTranslation(locale: BleexoLocale): void {
    this.removeLocale(locale);
    const text =
      this.existingTranslations.find((t) => t.locale === locale)?.text || '';
    this.translations.push(
      new FormGroup({
        locale: new FormControl(locale),
        text: new FormControl(
          text,
          Validators.compose([
            Validators.maxLength(this.maxLength),
            NoWhitespaceValidator(),
          ]),
        ),
      }),
    );
  }

  protected orderLocales(): void {
    // Will put the required locale first in the loop
    const requiredIndex = this.translations.controls.findIndex(
      (c: FormControl) =>
        c.get('locale').value === this.formGroup.get('requiredLocale').value,
    );
    if (requiredIndex !== -1) {
      const requiredGroup = this.translations.at(requiredIndex);
      this.translations.removeAt(requiredIndex);
      this.translations.insert(0, requiredGroup);
    }

    this.translations.markAllAsTouched();
  }

  protected removeLocale(locale: BleexoLocale): void {
    const copyLocales = [...this.locales.value];
    const index = copyLocales.findIndex(
      (localeFromControl) => localeFromControl === locale,
    );
    copyLocales.splice(index, 1);
    this.formGroup.setControl('locales', new FormControl(copyLocales));
  }

  protected removeTranslation(index: number, locale: BleexoLocale): void {
    this.addLocale(locale);
    this.translations.removeAt(index);
  }

  protected get translations(): FormArray {
    return this.formGroup.get('translations') as FormArray;
  }

  protected get locales(): FormControl<BleexoLocale[]> {
    return this.formGroup.get('locales') as FormControl<BleexoLocale[]>;
  }
}
