import { CommentsOptions } from '@models/comments.model';
import { WordCloudParams } from '@models/wordcloud.model';
import { UpdateNum } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import {
  CommentByDriver,
  CommentsFormOptions,
  CommentsMenuSortingTypes,
  CommentsSorting,
} from '../models/comments-page.models';

export const getCount = createAction(
  '[Pulse Comments] Get Count',
  props<{
    driverIds: number[];
    scores: number[];
    options: CommentsFormOptions;
  }>(),
);

export const getCountSuccess = createAction(
  '[Pulse Comments] Get Count Success ',
  props<{
    commentsCountUpdates: UpdateNum<CommentByDriver>[];
    scores: number[];
  }>(),
);

export const loadInitialState = createAction(
  '[Pulse Comments] Load Initial State',
  props<{ commentsByDriver: CommentByDriver[] }>(),
);

export const removeAllEntities = createAction(
  '[Pulse Comments] Remove All Entities',
);

export const getWordCloud = createAction(
  '[Pulse Comments] Get WordCloud',
  props<{
    driverId: number;
    scores: number[];
    options: WordCloudParams;
  }>(),
);

export const getWordCloudSuccess = createAction(
  '[Pulse Comments] Get WordCloud Success',
  props<{
    wordCloudUpdate: UpdateNum<CommentByDriver>;
    scores: number[];
  }>(),
);

export const getComments = createAction(
  '[Pulse Comments] Get Comments',
  props<{
    driverId: number;
    scores: number[];
    options: CommentsOptions;
    limit: number;
  }>(),
);

export const getCommentsSuccess = createAction(
  '[Pulse Comments] Get Comments Success',
  props<{
    commentsUpdate: UpdateNum<CommentByDriver>;
    scores: number[];
    shouldLoadChunk: boolean;
  }>(),
);

export const loadCommentsChunk = createAction(
  '[Pulse Comments] Load Comments Chunk',
  props<{
    driverId: number;
    scores: number[];
    options: CommentsOptions;
    limit: number;
  }>(),
);

export const loadCommentsChunkSuccess = createAction(
  '[Pulse Comments] Load Comments Chunk Success',
  props<{
    scores: number[];
    commentsUpdate: UpdateNum<CommentByDriver>;
    shouldLoadChunk: boolean;
  }>(),
);

export const setLoadingChunkToFalse = createAction(
  '[Pulse Comments] Set Loading Chunk To False',
);

export const loadCommentsFailure = createAction(
  '[Pulse Comments/API] Load Comments Failure',
  props<{ error: Error }>(),
);

export const selectDriver = createAction(
  '[Pulse Comments] Select Driver Id',
  props<{
    driverId: number;
    scores: number[];
    options: {
      minDate?: string;
      maxDate?: string;
      search?: string;
      surveyIds?: number[];
    };
    limit: number;
  }>(),
);

export const sortMenu = createAction(
  '[Pulse Comments] Sort Menu',
  props<{ menuSorting: CommentsMenuSortingTypes }>(),
);

export const setMenuReloaded = createAction(
  '[Pulse Comments] set Menu Reloaded',
);

export const sortComments = createAction(
  '[Pulse Comments] Sort Comments',
  props<{ sortings: CommentsSorting[] }>(),
);
