import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MeetingTypeActions from '../actions/meeting-type.actions';
import { IType } from '../models/meeting-type.interface';

export const meetingTypesFeatureKey = 'types';

export interface MeetingTypeState extends EntityState<IType> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<IType> = createEntityAdapter<IType>();

export const meetingTypeInitialState: MeetingTypeState =
  adapter.getInitialState({
    // additional entity state properties
    isLoaded: false,
    loading: false,
  });

export const meetingTypeReducer = createReducer(
  meetingTypeInitialState,
  on(MeetingTypeActions.MeetingTypesActions.loadMeetingTypes, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    MeetingTypeActions.MeetingTypesApiActions.loadMeetingTypesSuccess,
    (state, { meetingTypes }) =>
      adapter.setAll(meetingTypes, {
        ...state,
        loading: false,
        isLoaded: true,
      }),
  ),
  on(MeetingTypeActions.MeetingTypesErrorAction.errorMeetingType, (state) => ({
    ...state,
    loading: false,
  })),
);
