import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Feedback360Template } from '../store/models/template.model';
import { ApiData, Meta } from '@models/API.models';
import { retry, map, takeUntil } from 'rxjs/operators';
import { selectCompanyLocales } from '@core/store/selectors/core.selectors';
import { BleexoLocale } from '@models/bleexo.models';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FeedbackTemplateService implements OnDestroy {
  ENDPOINT_URL = `${environment.API_URL}/feedback/templating/surveys`;
  private _companyLocales: BleexoLocale[];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.store
      .pipe(select(selectCompanyLocales), takeUntil(this.unsubscribe$))
      .subscribe((companyLocales) => (this._companyLocales = companyLocales));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadAll(): Observable<Feedback360Template[]> {
    return this.http
      .get<ApiData<Feedback360Template[], Meta>>(this.ENDPOINT_URL)
      .pipe(
        retry(1),
        map((apiRes) => {
          apiRes.data.forEach((t) => {
            Object.keys(t.title).forEach((locale: BleexoLocale) => {
              if (!this._companyLocales.includes(locale)) {
                delete t.title[locale];
              }
            });
            Object.keys(t.description).forEach((locale: BleexoLocale) => {
              if (!this._companyLocales.includes(locale)) {
                delete t.description[locale];
              }
            });
          });
          return apiRes.data;
        }),
      );
  }
  create(question: Omit<Feedback360Template, 'id'>): Observable<number> {
    return this.http
      .post<ApiData<{ id: number }, Meta>>(this.ENDPOINT_URL, question)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data.id),
      );
  }
  update(questionToUpdate: Feedback360Template): Observable<void> {
    const { id, ...question } = questionToUpdate;
    return this.http
      .patch<void>(`${this.ENDPOINT_URL}/${id}`, question)
      .pipe(retry(1));
  }
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.ENDPOINT_URL}/${id}`).pipe(retry(1));
  }
}
