import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  AdminMeetingTemplatePageActions,
  MeetingTemplatesAPIActions,
  MeetingTemplatesErrorActions,
} from '../actions/meeting-template.actions';
import { ITemplateResult } from '../models/meeting-template.model';

export const meetingTemplatesFeatureKey = 'templates';

export interface MeetingTemplateState extends EntityState<ITemplateResult> {
  isLoaded: boolean;
  loading: boolean;
  selectedTemplateId: string;
}

export const meetingTemplateAdapter: EntityAdapter<ITemplateResult> =
  createEntityAdapter<ITemplateResult>();

export const meetingTemplateInitialState: MeetingTemplateState =
  meetingTemplateAdapter.getInitialState({
    isLoaded: false,
    loading: false,
    selectedTemplateId: null,
  });

export const meetingTemplteReducer = createReducer(
  meetingTemplateInitialState,
  on(
    MeetingTemplatesAPIActions.loadMeetingTemplatesSuccess,
    (state, { templates }) =>
      meetingTemplateAdapter.setAll(templates, {
        ...state,
        loading: false,
        isLoaded: true,
        selectedTemplateId: null,
      }),
  ),
  on(
    AdminMeetingTemplatePageActions.addMeetingTemplate,
    AdminMeetingTemplatePageActions.patchMeetingTemplate,
    AdminMeetingTemplatePageActions.loadMeetingTemplate,
    AdminMeetingTemplatePageActions.deleteMeetingTemplate,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    AdminMeetingTemplatePageActions.selectMeetingTemplate,
    (state, { id }) => ({ ...state, selectedTemplateId: id }),
  ),
  on(
    AdminMeetingTemplatePageActions.addMeetingTemplate,
    (state, { template }) => ({
      ...state,
      selectedQuestionId: null,
    }),
  ),
  on(
    AdminMeetingTemplatePageActions.patchMeetingTemplate,
    (state, { templateId }) => ({
      ...state,
      selectedTemplateId: templateId,
    }),
  ),
  on(
    MeetingTemplatesAPIActions.addMeetingTemplateSuccess,
    (state, { template }) => {
      return meetingTemplateAdapter.addOne(template, {
        ...state,
        selectedTemplateId: template.id,
        loading: false,
      });
    },
  ),
  on(
    MeetingTemplatesAPIActions.patchMeetingTemplateSuccess,
    (state, { template }) =>
      meetingTemplateAdapter.updateOne(
        { id: template.id, changes: template },
        {
          ...state,
          selectedTemplateId: template.id,
          loading: false,
        },
      ),
  ),
  on(MeetingTemplatesAPIActions.deleteMeetingTemplateSuccess, (state, { id }) =>
    meetingTemplateAdapter.removeOne(id, {
      ...state,
      loading: false,
      selectedTemplateId: null,
    }),
  ),

  on(MeetingTemplatesErrorActions.errorQuestion, (state, _) => ({
    ...state,
    loading: false,
  })),

  // UI ACTIONS
  on(AdminMeetingTemplatePageActions.createMeetingTemplateUI, (state) => ({
    ...state,
    selectedTemplateId: null,
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  meetingTemplateAdapter.getSelectors();
