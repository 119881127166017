export interface SegmentTypeLegacyStore {
  id: number;
  name: string;
  segments: Segment[];
}

export interface SegmentTypeLegacyNGRX {
  id: number;
  name: string;
  segmentIds: number[];
}
export interface Segment {
  id: number;
  name: string;
  segmentTypeId: number;
  order?: number;
  usersCount?: number;
}

export interface UserSegment {
  id: string;
  segmentId: number;
  userId: number;
  companyId: number;
  createdAt: Date | null;
  updateAt: Date | null;
  archivedAt: Date | null;
}

export interface ManagedSegments extends UserSegment {}

export interface SegmentTypeWithSegments {
  id: number;
  name: string;
  segmentIds: number[];
  segments: Segment[];
}

// export interface Segment {
//   id: number
//   segmentTypeId: number
//   name: string
//   order: number
// }

export interface SegmentType {
  id: number;
  name: string;
  isBenchmarkable: boolean;
  isMultiSegment: boolean;
  isDepartment: boolean;
  isInMyPulseView: boolean;
  segmentsOrderType?: SegmentTypeSegmentsOrderTypeEnum;
  segmentsCount: number;
}

export interface EditSegmentType extends Omit<SegmentType, 'id'> {}

export enum SegmentTypeSegmentsOrderTypeEnum {
  MANUAL = 'MANUAL',
  ALPHA_ASC = 'ALPHA_ASC',
  ALPHA_DESC = 'ALPHA_DESC',
}

export enum SegmentDirectionSortEnum {
  ASC = 'asc',
  DESC = 'desc',
  NONE = '',
}

export enum SegmentActiveColumnSortEnum {
  NAME = 'name',
  NONE = '',
}

export enum SegmentTypeSegmentsOrderTypeOriginEnum {
  MODAL = 'MODAL',
}

export const SegmentsTableColumnsWhenSegmentTypeIsDepartment: string[] = [
  'position',
  'name',
  'users',
  'managers',
  'menu',
];

export const SegmentsTableColumnsWhenSegmentTypeIsNotDepartment: string[] = [
  'position',
  'name',
  'users',
  'menu',
];
export interface SegmentUsersCountResData {
  id: number;
  usersCount: number;
}
