import { Meta } from '@models/API.models';
import {
  FiltersDepthEnum,
  FiltersScope,
  FiltersScopeLayout,
  FiltersScopeTypeEnum,
  SegmentationContent,
} from '@models/filters.model';
import { TeamMember, User } from '@models/user.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FiltersActions = createActionGroup({
  source: 'Filters',
  events: {
    'Initialize Filters': emptyProps(),
    'Set Scope To Default': emptyProps(),
    'Set Scope To Default Success': props<{ scope: FiltersScopeLayout }>(),
    'Set Scope Type': props<{ scopeType: FiltersScopeTypeEnum }>(),
    'Set Scope Depth': props<{ depth: FiltersDepthEnum }>(),
    'Set Scope Manager': props<{ user: User }>(),
    'Get Scope Manager': emptyProps(),
    'Set Selected Segments For Type': props<{
      segmentTypeId: number;
      segmentIds: number[];
    }>(),
    'Reset Segments Filter': emptyProps(),
    'Get Population Users List': props<{
      skip: number;
    }>(),
    'Get Segments Users Count': emptyProps(),
    'Set Segments Filters From URL': props<{
      segmentation: SegmentationContent;
    }>(),
    'Set Scope Filters From URL': props<{
      scope: FiltersScope;
    }>(),
    'Reset Filters to Available Scope': emptyProps(),
  },
});

export const FiltersSuccessActions = createActionGroup({
  source: 'Filters Success',
  events: {
    'Get Filters Population Count Success': props<{ count: number }>(),
    'Get Segments Users Count Success': props<{
      segmentsUsersCount: { id: number; usersCount: number }[];
    }>(),
    'Set Scope Filters From URL Success': props<{
      scope: FiltersScope;
    }>(),
    'Get Population Users List Success': props<{
      usersList: TeamMember[];
      meta: Meta;
    }>(),
  },
});
