import { Action, combineReducers } from '@ngrx/store';
import {
  filtersPopulationInitialState,
  filtersPopulationReducer,
  FiltersPopulationState,
} from './filters-population.reducer';
import {
  filtersScopeFeatureKey,
  filtersScopeInitialState,
  filtersScopeReducer,
  FiltersScopeState,
} from './filters-scope.reducer';
import {
  filtersSegmentationFeatureKey,
  FiltersSegmentationState,
  filtersSegmentationInitialState,
  filtersSegmentationReducer,
  filtersPopulationKey,
} from './filters-segmentation.reducer';

export const filtersFeatureKey = 'filters';

export interface FiltersState {
  [filtersScopeFeatureKey]: FiltersScopeState;
  [filtersSegmentationFeatureKey]: FiltersSegmentationState;
  [filtersPopulationKey]: FiltersPopulationState;
}

export const filtersInitialState: FiltersState = {
  [filtersScopeFeatureKey]: filtersScopeInitialState,
  [filtersSegmentationFeatureKey]: filtersSegmentationInitialState,
  [filtersPopulationKey]: filtersPopulationInitialState,
};

export function reducers(state: FiltersState | undefined, action: Action) {
  return combineReducers({
    [filtersScopeFeatureKey]: filtersScopeReducer,
    [filtersSegmentationFeatureKey]: filtersSegmentationReducer,
    [filtersPopulationKey]: filtersPopulationReducer,
  })(state, action);
}
