import { IEnvironment } from '@env/environment.interface';
import packageJson from '../../package.json';

export const environment: IEnvironment = {
  name: 'staging',
  production: true,
  API_URL: 'https://api-staging.talent.silae.fr',
  MY_SILAE_LABEL: 'My Silae (staging)',
  MY_SILAE_URL: 'https://staging.t.my.silae.fr',
  POST_HOG_API_KEY: 'phc_nseD9iPWJvxrdjSamLUxSHW0bhsfZzUOXyM0xwq5WrW',
  POST_HOG_HOST: 'https://sniffy.my.silae.fr',
  VERSION: packageJson.version,
  WHITELISTED_DOMAIN: 'api-staging.talent.silae.fr',
};
