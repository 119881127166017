import { Injectable } from '@angular/core';
import { Company } from '@models/company.model';
import { PulseTopicLocalized } from '@models/pulse.model';
import { SegmentTypeLegacyStore } from '@models/segments.model';
import { UserFull } from '@models/user.model';
import { HelpersService } from '@shared/services/helpers.service';
import { Observable } from 'rxjs';
import { BaseStore } from './baseStore';

export interface LegacyState {
  user: UserFull;
  company: Company;
  segments: SegmentTypeLegacyStore[];
  pulseDrivers: any;
  pulseTopics: any;
}

const initialState: Partial<LegacyState> = {
  user: null,
  company: null,
  pulseDrivers: [],
  pulseTopics: [],
};

@Injectable({
  providedIn: 'root',
})
export class LegacyStore extends BaseStore<Partial<LegacyState>> {
  constructor(private helpers: HelpersService) {
    super(initialState);
  }

  // User
  setCurrentUser(user: UserFull) {
    this.dispatch((state) => ({
      ...state,
      user,
    }));
  }
  updateCurrentUser(properties: Partial<UserFull>) {
    this.dispatch((state) => ({
      ...state,
      user: { ...state.user, ...properties },
    }));
  }
  get currentUser$(): Observable<UserFull> {
    return this.select((state) => state.user);
  }
  get currentUser(): UserFull {
    return this.selectSync((state) => state.user);
  }

  // Company
  setCompany(company: Company) {
    this.dispatch((state) => ({
      ...state,
      company,
    }));
  }
  get company$(): Observable<Company> {
    return this.select((state) => state.company);
  }
  get company(): Company {
    return this.selectSync((state) => state.company);
  }

  // Pulse Drivers
  setPulseDrivers(pulseDrivers) {
    this.dispatch((state) => ({
      ...state,
      pulseDrivers,
    }));
  }
  get pulseDrivers(): any {
    return this.selectSync((state) =>
      state.pulseDrivers.filter((driver) => {
        if (driver.hasOwnProperty('isDeleted')) {
          return !driver.isDeleted;
        } else if (driver.hasOwnProperty('archivedAt')) {
          return driver.archivedAt === null;
        }
      }),
    );
  }
  // Pulse Topics
  setPulseTopics(pulseTopics: PulseTopicLocalized[]) {
    this.dispatch((state) => ({
      ...state,
      pulseTopics,
    }));
  }
  get pulseTopics(): PulseTopicLocalized[] {
    return this.selectSync((state) =>
      state.pulseTopics.filter(
        (topic) =>
          !!topic.drivers.filter((driver) => {
            if (driver.hasOwnProperty('isDeleted')) {
              return !driver.isDeleted;
            } else if (driver.hasOwnProperty('archivedAt')) {
              return driver.archivedAt === null;
            }
          }).length,
      ),
    );
  }
  get pulseTopicIds() {
    return this.pulseTopics.map((topic) => topic.id);
  }

  // Segments
  setSegments(segmentTypes: SegmentTypeLegacyStore[]) {
    segmentTypes.forEach((type) => {
      const segmentColors = this.helpers.generateHexaArray(
        type.segments,
        type.segments.length,
      );
      type.segments = type.segments.map((segment, i) => ({
        ...segment,
        color: segmentColors[i],
      }));
    });
    this.dispatch((state) => ({
      ...state,
      segments: segmentTypes,
    }));
  }
  get segments$(): Observable<SegmentTypeLegacyStore[]> {
    return this.select((state) => state.segments);
  }
  get segments(): SegmentTypeLegacyStore[] {
    return this.selectSync((state) => state.segments);
  }

  logoutFromStore() {
    this.dispatch((state) => initialState);
  }
}
