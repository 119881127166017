import { Injectable } from '@angular/core';
import { Company } from '@models/company.model';
import { CompanyLogo } from '@models/companyLogo.model';
import { Segment, SegmentType } from '@models/segments.model';
import { User } from '@models/user.model';
import { CompanyService } from '@shared/services/company.service';
import { SegmentsService } from '@shared/services/segments.service';
import { UserFindOptions, UsersService } from '@shared/services/users.service';
import { Observable, forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BootstrapService {
  constructor(
    private segmentsService: SegmentsService,
    private usersService: UsersService,
    private companyService: CompanyService,
  ) {}

  getAdditionalData(
    company: Company,
    userId: number,
  ): Observable<[User, Segment[], SegmentType[], CompanyLogo]> {
    const userFindOptions: UserFindOptions = {
      retrieveRoleFromRoleId: true,
      retrieveSegmentsFromSegmentIds: true,
      retrieveManagerFromManagerId: true,
      retrieveManagedSemegentsFromManagedSegmentIds: true,
      applyUserFindOptionsToManager: false,
    };
    return forkJoin([
      this.usersService.get(userId, userFindOptions),
      this.segmentsService.getSegments(),
      this.segmentsService.getSegmentTypes(),
      company.logoEnabled
        ? this.companyService.getCompanyLogo(company.id)
        : of(null),
    ]);
  }
}
