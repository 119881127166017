import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  FeedbackSurveyParticipant,
  FeedbackParticipantRole,
} from '../store/models/participant.model';
import { ApiData, Meta } from '@models/API.models';
import { retry, map } from 'rxjs/operators';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackParticipantsService {
  ENDPOINT_URL = `${environment.API_URL}/feedback/core/surveys`;

  constructor(private http: HttpClient) {}

  get(surveyId: number): Observable<FeedbackSurveyParticipant[]> {
    return this.http
      .get<
        ApiData<FeedbackSurveyParticipant[], Meta>
      >(`${this.ENDPOINT_URL}/${surveyId}/participants`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  getCount(surveyId: number, onlyAnsweredCount = false): Observable<number> {
    const body: any = {};
    if (onlyAnsweredCount) {
      body.hasAnswered = true;
    }
    return this.http
      .get<ApiData<number, Meta>>(
        `${this.ENDPOINT_URL}/${surveyId}/participants/count?${qs.stringify({
          body,
        })}`,
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.meta.total),
      );
  }

  update(
    surveyId: number,
    participants: Array<{ userId: number; role: FeedbackParticipantRole }>,
  ): Observable<void> {
    return this.http
      .put<void>(`${this.ENDPOINT_URL}/${surveyId}/participants`, participants)
      .pipe(retry(1));
  }
}
