import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ParticipantsActions from '../actions/feedback-participant.actions';
import { FeedbackSurveyParticipant } from '../models/participant.model';

export const participantsFeatureKey = 'participants';

export interface SurveyParticipantsState {
  surveyId: number;
  participants: FeedbackSurveyParticipant[];
  count: number;
  answered: number;
}

export interface State extends EntityState<SurveyParticipantsState> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<SurveyParticipantsState> =
  createEntityAdapter<SurveyParticipantsState>({
    selectId: (state: SurveyParticipantsState) => state.surveyId,
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    ParticipantsActions.addFeedbackParticipants,
    ParticipantsActions.getFeedbackParticipants,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    ParticipantsActions.addFeedbackParticipantsSuccess,
    (state, { surveyId, participants }) =>
      adapter.upsertOne(
        {
          surveyId,
          participants,
          answered: null,
          // adding one for now because the reviewee is not counted in the API response
          count: participants.length + 1,
        },
        { ...state, loading: false },
      ),
  ),
  on(
    ParticipantsActions.getFeedbackParticipantsSuccess,
    (state, { participants, surveyId }) =>
      adapter.upsertOne(
        { surveyId, participants, answered: null, count: participants.length },
        { ...state, loading: false },
      ),
  ),
);
