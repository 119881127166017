import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GDPRGiveConsent,
  GDPRGiveConsentSuccess,
} from '@core/store/actions/user.actions';
import { i18nObject } from '@models/bleexo.models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './gdpr-consent-modal.component.html',
})
export class GdprConsentModalComponent {
  isLoading = false;

  constructor(
    private store: Store,
    private actions$: Actions,
    public dialogRef: MatDialogRef<GdprConsentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { userId: number; gdprText: i18nObject },
  ) {}

  giveConsent() {
    this.isLoading = true;
    this.store.dispatch(GDPRGiveConsent({ userId: this.data.userId }));

    this.actions$
      .pipe(ofType(GDPRGiveConsentSuccess), take(1))
      .subscribe((_success) => {
        this.isLoading = false;
        this.dialogRef.close();
      });
  }
}
