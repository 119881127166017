import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PermissionsService } from '@core/services/permissions.service';
import { environment } from '@env/environment';
import { ApiData } from '@models/API.models';
import { Store } from '@ngrx/store';
import * as qs from 'qs';
import { Observable, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { FiltersBaseService } from './filters-base.service';
import { FiltersLegacyService } from './filters.service';
import { SegmentsService } from './segments.service';
import { Filters, FiltersScopeTypeEnum } from './../../models/filters.model';

@Injectable({
  providedIn: 'root',
})
export class PopulationService extends FiltersBaseService {
  constructor(
    private http: HttpClient,
    private filtersLegacy: FiltersLegacyService,
    private segmentsService: SegmentsService,
    private permissions: PermissionsService,
    store: Store,
  ) {
    super(store);
  }

  forSegmentInCompare(
    segmentId: number,
  ): Observable<{ id: number; population: number }> {
    if (this.matrixOrgaRollupFF) {
      const segmentsToRequest = [];

      segmentsToRequest.push(segmentId);

      // detect if the segment is already present in the filters
      segmentsToRequest.filter((id) =>
        this.filtersObject?.filters?.segmentation?.map((segmentation) =>
          segmentation.segmentIds.includes(id),
        ),
      );

      return this.http
        .get<ApiData<number, Meta>>(
          `${environment.API_URL}/admin/populations?${qs.stringify({
            body: { ...this.filtersObject, segmentIds: segmentsToRequest },
          })}`,
        )
        .pipe(
          retry(1),
          map((apiRes) => ({ id: segmentId, population: apiRes.data })),
        );
    } else {
      // TODO matrixOrgaRollupFF remove else part
      const segmentType = this.segmentsService.getTypeIdOfSegment(segmentId);
      let segmentsToRequest = [
        ...(this.filtersLegacy.filtersParams['segmentIds[]'] || []),
      ];
      // detect if the requested segment's type is in the filters
      if (this.filtersLegacy.segments.has(segmentType)) {
        // bail if the segment is not present in the filters
        if (
          !this.filtersLegacy.segments
            .get(segmentType)
            .map((s) => s.id)
            .includes(segmentId)
        ) {
          return of({ id: segmentId, population: 0 });
        }
        // else remove all segments from that type
        if (segmentsToRequest.length) {
          segmentsToRequest = segmentsToRequest.filter((segId) => {
            return (
              this.segmentsService.getTypeIdOfSegment(Number(segId)) !==
              segmentType
            );
          });
        }
      }
      // then add back the segment to be requested
      segmentsToRequest.push(segmentId.toString());
      const meta = {
        filters: {
          ...this.filtersLegacy.rawParams(),
          segmentIds: segmentsToRequest,
        },
      };
      return this.http
        .get<
          ApiData<number, Meta>
        >(`${environment.API_URL}/admin/populations?${qs.stringify({ meta }, { arrayFormat: 'brackets' })}`)
        .pipe(
          retry(1),
          map((apiRes) => ({ id: segmentId, population: apiRes.data })),
        );
    }
  }

  forCompany(): Observable<number | null> {
    if (
      this.permissions.validateOneOfPermissions(['pulse:benchmark:company'])
    ) {
      if (this.matrixOrgaRollupFF) {
        return this.http
          .get<ApiData<number, Meta>>(
            `${environment.API_URL}/admin/populations/company?${qs.stringify({
              body: {
                filters: {
                  scope: { type: FiltersScopeTypeEnum.COMPANY },
                  segmentation: [],
                },
              },
            })}`,
          )
          .pipe(
            retry(1),
            map((apiRes) => apiRes.data),
          );
      } else {
        // TODO matrixOrgaRollupFF remove else part
        return this.http
          .get<
            ApiData<number, Meta>
          >(`${environment.API_URL}/admin/populations/company`)
          .pipe(
            retry(1),
            map((apiRes) => apiRes.data),
          );
      }
    } else {
      return of(null);
    }
  }

  forFilters(customFilters?: { filters: Filters }): Observable<number> {
    if (this.filtersObject.filters || customFilters) {
      return this.http
        .get<ApiData<number, Meta>>(
          `${environment.API_URL}/admin/populations?${qs.stringify({
            body: customFilters ?? this.filtersObject,
          })}`,
        )
        .pipe(
          retry(1),
          map((apiRes) => apiRes.data),
        );
    } else {
      // user has access to no scope (no filters) so population is 0
      return of(0);
    }
  }
}
