import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SmoothScrollDirective,
  SmoothScrollToDirective,
} from './smooth-scroll.directive';

@NgModule({
  declarations: [SmoothScrollToDirective, SmoothScrollDirective],
  imports: [CommonModule],
  exports: [SmoothScrollToDirective, SmoothScrollDirective],
})
export class SmoothScrollModule {}
