import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { selectUserId } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import {
  FiltersDepthEnum,
  FiltersScopeLayout,
  FiltersScopeTypeEnum,
} from '@models/filters.model';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

interface IReqUserLayout {
  id?: string;
  scopeType?: FiltersScopeTypeEnum;
  scopeViewAsUserId?: number;
  scopeDepth?: FiltersDepthEnum;
}
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  headerLabel$$ = new BehaviorSubject<string | undefined>(undefined);
  isMobile$ = this._breakpointObs
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    .pipe(
      map((result) => {
        return !!result.matches;
      }),
    );

  constructor(
    public store: Store,
    private _http: HttpClient,
    private _breakpointObs: BreakpointObserver,
  ) {
    this._breakpointObs.observe(Breakpoints.Small);
  }

  saveScope$(scopeToSave?: FiltersScopeLayout): Observable<FiltersScopeLayout> {
    return this.store.select(selectUserId).pipe(
      take(1),
      switchMap((userId) => {
        const scopeToSaveFormatted: IReqUserLayout = {
          scopeType: scopeToSave.type,
          scopeViewAsUserId: scopeToSave.viewAsUserId,
          scopeDepth: scopeToSave.depth,
        };
        return this._http
          .patch<IReqUserLayout>(
            `${environment.API_URL}/core/users/${userId}/layout`,
            { ...scopeToSaveFormatted },
          )
          .pipe(take(1));
      }),
    );
  }

  getScope$(): Observable<FiltersScopeLayout> {
    return this.store.select(selectUserId).pipe(
      take(1),
      switchMap((userId) => {
        return this._http
          .get<IReqUserLayout | null>(
            `${environment.API_URL}/core/users/${userId}/layout`,
          )
          .pipe(
            take(1),
            map((userLayout) => {
              const scopeFormatted: FiltersScopeLayout = {
                id: userLayout?.id,
                type: userLayout?.scopeType,
                depth: userLayout?.scopeDepth,
                viewAsUserId: userLayout?.scopeViewAsUserId,
              };
              return scopeFormatted;
            }),
          );
      }),
    );
  }
}
