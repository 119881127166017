import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { FeedbackCategoryService } from '../../services/feedback-category.service';
import * as fromCategory from '../actions/category.actions';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private category: FeedbackCategoryService,
  ) {}

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCategory.loadCategories),
      exhaustMap((_) =>
        this.category
          .loadAll()
          .pipe(
            map((categories) =>
              fromCategory.loadCategoriesSuccess({ categories }),
            ),
          ),
      ),
    ),
  );
  addCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCategory.addCategory),
      exhaustMap(({ category }) =>
        this.category.create(category).pipe(
          map(({ id, defaultLocale }) =>
            fromCategory.addCategorySuccess({
              category: { id, defaultLocale, ...category },
            }),
          ),
        ),
      ),
    ),
  );
  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCategory.updateCategory),
      exhaustMap(({ category }) =>
        this.category.update(category).pipe(
          map(({ id, defaultLocale }) => {
            return fromCategory.updateCategorySuccess({
              category: {
                id,
                changes: { ...category, defaultLocale },
              },
            });
          }),
        ),
      ),
    ),
  );
  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCategory.deleteCategory),
      exhaustMap(({ id }) =>
        this.category
          .delete(id)
          .pipe(map((_) => fromCategory.deleteCategorySuccess({ id }))),
      ),
    ),
  );
}
