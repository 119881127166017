import { createAction, props } from '@ngrx/store';
import {
  FeedbackSurveyParticipant,
  FeedbackParticipantRole,
} from '../models/participant.model';

export const addFeedbackParticipants = createAction(
  '[FeedbackSurvey] Add Feedback Participants',
  props<{
    surveyId: number;
    participants: FeedbackSurveyParticipant[];
    participantsPayload: { userId: number; role: FeedbackParticipantRole }[];
  }>(),
);
export const addFeedbackParticipantsSuccess = createAction(
  '[Participants/API] Add Feedback Participants Success',
  props<{ surveyId: number; participants: FeedbackSurveyParticipant[] }>(),
);

export const getFeedbackParticipants = createAction(
  '[FeedbackSurvey] Get Feedback Participants',
  props<{ surveyId: number }>(),
);
export const getFeedbackParticipantsSuccess = createAction(
  '[Participants/API] Get Feedback Participants Success',
  props<{ participants: FeedbackSurveyParticipant[]; surveyId: number }>(),
);

// Errors
export const feedbackParticipantsFailure = createAction(
  '[FeedbackParticipants/API] FeedbackSurvey Participants Failure',
  props<{ error: Error }>(),
);
