import { createSelector } from '@ngrx/store';
import { feedbackCampaignsFeatureKey } from '../reducers/feedback-campaigns.reducer';
import { selectFeedbackState } from './feedback.selectors';
import { adapter as campaignAdapter } from '../reducers/feedback-campaigns.reducer';

export const getFeedbackCampaignState = createSelector(
  selectFeedbackState,
  (feedback) => feedback[feedbackCampaignsFeatureKey],
);

export const {
  selectIds: selectCampaignsIds,
  selectEntities: selectCampaignsEntities,
  selectAll: selectAllCampaigns,
  selectTotal: selectCampaignsTotal,
} = campaignAdapter.getSelectors(getFeedbackCampaignState);

export const selectCampaignById = (id) =>
  createSelector(selectCampaignsEntities, (entities) => entities[id]);

export const selectFeedbackCampaigsLoadedState = createSelector(
  getFeedbackCampaignState,
  (state) => state.isLoaded,
);

export const selectFeedbackCampaigsLoadingState = createSelector(
  getFeedbackCampaignState,
  (state) => state.loading,
);
