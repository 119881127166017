import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromAuth from '../../actions/auth.actions';
import { Segment } from '@models/segments.model';

export const segmentsFeatureKey = 'segments';

export interface SegmentState extends EntityState<Segment> {
  // additional entities state properties
}

export const segmentsAdapter: EntityAdapter<Segment> =
  createEntityAdapter<Segment>();

export const segmentInitialState: SegmentState =
  segmentsAdapter.getInitialState({
    // additional entity state properties
  });

export const segmentsReducer = createReducer(
  segmentInitialState,
  on(fromAuth.bootstrapAppLegacySuccess, (state, action) => {
    const segments = [];
    action.segments.map((segmentType) =>
      segments.push(
        ...segmentType.segments.map((segment) => ({
          ...segment,
          segmentTypeId: segmentType.id,
        })),
      ),
    );

    return segmentsAdapter.setAll(segments, state);
  }),
);
