import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Feedback360Template } from '../models/template.model';
import { UpdateNum } from '@ngrx/entity/src/models';

export const loadTemplates = createAction(
  '[Feedback Templates] Load Templates',
);
export const loadTemplatesSuccess = createAction(
  '[Template/API] Load Templates Success',
  props<{ templates: Feedback360Template[] }>(),
);

export const addTemplate = createAction(
  '[Feedback Templates] Add Template',
  props<{ template: Feedback360Template }>(),
);
export const addTemplateSuccess = createAction(
  '[Template/API] Add Template Success',
  props<{ template: Feedback360Template }>(),
);

export const upsertTemplate = createAction(
  '[Template/API] Upsert Template',
  props<{ template: Feedback360Template }>(),
);
export const upsertTemplateSuccess = createAction(
  '[Template/API] Upsert Template Success',
  props<{ template: Feedback360Template }>(),
);

export const updateTemplate = createAction(
  '[Template/API] Update Template',
  props<{ template: Update<Feedback360Template> }>(),
);
export const updateTemplateSuccess = createAction(
  '[Template/API] Update Template Success',
  props<{ template: UpdateNum<Feedback360Template> }>(),
);

export const deleteTemplate = createAction(
  '[Template/API] Delete Template',
  props<{ id: number }>(),
);
export const deleteTemplateSuccess = createAction(
  '[Template/API] Delete Template Success',
  props<{ id: number }>(),
);

export const clearTemplates = createAction('[Template/API] Clear Templates');
