import { AdminMeetingDeleteQuestionModalComponent } from '@admin/components/admin-meeting-modals/admin-meeting-delete-question-modal/admin-meeting-delete-question-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_MODAL_WIDTH } from '@core/constants';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MeetingQuestionService } from '../../services/meeting-question.service';
import {
  AdminMeetingQuestionBankPageActions,
  AdminMeetingQuestionsModalActions,
  MeetingQuestionBankAPIActions,
  MeetingQuestionsErrorActions,
} from '../actions/meeting-question.actions';
import { EDataMutation, PostHogService } from '@shared/services/posthog.service';

@Injectable()
export class MeetingQuestionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly meetingQuestionsService: MeetingQuestionService,
    private readonly store: Store,
    private readonly postHogService: PostHogService,
    public dialog: MatDialog,
  ) {}

  loadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingQuestionBankPageActions.loadMeetingQuestions),
      exhaustMap((_) =>
        this.meetingQuestionsService.getAll().pipe(
          map((questions) =>
            MeetingQuestionBankAPIActions.loadMeetingQuestionsSuccess({
              questions,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              MeetingQuestionsErrorActions.errorQuestion({
                error,
              }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  addQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingQuestionBankPageActions.addMeetingQuestion),
      exhaustMap(({ question: createQuestion }) => {
        return this.meetingQuestionsService
          .create({ ...createQuestion, options: createQuestion.options ?? [] })
          .pipe(
            map((question) => {
              this.store.dispatch(
                showSuccess({
                  successMessage: 'MEETING.ADD_QUESTION_SUCCESS',
                }),
              );

              this.postHogService.emitQuestionEvent$$.next({
                mutation: EDataMutation.CREATED,
                question,
              });

              return MeetingQuestionBankAPIActions.addMeetingQuestionSuccess({
                question,
              });
            }),
            catchError((error: Error) => {
              this.store.dispatch(
                MeetingQuestionsErrorActions.errorQuestion({
                  error,
                }),
              );
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          );
      }),
    ),
  );

  patchQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingQuestionBankPageActions.patchMeetingQuestion),
      exhaustMap(({ question: patchQuestion, questionId }) => {
        return this.meetingQuestionsService
          .patch(questionId, patchQuestion)
          .pipe(
            map((question) => {
              this.store.dispatch(
                showSuccess({
                  successMessage: 'MEETING.UPDATE_QUESTION_SUCCESS',
                }),
              );
              return MeetingQuestionBankAPIActions.patchMeetingQuestionSuccess({
                question,
              });
            }),

            catchError((error: Error) => {
              this.store.dispatch(
                MeetingQuestionsErrorActions.errorQuestion({
                  error,
                }),
              );
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          );
      }),
    ),
  );

  deleteQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminMeetingQuestionBankPageActions.deleteMeetingQuestion),
      exhaustMap(({ id }) =>
        this.meetingQuestionsService.delete(id).pipe(
          map((question) => {
            this.store.dispatch(
              showSuccess({
                successMessage: 'MEETING.DELETE_QUESTION_SUCCESS',
              }),
            );

            this.postHogService.emitQuestionEvent$$.next({
              mutation: EDataMutation.DELETED,
              question,
            });

            return MeetingQuestionBankAPIActions.deleteMeetingQuestionSuccess({
              id,
            });
          }),
          tap((_) => this.dialog.closeAll()),
          catchError((error: Error) => {
            this.store.dispatch(
              MeetingQuestionsErrorActions.errorQuestion({
                error,
              }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  showDeleteQuestionModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminMeetingQuestionsModalActions.showDeleteMeetingQuestionModal,
        ),
        map((action) =>
          this.dialog.open(AdminMeetingDeleteQuestionModalComponent, {
            width: DEFAULT_MODAL_WIDTH,
            data: {
              id: action.id,
            },
          }),
        ),
      ),
    { dispatch: false },
  );
}
