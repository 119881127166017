import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MeetingActions from '../actions/meeting.actions';
import { ECampaignStatus, ICampaign } from '../models/meeting.model';

const getNextDeadline = (campaign: ICampaign) => {
  switch (ECampaignStatus[campaign.status]) {
    case ECampaignStatus.CREATED:
    case ECampaignStatus.STARTED:
      return campaign.startDate || null;
    case ECampaignStatus.FINISHED:
      return campaign.endDate || null;
    default:
      return null;
  }
};

export const startedCampaignsFeatureKey = 'startedCampaign';

export interface State extends EntityState<ICampaign> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<ICampaign> = createEntityAdapter<ICampaign>(
  {
    sortComparer: (a, b) => {
      const deadlineA = getNextDeadline(a);
      const deadlineB = getNextDeadline(b);

      if (deadlineA === deadlineB) {
        return ECampaignStatus[a.status] < ECampaignStatus[b.status] ? 1 : -1;
      } else if (deadlineA === null) {
        return 1;
      } else if (deadlineB === null) {
        return -1;
      }
      return deadlineA < deadlineB ? -1 : 1;
    },
  },
);

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(MeetingActions.createCampaign, (state) => ({ ...state, loading: true })),
  on(MeetingActions.createCampaignSuccess, (state) => ({
    ...state,
    loading: false,
    isLoaded: true,
  })),
);
