import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { HorizontalLoaderModule } from '@shared/components/horizontal-loader/horizontal-loader.module';
import { SvgIconModule } from '@shared/components/svg-icon/svg-icon.module';
import { PermissionsDirectivesModule } from '@shared/directives/permissions/permissionsDirectives.module';
import { SmoothScrollModule } from '@shared/directives/smooth-scroll/smooth-scroll.module';
import { ErrorToastComponent } from './components/toasts/error-toast.component';
import { InfoToastComponent } from './components/toasts/info-toast.component';
import { SuccessToastComponent } from './components/toasts/success-toast.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

const COMPONENTS = [
  UnsubscribeComponent,
  SuccessToastComponent,
  ErrorToastComponent,
  InfoToastComponent,
];

const BLEEXO = [SmoothScrollModule, SvgIconModule, PermissionsDirectivesModule];

const MATERIAL = [
  MatIconModule,
  MatButtonModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatDialogModule,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    TranslocoModule,
    HorizontalLoaderModule,
    MATERIAL,
    BLEEXO,
  ],
})
export class CoreModule {}
