import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  CompareTableFiltersEnum,
  FeedbackCompareRoleFiltersEnum,
} from '@feedback/pages/feedback-compare/feedback-compare.component';

import {
  FeedbackCompareSegment,
  FeedbackCompareSurvey,
  FeedbackCompareUser,
} from '@feedback/store/models/compare.model';
import { ApiData, Meta, OrderBy } from '@models/API.models';
import { CompareOrderBy } from '@models/compare.model';
import { Store } from '@ngrx/store';
import { FiltersBaseService } from '@shared/services/filters-base.service';
import { FiltersLegacyService } from '@shared/services/filters.service';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

export interface CompareSegmentsOptions {
  endDate: { from: string; to: string };
  roles: FeedbackCompareRoleFiltersEnum[];
  skip: number;
  limit: number;
  segmentTypeIds: number[];
  segmentIds?: number[];
  orderBy?: CompareOrderBy[];
}

export interface FeedbackCompareSsurveysOptions {
  comparing: CompareTableFiltersEnum;
  ids: number[];
  endDate: { from: string; to: string };
  skip: number;
  limit: number;
  orderBy: OrderBy[];
  roles: FeedbackCompareRoleFiltersEnum[];
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackCompareService extends FiltersBaseService {
  ENDPOINT_URL = `${environment.API_URL}/feedback/compare`;

  constructor(
    private http: HttpClient,
    private filtersLegacy: FiltersLegacyService,
    store: Store,
  ) {
    super(store);
  }

  compareUsers(
    endDate: { from: string; to: string },
    roles: FeedbackCompareRoleFiltersEnum[],
    orderBy: CompareOrderBy[],
    skip = 0,
    limit = 20,
  ): Observable<{ users: FeedbackCompareUser[]; meta: Meta }> {
    if (this.matrixOrgaRollupFF) {
      return this.http
        .get<ApiData<FeedbackCompareUser[], Meta>>(
          `${this.ENDPOINT_URL}/users?${qs.stringify({
            body: { endDate, roles, ...this.filtersObject },
            meta: {
              skip,
              limit,
              orderBy,
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ users: data, meta })),
        );
    } else {
      return this.http
        .get<ApiData<FeedbackCompareUser[], Meta>>(
          `${this.ENDPOINT_URL}/users?${qs.stringify({
            body: { endDate, roles },
            meta: {
              skip,
              limit,
              orderBy,
              filters: this.filtersLegacy.rawParams(),
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ users: data, meta })),
        );
    }
  }

  compareSegments(
    options: CompareSegmentsOptions,
  ): Observable<{ segments: FeedbackCompareSegment[]; meta: Meta }> {
    const { endDate, roles, skip, limit, orderBy, segmentTypeIds, segmentIds } =
      options;

    if (this.matrixOrgaRollupFF) {
      return this.http
        .get<ApiData<FeedbackCompareSegment[], Meta>>(
          `${this.ENDPOINT_URL}/segments?${qs.stringify({
            body: {
              endDate,
              roles,
              ...this.filtersObject,
              segmentTypeIds,
              ...(segmentIds ? { segmentIds } : {}),
            },
            meta: {
              skip,
              limit,
              orderBy,
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ segments: data, meta })),
        );
    } else {
      return this.http
        .get<ApiData<FeedbackCompareSegment[], Meta>>(
          `${this.ENDPOINT_URL}/segments?${qs.stringify({
            body: {
              endDate,
              roles,
              segmentTypeIds,
              ...(segmentIds ? { segmentIds } : {}),
            },
            meta: {
              skip,
              limit,
              orderBy,
              filters: this.filtersLegacy.rawParams(),
            },
          })}`,
        )
        .pipe(
          retry(1),
          map(({ meta, data }) => ({ segments: data, meta })),
        );
    }
  }

  loadSurveyChunk(
    options: FeedbackCompareSsurveysOptions,
  ): Observable<{ surveys: FeedbackCompareSurvey[]; meta: Meta }> {
    const { ids, comparing, endDate, roles, skip, limit, orderBy } = options;

    return this.http
      .get<ApiData<FeedbackCompareSurvey[], Meta>>(
        `${this.ENDPOINT_URL}/surveys/company?${qs.stringify({
          body: {
            endDate,
            roles,
            ...(comparing === CompareTableFiltersEnum.Users
              ? { userIds: ids }
              : {}),
            ...(comparing === CompareTableFiltersEnum.Segments
              ? { segmentIds: ids }
              : {}),
          },
          meta: {
            skip,
            limit,
            orderBy,
          },
        })}`,
      )
      .pipe(
        retry(1),
        map(({ meta, data }) => ({ surveys: data, meta })),
      );
  }
}
