import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { selectUrl } from '@appstore/selectors/router.selectors';
import { NotificationService } from '@core/services/notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromNotifActions from '../actions/notifications.actions';
import {
  selectNotification,
  selectNotificationsState,
} from '../selectors/notifications.selectors';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notifications: NotificationService,
    private store: Store,
    private router: Router,
  ) {}

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.loadNotificationsChunk),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectNotificationsState))),
        ),
      ),
      exhaustMap(([action, notificationsState]) =>
        this.notifications
          .get(notificationsState.notifications?.length || 0, action.chunkSize)
          .pipe(
            map(({ meta, notifications }) =>
              fromNotifActions.loadNotificationsSuccess({
                meta,
                notifications,
              }),
            ),
            tap(({ notifications }) => {
              if (notifications.length === 0) {
                this.store.dispatch(fromNotifActions.allNotificationsLoaded());
              }
            }),
            catchError((error) =>
              of(fromNotifActions.loadNotificationsFailure({ error })),
            ),
          ),
      ),
    ),
  );
  loadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.loadUnreadNotificationsCount),
      exhaustMap((_) =>
        this.notifications
          .getUnreadCount()
          .pipe(
            map((count) =>
              fromNotifActions.loadUnreadNotificationsCountSuccess({ count }),
            ),
          ),
      ),
    ),
  );
  refreshNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.refreshNotifications),
      exhaustMap(({ chunkSize }) =>
        forkJoin([
          this.notifications.get(0, chunkSize),
          this.notifications.getUnreadCount(),
        ]).pipe(
          map(([notifs, unreadCount]) =>
            fromNotifActions.refreshNotificationsSuccess({
              notifications: notifs.notifications,
              total: notifs.meta.total,
              unreadCount,
            }),
          ),
        ),
      ),
    ),
  );

  notificationClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.notificationClicked),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectNotification, action.notification.id)),
          ),
        ),
      ),
      tap(([action, notification]) => {
        const actions = this.notifications.getNotificationActions(notification);
        if (actions) {
          this.router.navigateByUrl(actions.routing);
        }
      }),
      filter(([_, notification]) => !notification.seen),
      map(([action]) => {
        return fromNotifActions.markAsRead({ id: action.notification.id });
      }),
    ),
  );
  markAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.markAsRead),
      exhaustMap(({ id }) =>
        this.notifications
          .markAsRead(id)
          .pipe(map((_) => fromNotifActions.markAsReadSuccess({ id }))),
      ),
    ),
  );
  markMultipleAsReadByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.markMultipleAsRead),
      exhaustMap(({ ids }) =>
        this.notifications.markMultipleAsRead({ ids }).pipe(
          tap((_) =>
            this.store.dispatch(
              fromNotifActions.loadUnreadNotificationsCount(),
            ),
          ),
          map((updatedIds) =>
            fromNotifActions.markMultipleAsReadSuccess({
              ids: updatedIds,
            }),
          ),
        ),
      ),
    ),
  );

  markAllAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromNotifActions.markAllAsRead,
        fromNotifActions.markAllAsReadWidget,
      ),
      exhaustMap((_) =>
        this.notifications
          .markMultipleAsRead({ ids: [] })
          .pipe(map((_success) => fromNotifActions.markAllAsReadSuccess())),
      ),
    ),
  );
  initPolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromNotifActions.initNotifPolling),
        map((_) => this.notifications.notificationsPolling()),
      ),
    { dispatch: false },
  );
  requestNotifPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotifActions.requestNotifPolling),
      switchMap((_) => this.store.pipe(select(selectUrl))),
      filter((route) => route !== '/mybleexo/notifications'),
      map((_) => fromNotifActions.startNotifPolling()),
    ),
  );

  initDownloadNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromNotifActions.initGenerationNotif),
        tap(({ eventId }) =>
          this.notifications.showNotifForEvent(eventId, {
            message: 'STATUS.CREATION_IN_PROGRESS',
            translateValue: {},
            title: null,
            disableTimeout: true,
            additionalMessage: 'STATUS.CREATION_IN_PROGRESS_DETAILED',
          }),
        ),
      ),
    { dispatch: false },
  );

  readyToDownloadNotif$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(fromNotifActions.readyToDownloadNotif),
        tap(({ eventId, downloadableFile, bleexoModule }) => {
          this.notifications.hideNotifForEvent(eventId);
          this.notifications.showDownload(
            eventId,
            downloadableFile.blob,
            downloadableFile.fileName,
            bleexoModule,
          );
        }),
      )
      .pipe(map((_) => fromNotifActions.readyToDownloadNotifSuccess())),
  );
}
