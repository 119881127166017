import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyHasModulesDirective } from './companyHasModules.directive';
import { UserHasPermissionsDirective } from './userHasPermissions.directive';
import { HasFeatureFlagDirective } from './hasFeatureFlag.directive';

@NgModule({
  declarations: [
    UserHasPermissionsDirective,
    CompanyHasModulesDirective,
    HasFeatureFlagDirective,
  ],
  imports: [CommonModule],
  exports: [
    UserHasPermissionsDirective,
    CompanyHasModulesDirective,
    HasFeatureFlagDirective,
  ],
})
export class PermissionsDirectivesModule {}
