import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as campaignActions from '../actions/feedback-campaign.actions';
import { FeedbackCampaign } from '../models/campaign.model';

export const feedbackCampaignsFeatureKey = 'campaigns';

export interface State extends EntityState<FeedbackCampaign> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FeedbackCampaign> =
  createEntityAdapter<FeedbackCampaign>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    campaignActions.loadFeedbackCampaignsChunk,
    campaignActions.loadFeedbackCampaigns,
    campaignActions.createFeedbackCampaign,
    campaignActions.updateFeedbackCampaign,
    campaignActions.loadOneFeedbackCampaign,
    campaignActions.deleteFeedbackCampaign,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    campaignActions.createFeedbackCampaignSuccess,
    campaignActions.feedbackCampaignFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    campaignActions.loadFeedbackCampaignsChunkSuccess,
    (state, { campaigns }) =>
      adapter.addMany(campaigns, { ...state, loading: false }),
  ),
  on(campaignActions.loadFeedbackCampaignsSuccess, (state, { campaigns }) =>
    adapter.setAll(campaigns, { ...state, loading: false, isLoaded: true }),
  ),
  on(campaignActions.loadOneFeedbackCampaignSuccess, (state, { campaign }) =>
    adapter.setOne(campaign, { ...state, loading: false }),
  ),
  on(campaignActions.deleteFeedbackCampaignSuccess, (state, { campaignId }) =>
    adapter.removeOne(campaignId, { ...state, loading: false }),
  ),
);
