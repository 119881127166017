import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { LegacyStore } from '@core/store/legacy/store.service';
import {
  selectAllSegments,
  selectSegmentById,
} from '@core/store/selectors/segments.selectors';
import { ApiData } from '@models/API.models';
import { Filters } from '@models/filters.model';
import {
  ManagedSegments,
  Segment,
  SegmentType,
  SegmentTypeLegacyStore,
  SegmentTypeWithSegments,
  SegmentUsersCountResData,
  UserSegment,
} from '@models/segments.model';
import { select, Store } from '@ngrx/store';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { map, retry, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FiltersBaseService } from './filters-base.service';
import { FiltersLegacyService } from './filters.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentsService extends FiltersBaseService {
  segmentTypes: SegmentTypeLegacyStore[];
  constructor(
    private http: HttpClient,
    private legacyStore: LegacyStore,
    private filtersService: FiltersLegacyService,
    store: Store,
  ) {
    super(store);
  }

  getSegments(): Observable<Segment[]> {
    // const meta = {
    //   orderBy: {
    //     property: 'name',
    //     direction: 'asc',
    //   },
    // }
    return this.http
      .get<ApiData<Segment[], Meta>>(`${environment.API_URL}/commons/segments`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  getSegment(segmentId: number): Observable<Segment> {
    return this.http
      .get<
        ApiData<Segment, Meta>
      >(`${environment.API_URL}/commons/segments/${segmentId}`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes?.data),
      );
  }

  getUserSegmentIds(userId): Observable<number[]> {
    return this.http
      .get<
        UserSegment[]
      >(`${environment.API_URL}/core/users/${userId}/segments`)
      .pipe(map((segments) => segments.map((segment) => segment.segmentId)));
  }

  getUserManagedSegmentIds(userId): Observable<number[]> {
    return this.http
      .get<
        ManagedSegments[]
      >(`${environment.API_URL}/core/users/${userId}/managedSegments`)
      .pipe(
        map((managedSegments) =>
          managedSegments.map((managedSegment) => managedSegment.segmentId),
        ),
      );
  }

  /**
   * @param filters scope and segmentation
   * @returns list of usersCount by segments
   */
  getSegmentsUsersCount$(
    filters: Filters,
  ): Observable<SegmentUsersCountResData[]> {
    return this.http
      .get<ApiData<SegmentUsersCountResData[], Meta>>(
        `${environment.API_URL}/commons/segments/usersCount?${qs.stringify({
          body: { filters },
        })}`,
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  getSegmentTypes(): Observable<SegmentType[]> {
    // const meta = {
    //   orderBy: {
    //     property: 'name',
    //     direction: 'asc',
    //   },
    // }
    return this.http
      .get<
        ApiData<SegmentType[], Meta>
      >(`${environment.API_URL}/commons/segmentTypes`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  // Before Matrics Organization
  getSegmentsLegacy(): Observable<SegmentTypeLegacyStore[]> {
    return this.http
      .get<
        ApiData<SegmentTypeLegacyStore[], Meta>
      >(`${environment.API_URL}/admin/segments`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  getAllSegments(): Observable<SegmentTypeLegacyStore[]> {
    return this.http
      .get<
        ApiData<SegmentTypeLegacyStore[], Meta>
      >(`${environment.API_URL}/admin/segments?includeDeleted=true`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }

  addSegment(name: string, segmentTypeId: number): Observable<number> {
    return this.http
      .post<
        ApiData<{ id: number }, Meta>
      >(`${environment.API_URL}/admin/segments`, { segmentTypeId, name }, { observe: 'response' })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.body.data.id),
      );
  }

  editSegment(id: number, name: string): Observable<boolean> {
    return this.http
      .patch(
        `${environment.API_URL}/admin/segments/${id}`,
        {
          name,
        },
        { observe: 'response' },
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }

  deleteSegment(id: number): Observable<boolean> {
    return this.http
      .delete(`${environment.API_URL}/admin/segments/${id}`, {
        observe: 'response',
      })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }

  // TYPES
  addSegmentType(name: string): Observable<number> {
    return this.http
      .post<
        ApiData<{ id: number }, Meta>
      >(`${environment.API_URL}/admin/segmentTypes`, { name }, { observe: 'response' })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.body.data.id),
      );
  }

  editSegmentType(id: number, name: string): Observable<boolean> {
    return this.http
      .patch(
        `${environment.API_URL}/admin/segmentTypes/${id}`,
        { name },
        { observe: 'response' },
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }

  deleteSegmentType(id: number): Observable<any> {
    return this.http.delete(`${environment.API_URL}/admin/segmentTypes/${id}`);
  }

  // DEPARTMENT
  getUserDepartment(): Observable<Segment | null> {
    let URL = `${environment.API_URL}/my/user/department`;

    if (this.filtersService.viewAsUser) {
      const meta = {
        filters: {
          viewAsUserId: this.filtersService.viewAsUser.id,
        },
      };
      URL += `?${qs.stringify({
        meta,
      })}`;
    }

    return this.http.get<ApiData<Segment | null, Meta>>(URL).pipe(
      retry(1),
      map((apiRes) => apiRes.data),
    );
  }

  // ---- HELPERS ----

  // TODO companySegments should be optional, provided in reports but from store in app
  formatFromStringArrayToMap(
    segmentsArray: string[],
    companySegments: SegmentTypeLegacyStore[],
  ): Map<number, Segment[]> {
    const segmentsMap = new Map();
    segmentsArray.map((selected) => {
      selected.split(',').forEach((seg) => {
        companySegments.forEach((type) => {
          type.segments.forEach((typeSegment) => {
            if (typeSegment.id === Number(seg)) {
              if (segmentsMap.has(type.id)) {
                segmentsMap.get(type.id).push(typeSegment);
              } else {
                segmentsMap.set(type.id, [typeSegment]);
              }
            }
          });
        });
      });
    });
    return segmentsMap;
  }

  formatFromStringArrayToNames(
    target: string[],
    companySegments: SegmentTypeWithSegments[],
  ): { [key: string]: { name: string; isDeleted: boolean }[] } {
    const formatted = {};
    target
      .toString()
      .split(',')
      .forEach((s) => {
        companySegments.forEach((type: SegmentTypeWithSegments) => {
          type.segments.map((seg) => {
            if (seg.id === Number(s)) {
              formatted[type.name] = formatted[type.name] || [];
              formatted[type.name].push({
                name: seg.name,
              });
            }
          });
        });
      });
    return formatted;
  }

  getSegmentsFromIds(ids: number[], typeId: number): Segment[] {
    const result = [];

    const type = this.legacyStore.segments.find(
      (segmentType: SegmentTypeLegacyStore) => segmentType.id === typeId,
    );
    type.segments.forEach((segment) => {
      if (ids.includes(segment.id)) {
        result.push(segment);
      }
    });
    return result;
  }

  getTypeIdOfSegment(segmentId: number): number | null {
    let segmentType: SegmentType | SegmentTypeLegacyStore = {
      id: null,
      name: null,
      isBenchmarkable: false,
      isMultiSegment: false,
      isDepartment: false,
      isInMyPulseView: false,
      segmentsOrderType: null,
      segmentsCount: null,
    };
    if (this.matrixOrgaRollupFF) {
      this.store
        .pipe(select(selectAllSegments), take(1))
        .subscribe((segments: Segment[]) => {
          segmentType.id = segments.find(
            (segment: Segment) => segment.id === segmentId,
          )?.segmentTypeId;
        });
    } else {
      // TODO: remove this when matrixOrgaRollupFF is removed
      segmentType = this.legacyStore.segments.find((type) =>
        type.segments.map((s) => s.id).includes(segmentId),
      );
    }

    return segmentType ? segmentType.id : null;
  }

  getDepartmentName(departmentId: number): string | null {
    let departmentName = null;
    this.store
      .pipe(select(selectSegmentById(departmentId)), take(1))
      .subscribe((segment: Segment) => {
        departmentName = segment.name;
      });
    return departmentName ? departmentName : null;
  }

  getUserDepartmentName(allowViewAsUser = false): string | null {
    // check du flag dans le legacy store parce que matrix orga activé ou pas le legacy store sera populé
    if (this.legacyStore.company.departmentId) {
      let userSegmentIds = this.legacyStore.currentUser.segmentIds;

      if (
        allowViewAsUser &&
        this.filtersService.filters.viewAsUser?.segmentIds
      ) {
        userSegmentIds = this.filtersService.filters.viewAsUser.segmentIds;
      }

      const departmentSegmentType = this.legacyStore.segments.find(
        (segmentType) =>
          segmentType.id === this.legacyStore.company.departmentId,
      );

      let userDepartment = null;
      if (departmentSegmentType) {
        userDepartment = departmentSegmentType.segments.find((segment) =>
          userSegmentIds.includes(segment.id),
        );
      }
      return userDepartment ? userDepartment.name : null;
    } else {
      return null;
    }
  }
}
