import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked';
import { MarkedOptions } from 'marked';

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  public transform(markdown: string, options?: MarkedOptions): string {
    if (markdown == null) {
      return '';
    }
    return marked(markdown, { smartLists: true, xhtml: true, breaks: true });
  }

  public setOptions(options: MarkedOptions): void {
    marked.setOptions(options);
  }
}
