import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AddSegment, AdminSegment, EditSegment } from '../admin-segments.model';
import { UpdateNum } from '@ngrx/entity/src/models';

export const AdminSegmentsActions = createActionGroup({
  source: 'Admin Segments Page',
  events: {
    'Load Admin Segments': emptyProps(),
    'Add Admin Segment': props<{
      segment: Omit<AddSegment, 'order'>;
    }>(),
    'Update Admin Segment': props<{
      segment: UpdateNum<EditSegment>;
    }>(),
    'Erase Managers Segment From Admin Segment Type': props<{
      id: number;
    }>(),
    'Delete Admin Segment': props<{ id: number }>(),
    'Upload File Managers By Segment From Segment Type Id': props<{
      segmentTypeId: number;
      file: File;
    }>(),
  },
});

export const AdminSegmentsSuccessActions = createActionGroup({
  source: 'AdminSegments/API',
  events: {
    'Load Admin Segments Success': props<{
      segments: AdminSegment[];
    }>(),
    'Add Admin Segment Success': props<{
      segment: AdminSegment;
    }>(),
    'Update Admin Segment Success': props<{
      segment: Update<AdminSegment>;
    }>(),
    'Update Admin Segments Success': props<{
      segments: UpdateNum<AdminSegment>[];
    }>(),
    'Delete Admin Segment Success': props<{ id: number }>(),
    'Update File Managers By Segment From Segment Type Id Success': props<{
      segmentTypeId: number;
      segments: AdminSegment[];
    }>(),
  },
});
