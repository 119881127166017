import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CategoryActions from '../actions/category.actions';
import { FeedbackCategory } from '../models/category.model';

export const categoriesFeatureKey = 'categories';

export interface State extends EntityState<FeedbackCategory> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FeedbackCategory> =
  createEntityAdapter<FeedbackCategory>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    CategoryActions.addCategory,
    CategoryActions.upsertCategory,
    CategoryActions.updateCategory,
    CategoryActions.deleteCategory,
    CategoryActions.loadCategories,
    (state) => ({ ...state, loading: true }),
  ),
  on(CategoryActions.addCategorySuccess, (state, { category }) =>
    adapter.addOne(category, { ...state, loading: false }),
  ),
  on(CategoryActions.upsertCategorySuccess, (state, { category }) =>
    adapter.upsertOne(category, { ...state, loading: false }),
  ),
  on(CategoryActions.updateCategorySuccess, (state, { category }) =>
    adapter.updateOne(category, { ...state, loading: false }),
  ),
  on(CategoryActions.deleteCategorySuccess, (state, { id }) =>
    adapter.removeOne(id, { ...state, loading: false }),
  ),
  on(CategoryActions.loadCategoriesSuccess, (state, { categories }) =>
    adapter.setAll(categories, { ...state, loading: false, isLoaded: true }),
  ),
);
