<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{ t('MEETING.DELETE_CATEGORY') }}</h1>
  <div mat-dialog-content
       class="pb-5">
    <p class="text-title text-red-7 mb-1.25">{{ t('GLOBAL.CONFIRM_MODAL_WARN') }}</p>
    <ul class="pl-5 list-disc">
      <li class="mt-2.5" [innerHTML]="t('MEETING.DELETE_CATEGORY_WARN_1')"></li>
    </ul>
  </div>
  <mat-dialog-actions class="flex justify-between talent-theme">
    <button mat-button
            color="primary"
            mat-dialog-close>{{ t('GLOBAL.CANCEL') | uppercase }}</button>
    <app-button-with-spinner (handleClick)="delete()"
                             [isLoading]="loading$ | async"
                             color='warn'
                             buttonStyle="mat-raised-button">
      {{ t('GLOBAL.DELETE') | uppercase }}
    </app-button-with-spinner>
  </mat-dialog-actions>
</ng-container>
