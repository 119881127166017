import { AdminSegmentsSuccessActions } from '@admin/store/actions/admin-segments.actions';
import { Company } from '@models/company.model';
import { CompanyLogo } from '@models/companyLogo.model';
import { BleexoModule, UserRole } from '@models/permissions.model';
import { Segment, SegmentTypeLegacyStore } from '@models/segments.model';
import { User, UserFull } from '@models/user.model';
import { createReducer, on } from '@ngrx/store';
import {
  bootstrapAppLegacySuccess,
  bootstrapAppSuccess,
} from '../actions/auth.actions';
import {
  getCompanySuccess,
  updateCompanySettingsSuccess,
} from '../actions/company.actions';
import { setCurrentModule, setSurveyModule } from '../actions/core.actions';
import {
  createRoleSuccess,
  deleteRoleSuccess,
  getRolesCountSuccess,
  loadRolesSuccess,
  updateRoleSuccess,
} from '../actions/roles.actions';
import * as UserActions from '../actions/user.actions';

export const coreFeatureKey = 'core';

export interface CoreState {
  isAuthenticated: boolean;
  userLegacy: UserFull;
  user: User;
  company: Company;
  companyLogo: CompanyLogo;
  segments: SegmentTypeLegacyStore[]; // TODO DELETE when legacyStore 100% removed
  roles: Array<UserRole & { count?: number }>;
  currentModule: BleexoModule;
  surveyModule: BleexoModule;
}

export const coreInitialState: CoreState = {
  isAuthenticated: false,
  userLegacy: null,
  user: null,
  company: null,
  companyLogo: null,
  segments: null,
  roles: null,
  currentModule: null,
  surveyModule: null,
};

export const coreReducer = createReducer(
  coreInitialState,
  on(
    bootstrapAppLegacySuccess,
    (state, { user, company, segments, companyLogo }) => ({
      ...state,
      userLegacy: user,
      company,
      segments,
      companyLogo,
      isAuthenticated: true,
    }),
  ),
  on(bootstrapAppSuccess, (state, { user, company, companyLogo }) => ({
    ...state,
    user,
    company,
    companyLogo,
    isAuthenticated: true,
  })),
  on(getCompanySuccess, (state, { company }) => ({
    ...state,
    company,
  })),
  on(updateCompanySettingsSuccess, (state, { companyUpdates }) => ({
    ...state,
    company: {
      ...state.company,
      ...companyUpdates,
    },
  })),
  on(setCurrentModule, (state, { currentModule }) => ({
    ...state,
    currentModule,
  })),
  on(setSurveyModule, (state, { surveyModule }) => ({
    ...state,
    surveyModule,
  })),
  on(UserActions.updateUserLocaleSuccess, (state, { locale }) => ({
    ...state,
    user: { ...state.user, locale },
    userLegacy: { ...state.userLegacy, locale },
  })),
  on(UserActions.GDPRGiveConsentSuccess, (state) => ({
    ...state,
    userLegacy: { ...state.userLegacy, gdprConsentGiven: true },
  })),
  on(UserActions.updateUserPermissions, (state, { permissions }) => ({
    ...state,
    ...(state.company.matrixOrgaRollupFF
      ? {
          user: {
            ...state.user,
            role: { ...state.user.role, permissions },
          },
        }
      : {
          userLegacy: {
            ...state.userLegacy,
            role: { ...state.userLegacy.role, permissions },
          },
        }),
  })),
  on(loadRolesSuccess, (state, { roles }) => ({ ...state, roles })),
  on(updateRoleSuccess, (state, { role }) => ({
    ...state,
    roles: state.roles.map((r) => (r.id === role.id ? role : r)),
  })),
  on(createRoleSuccess, (state, { role }) => ({
    ...state,
    roles: [...state.roles, { ...role, count: 0 }],
  })),
  on(deleteRoleSuccess, (state, { id }) => ({
    ...state,
    roles: state.roles.filter((r) => r.id !== id),
  })),
  on(getRolesCountSuccess, (state, { roleId, count }) => ({
    ...state,
    roles: state.roles.map((r) => (r.id === roleId ? { ...r, count } : r)),
  })),
  on(
    UserActions.updateUserPinnedPulseDriver,
    (state, { pinnedPulseDriver }) => ({
      ...state,
      user: { ...state.user, pinnedPulseDriver },
    }),
  ),
  on(UserActions.updateUserSegmentsSuccess, (state, { segments }) => ({
    ...state,
    user: { ...state.user, segments, segmentIds: segments.map((s) => s.id) },
  })),
  on(
    AdminSegmentsSuccessActions.addAdminSegmentSuccess,
    (state, { segment }) => {
      if (segment.managers.some((m) => m.id === state.user.id)) {
        return {
          ...state,
          user: {
            ...state.user,
            managedSegments: [...state.user.managedSegments, segment],
          },
        };
      } else {
        return state;
      }
    },
  ),
  on(AdminSegmentsSuccessActions.deleteAdminSegmentSuccess, (state, { id }) => {
    return {
      ...state,
      user: {
        ...state.user,
        managedSegments: state.user.managedSegments.filter((s) => s.id !== id),
      },
    };
  }),
  on(
    AdminSegmentsSuccessActions.updateAdminSegmentSuccess,
    (state, { segment }) => {
      // verify if user is manager of the segment and if he is not already in the managedSegments
      if (
        segment.changes.managers.some((m) => m.id === state.user.id) &&
        !state.user.managedSegments.some((s) => s.id === segment.id)
      ) {
        const updatedSegment: Segment = {
          id: segment.changes.id,
          name: segment.changes.name,
          segmentTypeId: segment.changes.segmentTypeId,
        };
        return {
          ...state,
          user: {
            ...state.user,
            managedSegments: [...state.user.managedSegments, updatedSegment],
          },
        };
      }
      // verify if user is not manager anymore of the segment and if the segment is in the managedSegments
      else if (
        !segment.changes.managers.some((m) => m.id === state.user.id) &&
        state.user.managedSegments.some((s) => s.id === segment.id)
      ) {
        return {
          ...state,
          user: {
            ...state.user,
            managedSegments: state.user.managedSegments.filter(
              (s) => s.id !== segment.id,
            ),
          },
        };
      }
      // user is not concerned by the segment
      else {
        return state;
      }
    },
  ),
);
