import { Component, Input } from '@angular/core';
import { BleexoModule } from '@models/permissions.model';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { ToastPreset, toastPresets, toastAnim } from './toast-vars';

@Component({
  selector: '[download-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div
      class="custom-toast shadow-xl border-l-4 border-green-4 flex items-start mb-2.5"
    >
      <svg-icon
        icon="toast-success"
        class="solid text-base text-green-4 mr-2.5"
      ></svg-icon>

      <div class="grow" style="overflow: hidden">
        <h5 class="mb-1.25">
          {{ title || 'STATUS.' + presets.title | transloco }}
        </h5>
        <p *ngIf="message">{{ message }}</p>
        <p class="truncate mb-1.25">
          {{ 'STATUS.DOWNLOAD_READY' | transloco }}
          <span class="text-note">{{ fileName }}</span>
        </p>
        <a
          target="_blank"
          [href]="url"
          [download]="fileName"
          (click)="close($event)"
          class="{{ module }}-theme"
        >
          <button mat-flat-button color="primary">
            {{ 'GLOBAL.DOWNLOAD' | transloco | capitalize
            }}<i class="material-icons ml-2.5">file_download</i>
          </button>
        </a>
      </div>
      <i
        class="material-icons remove-btn text-2xl bigger-hitbox"
        (click)="close($event)"
        >clear</i
      >
    </div>
  `,
  animations: [toastAnim],
})
export class DownloadToastComponent extends Toast {
  @Input() url: string;
  @Input() fileName: string;
  @Input() toastId: number;
  @Input() module: BleexoModule;
  presets: ToastPreset;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.presets = toastPresets.success;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
