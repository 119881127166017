import { Injectable } from '@angular/core';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { FeedbackCampaignService } from '@feedback/services/feedback-campaign.service';
import { FeedbackSurveyService } from '@feedback/services/feedback-survey.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromCampaigns from '../actions/feedback-campaign.actions';
import { getFeedbackSurveyWithQuestions } from '../actions/feedback-survey.actions';
import { selectCampaignsTotal } from '../selectors/campaign.selectors';

@Injectable()
export class FeedbackCampaignEffects {
  constructor(
    private actions$: Actions,
    private campaigns: FeedbackCampaignService,
    private surveys: FeedbackSurveyService,
    private store: Store,
  ) {}

  loadCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.loadFeedbackCampaigns),
      switchMap(({ options, limit }) => {
        return this.campaigns.loadChunk(options, 0, limit).pipe(
          map(({ campaigns, meta }) =>
            fromCampaigns.loadFeedbackCampaignsSuccess({
              campaigns,
              meta,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  loadCampaignsChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.loadFeedbackCampaignsChunk),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectCampaignsTotal))),
        ),
      ),
      exhaustMap(([action, loadedCampaignsTotal]) => {
        const { options, limit } = action;
        return this.campaigns
          .loadChunk(options, loadedCampaignsTotal, limit)
          .pipe(
            map(({ campaigns, meta }) =>
              fromCampaigns.loadFeedbackCampaignsChunkSuccess({
                campaigns,
                meta,
              }),
            ),
            catchError((error: Error) => {
              this.store.dispatch(
                fromCampaigns.feedbackCampaignFailure({ error }),
              );
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          );
      }),
    ),
  );

  loadOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.loadOneFeedbackCampaign),
      switchMap(({ campaignId }) => {
        return this.campaigns.getOne(campaignId).pipe(
          map((campaign) =>
            fromCampaigns.loadOneFeedbackCampaignSuccess({
              campaign,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  getSurveyFromCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.getSurveyFromCampaign),
      exhaustMap(({ campaignId }) => {
        return this.surveys
          .getSurveyFromCampaign(campaignId)
          .pipe(
            map((survey) => getFeedbackSurveyWithQuestions({ id: survey.id })),
          );
      }),
    ),
  );
  getSurveyAndQuestionsFromCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.getSurveyAndQuestionsFromCampaign),
      exhaustMap(({ campaignId }) => {
        return this.surveys
          .loadManageSurveysChunk(
            { campaignIds: [campaignId] },
            { skip: 0, limit: 1 },
          )
          .pipe(
            map(({ surveys }) =>
              getFeedbackSurveyWithQuestions({ id: surveys[0].id }),
            ),
          );
      }),
    ),
  );

  getReviewersForCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.getReviewersForCampaign),
      exhaustMap(({ campaignId }) =>
        this.surveys
          .loadManageSurveysChunk(
            { campaignIds: [campaignId] },
            { onlyCount: true },
          )
          .pipe(
            switchMap(({ meta }) =>
              this.surveys
                .loadManageSurveysChunk(
                  { campaignIds: [campaignId] },
                  { skip: 0, limit: meta.total },
                )
                .pipe(
                  map(({ surveys }) =>
                    fromCampaigns.getReviewersForCampaignSuccess({
                      campaignReviewees: surveys.map((s) => s.reviewee),
                    }),
                  ),
                ),
            ),
          ),
      ),
    ),
  );

  createCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.createFeedbackCampaign),
      exhaustMap(({ campaign }) =>
        this.campaigns.create(campaign).pipe(
          map((_) => fromCampaigns.createFeedbackCampaignSuccess()),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.updateFeedbackCampaign),
      exhaustMap(({ campaign, campaignId }) =>
        this.campaigns.update(campaignId, campaign).pipe(
          map((_) => fromCampaigns.updateFeedbackCampaignSuccess()),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.deleteFeedbackCampaign),
      exhaustMap(({ campaignId }) =>
        this.campaigns.delete(campaignId).pipe(
          map((_) =>
            fromCampaigns.deleteFeedbackCampaignSuccess({ campaignId }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  forceStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.forceStartFeedbackCampaign),
      exhaustMap(({ campaignId }) =>
        this.campaigns.forceStart(campaignId).pipe(
          map((_) => fromCampaigns.forceStartFeedbackCampaignSuccess()),
          tap((_) =>
            this.store.dispatch(
              fromCampaigns.loadOneFeedbackCampaign({
                campaignId,
              }),
            ),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  forceStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.forceStopFeedbackCampaign),
      exhaustMap(({ campaignId }) =>
        this.campaigns.forceStop(campaignId).pipe(
          map((_) => fromCampaigns.forceStopFeedbackCampaignSuccess()),
          tap((_) =>
            this.store.dispatch(
              fromCampaigns.loadOneFeedbackCampaign({
                campaignId,
              }),
            ),
          ),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  sendAnswersReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.sendFeedbackCampaignAnswerReminders),
      exhaustMap(({ campaignId }) =>
        this.campaigns.sendAnswersReminders(campaignId).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromCampaigns.sendFeedbackCampaignAnswerRemindersSuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  sendInvitationsReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.sendFeedbackCampaignInvitationsReminders),
      exhaustMap(({ campaignId }) =>
        this.campaigns.sendInvitationsReminders(campaignId).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromCampaigns.sendFeedbackCampaignInvitationsRemindersSuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  sendRevisionReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.sendFeedbackCampaignRevisionReminders),
      exhaustMap(({ campaignId }) =>
        this.campaigns.sendRevisionReminders(campaignId).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromCampaigns.sendFeedbackCampaignRevisionRemindersSuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  shareReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaigns.shareFeedbackCampaignReport),
      exhaustMap(({ campaignId }) =>
        this.campaigns.shareReports(campaignId).pipe(
          map((_) => fromCampaigns.shareFeedbackCampaignReportSuccess()),
          catchError((error: Error) => {
            this.store.dispatch(
              fromCampaigns.feedbackCampaignFailure({ error }),
            );
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
}
