import { Injectable } from '@angular/core';
import { showError, showSuccess } from '@core/store/actions/toast.actions';
import { FeedbackParticipantsService } from '@feedback/services/feedback-participants.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FeedbackQuestionService } from '../../services/feedback-question.service';
import { FeedbackSurveyService } from '../../services/feedback-survey.service';
import * as fromSurveys from '../actions/feedback-survey.actions';
import { FeedbackSurveyTarget } from '../models/survey.model';
import {
  selectActionableFeedbackSurvey,
  selectAllEndedSurveys,
} from '../selectors/survey.selectors';

@Injectable()
export class FeedbackSurveysEffects {
  constructor(
    private actions$: Actions,
    private surveys: FeedbackSurveyService,
    private store: Store,
    private questions: FeedbackQuestionService,
    private participants: FeedbackParticipantsService,
  ) {}

  loadActionableSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.loadActionableFeedbackSurveys),
      exhaustMap((_) =>
        this.surveys
          .loadAll(['INVITATIONS', 'READY', 'POLLING', 'REVISION'])
          .pipe(
            map((surveys) =>
              fromSurveys.loadActionableFeedbackSurveysSuccess({ surveys }),
            ),
            catchError((error: Error) => {
              this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          ),
      ),
    ),
  );
  loadEndedFeedbackSurveysChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.loadEndedFeedbackSurveysChunk),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectAllEndedSurveys))),
        ),
      ),
      exhaustMap(([action, endedSurveysState]) =>
        this.surveys
          .loadChunk(
            ['ENDED', 'CANCELLED'],
            action.mySurveyRoles,
            action.skip ?? endedSurveysState.length,
            action.chunkSize,
          )
          .pipe(
            map(({ meta, endedSurveys }) => {
              if (action.skip === 0) {
                return fromSurveys.refreshEndedFeedbackSurveys({
                  meta,
                  surveys: endedSurveys,
                });
              } else {
                return fromSurveys.loadEndedFeedbackSurveysChunkSuccess({
                  meta,
                  surveys: endedSurveys,
                });
              }
            }),
            tap(({ surveys }) => {
              if (surveys.length === 0) {
                this.store.dispatch(fromSurveys.allEndedSurveysLoaded());
              }
            }),
            catchError((error: Error) => {
              this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          ),
      ),
    ),
  );
  loadManageFeedbackSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.loadManageFeedbackSurveys),
      switchMap(({ options, metaOptions }) =>
        this.surveys.loadManageSurveysChunk(options, metaOptions).pipe(
          map(({ meta, surveys }) => {
            return fromSurveys.loadManageFeedbackSurveysSuccess({
              meta,
              surveys,
            });
          }),
          tap(({ surveys }) => {
            if (surveys.length === 0) {
              this.store.dispatch(fromSurveys.allEndedSurveysLoaded());
            }
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );

  getCompareSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getCompareSurveys),
      switchMap(({ endDates, role }) =>
        this.surveys
          .getCompareSurveys(endDates, role)
          .pipe(
            switchMap(({ meta }) =>
              this.surveys.getCompareSurveys(endDates, role, meta.total),
            ),
          )
          .pipe(
            map(({ meta, data }) => ({ surveys: data, meta })),
            map(({ surveys }) =>
              fromSurveys.getCompareSurveysSuccess({ surveys }),
            ),
            catchError((error: Error) => {
              this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          ),
      ),
    ),
  );

  getCompareSurveysFromSelectedSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getCompareSurveysFromSelectedSegments),
      switchMap(({ dates, role, segmentsIds }) =>
        this.surveys
          .getCompareSurveysFromSelectedSegments(dates, role, segmentsIds)
          .pipe(
            switchMap(({ meta }) =>
              this.surveys.getCompareSurveysFromSelectedSegments(
                dates,
                role,
                segmentsIds,
                meta.total,
              ),
            ),
            map(({ meta, data }) => ({ surveys: data, meta })),
            map(({ surveys }) =>
              surveys.filter((surv) => surv.endDate !== null),
            ),
            map((surveys) =>
              fromSurveys.getCompareSurveysFromSelectedSegmentsSuccess({
                surveys,
              }),
            ),
            catchError((error: Error) => {
              this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          ),
      ),
    ),
  );

  getSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getFeedbackSurvey),
      exhaustMap(({ surveyId }) =>
        this.surveys.getOne(surveyId).pipe(
          map((survey) => fromSurveys.getFeedbackSurveySuccess({ survey })),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  loadMultipleSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.loadMultipleFeedbackSurveys),
      filter((action) => !!action.ids.length),
      exhaustMap(({ ids }) =>
        this.surveys
          .getMultiple(ids)
          .pipe(
            map((surveys) =>
              fromSurveys.loadMultipleFeedbackSurveysSuccess({ surveys }),
            ),
          ),
      ),
    ),
  );
  loadOneSurveyWithQuestionsAndAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getFeedbackSurveyWithQuestionsAndAnswers),
      exhaustMap(({ id }) =>
        forkJoin([
          this.surveys.getOne(id),
          this.questions.getSurveyQuestions(id),
          this.surveys.getMyAnswers(id),
        ]).pipe(
          map(([survey, questions, answers]) =>
            fromSurveys.getFeedbackSurveyWithQuestionsAndAnswersSuccess({
              survey,
              questions,
              answers,
            }),
          ),
        ),
      ),
    ),
  );
  loadOneSurveyWithQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getFeedbackSurveyWithQuestions),
      exhaustMap(({ id }) =>
        forkJoin([
          this.surveys.getOne(id),
          this.questions.getSurveyQuestions(id),
        ]).pipe(
          map(([survey, questions]) =>
            fromSurveys.getFeedbackSurveyWithQuestionsSuccess({
              survey,
              questions,
              answers: null,
            }),
          ),
        ),
      ),
    ),
  );
  getSurveyReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getSurveyReport),
      exhaustMap(({ id }) =>
        forkJoin([
          this.surveys.getOne(id),
          this.questions.getSurveyQuestions(id),
          this.surveys.getAnswers(id),
          this.participants.getCount(id),
          this.participants.getCount(id, true),
        ]).pipe(
          map(
            ([survey, questions, answers, participantsCount, answeredCount]) =>
              fromSurveys.getSurveyReportSuccess({
                report: {
                  survey,
                  questions,
                  answers,
                  participantsCount,
                  answeredCount,
                },
              }),
          ),
        ),
      ),
    ),
  );
  createSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.createFeedbackSurvey),
      exhaustMap(({ survey, target }) =>
        this.surveys.create(survey).pipe(
          map((createdSurvey) => {
            if (target === FeedbackSurveyTarget.MYSELF) {
              return fromSurveys.createSelfFeedbackSurveySuccess({
                survey: createdSurvey,
              });
            } else if (target === FeedbackSurveyTarget.OTHER) {
              return fromSurveys.createOtherFeedbackSurveySuccess({
                survey: createdSurvey,
              });
            }
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  editSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.updateFeedbackSurvey),
      mergeMap(({ surveyId, survey, target }) => {
        return this.surveys.update(surveyId, survey).pipe(
          map((editedSurvey) => {
            const updatedSurvey = {
              id: surveyId,
              changes: editedSurvey,
            };
            if (target === FeedbackSurveyTarget.MYSELF) {
              return fromSurveys.updateSelfFeedbackSurveySuccess({
                updatedSurvey,
                survey: editedSurvey,
              });
            } else if (target === FeedbackSurveyTarget.OTHER) {
              return fromSurveys.updateOtherFeedbackSurveySuccess({
                updatedSurvey,
                survey: editedSurvey,
              });
            }
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );
  changeSurveyReviewee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.changeSurveyRevieweeFeedbackSurvey),
      mergeMap(({ surveyId, survey }) => {
        return this.surveys.create(survey).pipe(
          mergeMap((createdSurvey) => {
            return this.surveys.delete(surveyId).pipe(
              map((_) =>
                fromSurveys.changeSurveyRevieweeFeedbackSurveySuccess({
                  survey: createdSurvey,
                }),
              ),
            );
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );
  deleteSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.deleteFeedbackSurvey),
      exhaustMap(({ id }) =>
        this.surveys.delete(id).pipe(
          map((_) => fromSurveys.deleteFeedbackSurveySuccess({ id })),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  forceStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.forceStartFeedbackSurvey),
      exhaustMap(({ id }) =>
        this.surveys.forceStart(id).pipe(
          map((_) => fromSurveys.forceStartFeedbackSurveySuccess()),
          tap((_) =>
            this.store.dispatch(
              fromSurveys.getFeedbackSurveyUpdatedProperties({
                id,
                properties: ['status', 'startDate', 'participation'],
              }),
            ),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  forceStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.forceStopFeedbackSurvey),
      exhaustMap(({ id }) =>
        this.surveys.forceStop(id).pipe(
          map((_) => fromSurveys.forceStopFeedbackSurveySuccess()),
          tap((_) =>
            this.store.dispatch(
              fromSurveys.getFeedbackSurveyUpdatedProperties({
                id,
                properties: ['status', 'endDate', 'participation'],
              }),
            ),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  forceReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.forceRemindersFeedbackSurvey),
      exhaustMap(({ id }) =>
        this.surveys.forceReminders(id).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromSurveys.forceRemindersFeedbackSurveySuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  sendRevieweeReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.sendRevieweeReminderFeedbackSurvey),
      exhaustMap(({ surveyId }) =>
        this.surveys.sendRevieweeReminder(surveyId).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromSurveys.sendRevieweeReminderFeedbackSurveySuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  updateCoachForFeedbackSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.updateCoachForFeedbackSurvey),
      exhaustMap(({ surveyId, coach }) =>
        this.surveys.addCoach(surveyId, coach.id).pipe(
          map((_) =>
            fromSurveys.updateCoachForFeedbackSurveySuccess({
              id: surveyId,
              changes: { coach },
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  deleteCoachForFeedbackSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.deleteCoachForFeedbackSurvey),
      exhaustMap(({ surveyId }) =>
        this.surveys.deleteCoach(surveyId).pipe(
          map((_) =>
            fromSurveys.deleteCoachForFeedbackSurveySuccess({
              id: surveyId,
              changes: { coach: null },
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  shareReportForFeedbackSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.shareReportForFeedbackSurvey),
      exhaustMap(({ surveyId }) =>
        this.surveys.shareReport(surveyId).pipe(
          map(({ coachReportReadDate, status }) =>
            fromSurveys.shareReportForFeedbackSurveySuccess({
              id: surveyId,
              changes: { coachReportReadDate, status },
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  sendCoachReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.sendCoachReminderFeedbackSurvey),
      exhaustMap(({ surveyId }) =>
        this.surveys.sendCoachReminder(surveyId).pipe(
          map((_) => {
            this.store.dispatch(
              showSuccess({ successMessage: 'FEEDBACK.SEND_REMINDER_SUCCESS' }),
            );
            return fromSurveys.sendCoachReminderFeedbackSurveySuccess();
          }),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  getFeedbackSurveyUpdatedProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getFeedbackSurveyUpdatedProperties),
      exhaustMap(({ id, properties }) =>
        this.surveys.getUpdatedProperties(id, properties).pipe(
          map((changes) =>
            fromSurveys.getFeedbackSurveyUpdatedPropertiesSuccess({
              id,
              changes,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
  updateEndedSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromSurveys.getFeedbackSurveyUpdatedPropertiesSuccess,
        fromSurveys.shareReportForFeedbackSurveySuccess,
      ),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectActionableFeedbackSurvey(action.id))),
          ),
        ),
      ),
      filter(([_, survey]) => survey?.status === 'ENDED'),
      mergeMap(([_, survey]) =>
        of(fromSurveys.addEndedFeedbackSurvey({ survey })),
      ),
    ),
  );
  upsertAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.upsertFeedbackSurveyAnswers),
      exhaustMap(({ surveyId, hasAnswered, answers }) => {
        const API_CALL = !hasAnswered
          ? this.surveys.postAnswers({ surveyId, answers })
          : this.surveys.patchAnswers({ surveyId, answers });

        return API_CALL.pipe(
          map((_) => fromSurveys.upsertFeedbackSurveyAnswersSuccess()),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );
  getAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSurveys.getFeedbackSurveyAnswers),
      exhaustMap(({ surveyId }) =>
        this.surveys.getAnswers(surveyId).pipe(
          map((answers) =>
            fromSurveys.getFeedbackSurveyAnswersSuccess({ answers }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromSurveys.feedbackSurveyFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        ),
      ),
    ),
  );
}
