import { CampaignOptions } from '@feedback/services/feedback-campaign.service';
import { Meta } from '@models/API.models';
import { TeamMember } from '@models/user.model';
import { createAction, props } from '@ngrx/store';
import {
  FeedbackCampaign,
  FeedbackCampaignCreatePayload,
} from '../models/campaign.model';

export const loadFeedbackCampaigns = createAction(
  '[FeedbackCampaign] Load FeedbackCampaigns',
  props<{ options: CampaignOptions; limit: number }>(),
);
export const loadFeedbackCampaignsSuccess = createAction(
  '[Feedback/API] Load FeedbackCampaigns Success',
  props<{ campaigns: FeedbackCampaign[]; meta: Meta }>(),
);

export const loadOneFeedbackCampaign = createAction(
  '[FeedbackCampaign] Load One FeedbackCampaign',
  props<{ campaignId: number }>(),
);
export const loadOneFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Load One FeedbackCampaign Success',
  props<{ campaign: FeedbackCampaign }>(),
);

export const loadFeedbackCampaignsChunk = createAction(
  '[FeedbackCampaign] Load FeedbackCampaigns Chunk',
  props<{ options: CampaignOptions; limit: number }>(),
);
export const loadFeedbackCampaignsChunkSuccess = createAction(
  '[Feedback/API] Load FeedbackCampaigns Chunk Success',
  props<{ campaigns: FeedbackCampaign[]; meta: Meta }>(),
);

export const getSurveyFromCampaign = createAction(
  '[Feedback] Get Survey From Campaign',
  props<{ campaignId: number }>(),
);

export const getSurveyAndQuestionsFromCampaign = createAction(
  '[Feedback] Get Survey And Questions From Campaign',
  props<{ campaignId: number }>(),
);

export const getReviewersForCampaign = createAction(
  '[Feedback] Get Reviewers For Campaign',
  props<{ campaignId: number }>(),
);
export const getReviewersForCampaignSuccess = createAction(
  '[Feedback] Get Reviewers For Campaign',
  props<{ campaignReviewees: TeamMember[] }>(),
);

// CRUD
export const createFeedbackCampaign = createAction(
  '[Feedback Create] Create FeedbackCampaign',
  props<{ campaign: FeedbackCampaignCreatePayload }>(),
);
export const createFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Create FeedbackCampaign Success',
);

export const updateFeedbackCampaign = createAction(
  '[Feedback Create] Update FeedbackCampaign',
  props<{ campaignId: number; campaign: FeedbackCampaignCreatePayload }>(),
);
export const updateFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Update FeedbackCampaign Success',
);

export const deleteFeedbackCampaign = createAction(
  '[Feedback Campaigns Page] Delete FeedbackCampaign',
  props<{ campaignId: number }>(),
);
export const deleteFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Delete FeedbackCampaign Success',
  props<{ campaignId: number }>(),
);

// OPERATIONS
export const forceStartFeedbackCampaign = createAction(
  '[Feedback Campaign View] Force Start Campaign',
  props<{ campaignId: number }>(),
);
export const forceStartFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Force Start Campaign Success',
);

export const forceStopFeedbackCampaign = createAction(
  '[Feedback Campaign View] Force Stop Campaign',
  props<{ campaignId: number }>(),
);
export const forceStopFeedbackCampaignSuccess = createAction(
  '[Feedback/API] Force Stop Campaign Success',
);

export const sendFeedbackCampaignInvitationsReminders = createAction(
  '[Feedback Campaign View] Send Invitations Reminders',
  props<{ campaignId: number }>(),
);
export const sendFeedbackCampaignInvitationsRemindersSuccess = createAction(
  '[Feedback/API] Send Invitations Reminders Success',
);

export const sendFeedbackCampaignAnswerReminders = createAction(
  '[Feedback Campaign View] Send Answer Reminders',
  props<{ campaignId: number }>(),
);
export const sendFeedbackCampaignAnswerRemindersSuccess = createAction(
  '[Feedback/API] Send Campaign Answer Reminders Success',
);

export const sendFeedbackCampaignRevisionReminders = createAction(
  '[Feedback Campaign View] Send Revision Reminders',
  props<{ campaignId: number }>(),
);
export const sendFeedbackCampaignRevisionRemindersSuccess = createAction(
  '[Feedback/API] Send Revision Reminders Success',
);

export const shareFeedbackCampaignReport = createAction(
  '[Feedback Campaign View] Share Reports',
  props<{ campaignId: number }>(),
);
export const shareFeedbackCampaignReportSuccess = createAction(
  '[Feedback/API] Share Reports Success',
);

// Errors
export const feedbackCampaignFailure = createAction(
  '[FeedbackCampaign/API] FeedbackCampaign Failure',
  props<{ error: Error }>(),
);
