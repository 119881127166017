<div class="blx-container"
     *transloco="let t">
  <blx-filters>
  </blx-filters>

  <h3 class="mb-7.5">{{ 'FEEDBACK.COMPARE_PAGE_SCOPE' | transloco }}</h3>

  <form [formGroup]="pageFiltersForm"
        class="flex flex-wrap justify-start mb-2.5">

    <div class="mr-5 mb-2.5">
      <p class="font-semibold text-grey-7">{{ 'FEEDBACK.AS_ROLE' | transloco | colon }}</p>
      <mat-form-field appearance="outline"
                      floatLabel="never">
        <mat-select formControlName="role">
          <mat-option [value]="ROLE_ENUM.Reviewee">
            {{ 'FEEDBACK.ROLE_REVIEWEE' | transloco }}
          </mat-option>
          <mat-option [value]="ROLE_ENUM.Reviewer">
            {{ 'FEEDBACK.SURVEY_REVIEWER' | transloco }}
          </mat-option>
          <mat-option [value]="ROLE_ENUM.Coach">
            {{ 'FEEDBACK.SURVEY_COACH' | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mr-5 mb-2.5">
      <p class="font-semibold text-grey-7">{{ 'COMPARE.SELECT_PERIOD' | transloco | colon }}</p>
      <mat-form-field appearance="outline"
                      (click)="picker.open()">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate
                 formControlName="from"
                 readonly
                 [placeholder]="'GLOBAL.START' | transloco | capitalize">
          <input matEndDate
                 formControlName="to"
                 readonly
                 [placeholder]="'GLOBAL.END' | transloco | capitalize">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"
                               class="inline-block"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button
                    color="primary"
                    matDateRangePickerCancel>{{'GLOBAL.CANCEL' | transloco | uppercase }}</button>
            <button mat-raised-button
                    color="primary"
                    matDateRangePickerApply>{{'GLOBAL.APPLY' | transloco | uppercase }}</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>

    </div>

    <div class="flex flex-wrap">
      <div class="mr-2.5 mb-2.5">
        <p class="font-semibold text-grey-7">{{ 'FEEDBACK.COMPARE_TYPE' | transloco | colon }}</p>
        <mat-form-field appearance="outline"
                        floatLabel="never">
          <mat-select formControlName="comparing"
                      (selectionChange)="resetSegmentsSelection()">
            <mat-option [value]="TABLE_FILTERS_ENUM.Users">
              {{ 'USERS.USER' | transloco }}
            </mat-option>
            <mat-option [value]="TABLE_FILTERS_ENUM.Segments">
              {{ 'LAYOUT.SEGMENT' | transloco }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="ml-2.5"
           *ngIf="compareType === TABLE_FILTERS_ENUM.Segments"
           @accordion>
        <div class="flex flex-wrap items-center pt-6">
          <mat-form-field class="mr-5"
                          appearance="outline">
            <mat-label>{{ t('SEGMENTS.SEGMENT_TYPE') | capitalize }}</mat-label>
            <mat-select (selectionChange)="segmentTypeChanged($event.value)"
                        formControlName="segmentType"
                        [disabled]="allSegmentTypesWithSegments.size === 0"
                        id="selectSegmentType">
              <mat-option *ngFor="let segmentTypeWithSegments of allSegmentTypesWithSegments | keyvalue"
                          [value]="segmentTypeWithSegments.value.segmentType">
                {{segmentTypeWithSegments.value.segmentType.name}} </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="segmentType">
            <p *ngIf="!displaySegmentsSelection"
               [@fadeIn]
               (click)="displaySegmentsSelection = true"
               class="inline-button "><i class="material-icons">local_offer</i>{{ 'PULSE.SPECIFY_SEGMENTS' |
              transloco }}
            </p>
            <blx-segment-multi-select *ngIf="displaySegmentsSelection"
                                      title="{{ t('GLOBAL.SEGMENT.other') | capitalize }}"
                                      [segmentTypeId]="segmentType.id"
                                      (listSelectionChange)="selectSegments($event)">
            </blx-segment-multi-select>
          </ng-container>

          <span *ngIf="segmentType && !allSegmentTypesWithSegments.get(segmentType.id).segments?.length" class="mb-5 text-note">{{ t('COMPARE.COMPARE_NO_SEGMENT') }}</span>

        </div>
      </div>
    </div>
  </form>

  <blx-feedback-compare-surveys-table [formChanges]="pageFiltersForm.value"></blx-feedback-compare-surveys-table>


</div>