import { Route } from '@angular/router';
import { BleexoLocale } from './bleexo.models';
import { ModulePermission, Permission } from './permissions.model';

export interface BleexoRoute extends Route {
  data?: {
    expectedPermissions?: Permission[];
    permissionsOperator?: 'AND' | 'OR';
    locale?: BleexoLocale;
    hasModule?: ModulePermission;
    featureFlag?: string;
    featureVersions?: string[];
  };
  children?: BleexoRoute[];
}

export enum TALENT_TRANSLOCO_HEADER_FROM_ROUTE {}
