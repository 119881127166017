import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalLoaderComponent } from './horizontal-loader.component';

@NgModule({
  declarations: [HorizontalLoaderComponent],
  imports: [CommonModule],
  exports: [HorizontalLoaderComponent],
})
export class HorizontalLoaderModule {}
