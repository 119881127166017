import { FeedbackCompareRoleFiltersEnum } from '@feedback/pages/feedback-compare/feedback-compare.component';
import { OrderBy } from '@models/API.models';
import { CompareOrderDirections } from '@models/compare.model';
import { TeamMember } from '@models/user.model';
import { UpdateNum } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { FeebackCoreQuestion } from '../models/question.model';
import {
  FeedbackSurvey,
  FeedbackSurveyAnswer,
  FeedbackSurveyPayload,
  FeedbackSurveyStatus,
  FeedbackSurveyTarget,
} from '../models/survey.model';

export const loadActionableFeedbackSurveys = createAction(
  '[Feedback my360] Load Actionable FeedbackSurveys',
);
export const loadActionableFeedbackSurveysSuccess = createAction(
  '[FeedbackSurvey/API] Load Actionable FeedbackSurveys Success',
  props<{ surveys: FeedbackSurvey[] }>(),
);

export const loadMultipleFeedbackSurveys = createAction(
  '[Surveys/Feedback] Load Multiple FeedbackSurvey',
  props<{ ids: number[] }>(),
);
export const loadMultipleFeedbackSurveysSuccess = createAction(
  '[FeedbackSurvey/API] Load Multiple FeedbackSurvey Success',
  props<{ surveys: FeedbackSurvey[] }>(),
);

export const getFeedbackSurveyWithQuestionsAndAnswers = createAction(
  '[Surveys/Feedback] Load FeedbackSurvey With Questions And Answers',
  props<{ id: number }>(),
);
export const getFeedbackSurveyWithQuestionsAndAnswersSuccess = createAction(
  '[FeedbackSurvey/API] Load FeedbackSurvey With Questions And Answers Success',
  props<{
    survey: FeedbackSurvey;
    questions: FeebackCoreQuestion[];
    answers: Array<FeedbackSurveyAnswer>;
  }>(),
);

export const getFeedbackSurveyWithQuestions = createAction(
  '[Surveys/Feedback] Load FeedbackSurvey With Questions',
  props<{ id: number }>(),
);
export const getFeedbackSurveyWithQuestionsSuccess = createAction(
  '[FeedbackSurvey/API] Load FeedbackSurvey With Questions Success',
  props<{
    survey: FeedbackSurvey;
    questions: FeebackCoreQuestion[];
    answers: null;
  }>(),
);

export const upsertFeedbackSurveyAnswers = createAction(
  '[Surveys/Feedback] Upsert Answers',
  props<{
    surveyId: number;
    hasAnswered: boolean;
    answers: Array<FeedbackSurveyAnswer>;
  }>(),
);
export const upsertFeedbackSurveyAnswersSuccess = createAction(
  '[FeedbackSurvey/API] Upsert Answers Success',
);

export const getSurveyReport = createAction(
  '[Feedback/Report] Get FeedbackSurvey Report',
  props<{ id: number }>(),
);
export const getSurveyReportSuccess = createAction(
  '[FeedbackSurvey/API] Get FeedbackSurvey Report Success',
  props<{
    report: {
      survey: FeedbackSurvey;
      questions: FeebackCoreQuestion[];
      answers: Array<FeedbackSurveyAnswer>;
      participantsCount: number;
      answeredCount: number;
    };
  }>(),
);

export const getFeedbackSurveyAnswers = createAction(
  '[Surveys/Feedback] Get Answers',
  props<{
    surveyId: number;
  }>(),
);
export const getFeedbackSurveyAnswersSuccess = createAction(
  '[FeedbackSurvey/API] Get Answers Success',
  props<{
    answers: Array<FeedbackSurveyAnswer>;
  }>(),
);

export const loadEndedFeedbackSurveysChunk = createAction(
  '[my360] Load ended FeedbackSurveys',
  props<{
    mySurveyRoles: Array<'REVIEWEE' | 'REVIEWER' | 'COACH'>;
    chunkSize: number;
    skip?: number;
  }>(),
);
export const loadEndedFeedbackSurveysChunkSuccess = createAction(
  '[FeedbackSurvey/API] Load ended FeedbackSurveys Success',
  props<{ meta: { total: number }; surveys: FeedbackSurvey[] }>(),
);
export const refreshEndedFeedbackSurveys = createAction(
  '[FeedbackSurvey/API] Refresh ended FeedbackSurveys Success',
  props<{ meta: { total: number }; surveys: FeedbackSurvey[] }>(),
);
export const allEndedSurveysLoaded = createAction(
  '[FeedbackSurvey/API] All Ended Surveys Loaded',
);

export const getFeedbackSurvey = createAction(
  '[Feedback] Get FeedbackSurvey',
  props<{ surveyId: number }>(),
);
export const getFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Get FeedbackSurvey Success',
  props<{ survey: FeedbackSurvey }>(),
);

// CRUD
export const createFeedbackSurvey = createAction(
  '[Feedback Create] Create FeedbackSurvey',
  props<{
    survey: FeedbackSurveyPayload;
    target: FeedbackSurveyTarget;
  }>(),
);
export const createSelfFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Create FeedbackSurvey for Myself Success',
  props<{ survey: FeedbackSurvey }>(),
);

export const createOtherFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Create FeedbackSurvey for Other Success',
  props<{ survey: FeedbackSurvey }>(),
);

export const addEndedFeedbackSurvey = createAction(
  '[FeedbackSurvey/API] Add Ended FeedbackSurvey',
  props<{ survey: FeedbackSurvey }>(),
);

export const updateFeedbackSurvey = createAction(
  '[Feedback Create] Update FeedbackSurvey',
  props<{
    surveyId: number;
    survey: FeedbackSurveyPayload;
    target: FeedbackSurveyTarget;
  }>(),
);
export const updateSelfFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Update FeedbackSurvey for Myself Success',
  props<{
    updatedSurvey: UpdateNum<FeedbackSurvey>;
    survey: FeedbackSurvey;
  }>(),
);
export const updateOtherFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Update FeedbackSurvey for Other Success',
  props<{ updatedSurvey: UpdateNum<FeedbackSurvey>; survey: FeedbackSurvey }>(),
);

export const changeSurveyRevieweeFeedbackSurvey = createAction(
  '[Feedback Create] Change Reviewee for Other',
  props<{ surveyId: number; survey: FeedbackSurveyPayload }>(),
);

export const changeSurveyRevieweeFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Update FeedbackSurvey for Other Success',
  props<{ survey: FeedbackSurvey }>(),
);

export const deleteFeedbackSurvey = createAction(
  '[Feedback my360] Delete FeedbackSurvey',
  props<{ id: number }>(),
);
export const deleteFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Delete FeedbackSurvey Success',
  props<{ id: number }>(),
);

export const getFeedbackSurveyUpdatedProperties = createAction(
  '[Feedback] Get FeedbackSurvey Updated properties',
  props<{ id: number; properties: Array<keyof FeedbackSurvey> }>(),
);
export const getFeedbackSurveyUpdatedPropertiesSuccess = createAction(
  '[Feedback] Get FeedbackSurvey Updated properties Success',
  props<UpdateNum<FeedbackSurvey>>(),
);

// Forced Actions
export const forceStartFeedbackSurvey = createAction(
  '[my360] Force Survey Start',
  props<{ id: number }>(),
);
export const forceStartFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Force Survey Start Success',
);

export const forceStopFeedbackSurvey = createAction(
  '[my360] Force Survey Stop',
  props<{ id: number }>(),
);
export const forceStopFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Force Survey Stop Success',
);

export const forceRemindersFeedbackSurvey = createAction(
  '[my360] Force Survey Reminders',
  props<{ id: number }>(),
);
export const forceRemindersFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Force Survey Reminders Success',
);

// Coach actions
export const updateCoachForFeedbackSurvey = createAction(
  '[my360] Add Coach to FeedbackSurvey',
  props<{ surveyId: number; coach: TeamMember | null }>(),
);
export const updateCoachForFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Add Coach to FeedbackSurvey Success',
  props<UpdateNum<FeedbackSurvey>>(),
);
export const deleteCoachForFeedbackSurvey = createAction(
  '[my360] Delete Coach of FeedbackSurvey',
  props<{ surveyId: number }>(),
);
export const deleteCoachForFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Delete Coach of FeedbackSurvey Success',
  props<UpdateNum<FeedbackSurvey>>(),
);
export const markReportReadForFeedbackSurvey = createAction(
  '[Feedback Report] Mark Report Read',
  props<{ surveyId: number }>(),
);
export const shareReportForFeedbackSurvey = createAction(
  '[my360] Share Report of FeedbackSurvey',
  props<{ surveyId: number }>(),
);
export const shareReportForFeedbackSurveySuccess = createAction(
  '[FeedbackSurvey/API] Share Report of FeedbackSurvey Success',
  props<UpdateNum<FeedbackSurvey>>(),
);

// Manage actions
export const loadManageFeedbackSurveys = createAction(
  '[Feedback manage 360] Load Manage FeedbackSurveys',
  props<{
    options: {
      statuses?: FeedbackSurveyStatus[];
      reviewee?: string;
      coach?: string;
      pollingDate?: { from?: string; to?: string };
    };
    metaOptions: {
      orderBy?: {
        property: string;
        direction: CompareOrderDirections;
      }[];
      skip: number;
      limit: number;
    };
  }>(),
);
export const loadManageFeedbackSurveysSuccess = createAction(
  '[FeedbackSurvey/API] Load Manage FeedbackSurveys Success',
  props<{
    surveys: FeedbackSurvey[];
    meta: { orderBy: OrderBy[]; skip: number; limit: number; total: number };
  }>(),
);
export const getCompareSurveys = createAction(
  '[Feedback manage 360] Get Manage FeedbackSurveys Count',
  props<{
    endDates: { from?: string; to?: string };
    role: FeedbackCompareRoleFiltersEnum;
    limit?: number;
  }>(),
);
export const getCompareSurveysSuccess = createAction(
  '[FeedbackSurvey/API] Get Manage FeedbackSurveys Count Success',
  props<{
    surveys: FeedbackSurvey[];
  }>(),
);

export const getCompareSurveysFromSelectedSegments = createAction(
  '[Feedback compare 360] Get Compare FeedbackSurveys From Selected Segments',
  props<{
    dates: { from?: string; to?: string };
    role: FeedbackCompareRoleFiltersEnum;
    segmentsIds: number[];
    limit?: number;
  }>(),
);

export const getCompareSurveysFromSelectedSegmentsSuccess = createAction(
  '[FeedbackSurvey/API] Get Manage FeedbackSurveys From Selected Segments Success',
  props<{
    surveys: FeedbackSurvey[];
  }>(),
);

export const getManageFeedbackSurveysCount = createAction(
  '[Feedback manage 360] Get Manage FeedbackSurveys Count',
  props<{
    options: {
      statuses?: FeedbackSurveyStatus[];
      reviewee?: string;
      coach?: string;
      pollingDate?: { from?: string; to?: string };
    };
  }>(),
);
export const getManageFeedbackSurveysCountSuccess = createAction(
  '[FeedbackSurvey/API] Get Manage FeedbackSurveys Count Success',
  props<{
    count: number;
  }>(),
);
export const sendRevieweeReminderFeedbackSurvey = createAction(
  '[manage 360] Send Reviewee Reminders',
  props<{ surveyId: number }>(),
);
export const sendRevieweeReminderFeedbackSurveySuccess = createAction(
  '[manage 360] Send Reviewee Reminders Success',
);
export const sendCoachReminderFeedbackSurvey = createAction(
  '[manage 360] Send Coach Reminders',
  props<{ surveyId: number }>(),
);
export const sendCoachReminderFeedbackSurveySuccess = createAction(
  '[manage 360] Send Coach Reminders Success',
);

// Errors
export const feedbackSurveyFailure = createAction(
  '[FeedbackSurvey/API] FeedbackSurvey Failure',
  props<{ error: Error }>(),
);
