import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IType } from '../models/meeting-type.interface';

/**
 * * All actions from component in admin meeting type *
 */
const MeetingTypesActions = createActionGroup({
  source: 'Meeting Types [Compo]',
  events: {
    'Load Meeting Types': emptyProps(),
  },
});

/**
 * * All actions rised by api in admin meeting type *
 */
const MeetingTypesApiActions = createActionGroup({
  source: 'Meeting Type(s) [API]',
  events: {
    'Load Meeting Types Success': props<{
      meetingTypes: IType[];
    }>(),
  },
});

/**
 * * Action rised when error occured in api for admin meeting type *
 */
const MeetingTypesErrorAction = createActionGroup({
  source: 'Meeting Type(s) [API]',
  events: {
    'Error Meeting Type': props<{ error: Error }>(),
  },
});

/**
 * * Export all actions in single object *
 */
export { MeetingTypesActions, MeetingTypesApiActions, MeetingTypesErrorAction };
