import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MeetingActions from '../actions/meeting.actions';
import { EMeetingStatuses, IMeeting } from '../models/meeting.model';

const getNextDeadline = (meeting: IMeeting) => {
  switch (EMeetingStatuses[meeting.status]) {
    case EMeetingStatuses.CREATED:
    case EMeetingStatuses.STARTED:
      return meeting.startDate || null;
    case EMeetingStatuses.CLOSED_BY_BOTH:
      return meeting.endDate || null;
    default:
      return null;
  }
};

export const startedMeetingsFeatureKey = 'startedMeetings';

export interface State extends EntityState<IMeeting> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<IMeeting> = createEntityAdapter<IMeeting>({
  sortComparer: (a, b) => {
    const deadlineA = getNextDeadline(a);
    const deadlineB = getNextDeadline(b);

    if (deadlineA === deadlineB) {
      return EMeetingStatuses[a.status] < EMeetingStatuses[b.status] ? 1 : -1;
    } else if (deadlineA === null) {
      return 1;
    } else if (deadlineB === null) {
      return -1;
    }
    return deadlineA < deadlineB ? -1 : 1;
  },
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    MeetingActions.createMeeting,
    MeetingActions.updateMeeting,
    MeetingActions.deleteMeeting,
    MeetingActions.loadStartedMeetings,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    MeetingActions.createMeetingSuccess,
    MeetingActions.updateMeetingSuccess,
    MeetingActions.deleteMeetingSuccess,
    (state) => ({ ...state, loading: false, isLoaded: true }),
  ),
  on(MeetingActions.getMeetingUpdatedPropertiesSuccess, (state, action) =>
    adapter.updateOne(action, { ...state, loading: false }),
  ),
  on(MeetingActions.loadStartedMeetingsSuccess, (state, action) =>
    adapter.setAll(action.meetings, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
  on(MeetingActions.meetingFailure, (state) => ({
    ...state,
    loading: false,
  })),
);
