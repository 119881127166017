import { UpdateNum } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { FeedbackCategory } from '../models/category.model';

export const loadCategories = createAction(
  '[Admin Feedback Questions] Load Categories',
);
export const loadCategoriesSuccess = createAction(
  '[Category/API] Load Categories Success',
  props<{ categories: FeedbackCategory[] }>(),
);

export const addCategory = createAction(
  '[Admin Feedback Questions] Add Category',
  props<{ category: Omit<FeedbackCategory, 'id'> }>(),
);
export const addCategorySuccess = createAction(
  '[Category/API] Add Category Success',
  props<{ category: FeedbackCategory }>(),
);

export const upsertCategory = createAction(
  '[Admin Feedback Questions] Upsert Category',
  props<{ category: FeedbackCategory }>(),
);
export const upsertCategorySuccess = createAction(
  '[Category/API] Upsert Category Success',
  props<{ category: FeedbackCategory }>(),
);

export const updateCategory = createAction(
  '[Admin Feedback Questions] Update Category',
  props<{ category: FeedbackCategory }>(),
);
export const updateCategorySuccess = createAction(
  '[Category/API] Update Category Success',
  props<{ category: UpdateNum<FeedbackCategory> }>(),
);

export const deleteCategory = createAction(
  '[Admin Feedback Questions] Delete Category',
  props<{ id: number }>(),
);
export const deleteCategorySuccess = createAction(
  '[Category/API] Delete Category Success',
  props<{ id: number }>(),
);
