import { createSelector } from '@ngrx/store';
import { adapter } from '../reducers/meeting-type.reducer';
import { selectMeetingTypeState } from './meeting.selectors';

export const getMeetingTypeState = createSelector(
  selectMeetingTypeState,
  (meeting) => meeting.types,
);

export const {
  selectIds,
  selectEntities,
  selectAll: SelectAllMeetingTypes,
  selectTotal,
} = adapter.getSelectors(getMeetingTypeState);

export const selectMeetingTypeLoadingState = createSelector(
  getMeetingTypeState,
  (state) => state.loading,
);

export const selectMeetingTypeIsLoadedState = createSelector(
  getMeetingTypeState,
  (state) => state.isLoaded,
);

export const selectMeetingTypeById = (id: string) =>
  createSelector(SelectAllMeetingTypes, (types) =>
    types.find((t) => t.id === id),
  );
