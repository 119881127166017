import { UpdateNum, UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import {
  EParticipantRoles,
  ICampaignResult,
  ICreateCampaign,
  ICreateMeeting,
  IMeeting,
  IMeetingResult,
  IPatchMeeting,
} from '../models/meeting.model';

export const loadStartedMeetings = createAction(
  '[MeetingManage] Load started Meetings',
);
export const loadStartedMeetingsSuccess = createAction(
  '[MeetingManage/API] Load started Meetings Success',
  props<{ meetings: IMeeting[] }>(),
);

export const loadIdleMeetings = createAction(
  '[MeetingManage] Load idle Meetings',
);
export const loadIdleMeetingsSuccess = createAction(
  '[MeetingManage/API] Load idle Meetings Success',
  props<{ meetings: IMeeting[] }>(),
);

export const loadMultipleMeetings = createAction(
  '[MeetingManage] Load Multiple Meetings',
  props<{ ids: string[] }>(),
);

export const loadMultipleMeetingsSuccess = createAction(
  '[MeetingManage/API] Load Multiple Meetings Success',
  props<{ meetings: IMeetingResult[] }>(),
);

export const loadEndedMeetings = createAction(
  '[MeetingManage] Load ended Meeting',
);
export const loadEndedMeetingsSuccess = createAction(
  '[MeetingManage/API] Load ended Meetings Success',
  props<{ meetings: IMeeting[] }>(),
);
export const refreshEndedMeetings = createAction(
  '[MeetingManage/API] Refresh ended Meetings Success',
  props<{ meetings: IMeeting[] }>(),
);
export const allEndedMeetingsLoaded = createAction(
  '[MeetingManage/API] All Ended Meetings Loaded',
);

export const getMeeting = createAction(
  '[Meeting] Get Meeting',
  props<{ meetingId: string }>(),
);
export const getMeetingSuccess = createAction(
  '[MeetingManage/API] Get Meeting Success',
  props<{ meeting: IMeetingResult }>(),
);

// CRUD
export const createMeeting = createAction(
  '[Meeting Create] Create Meeting',
  props<{
    meeting: ICreateMeeting;
  }>(),
);

export const createCampaign = createAction(
  '[Campaign Create] Create Campaign',
  props<{
    campaign: ICreateCampaign;
  }>(),
);

export const createMeetingSuccess = createAction(
  '[MeetingManage/API] Create Meeting Success',
  props<{ meeting: IMeetingResult }>(),
);

export const createCampaignSuccess = createAction(
  '[CampaignManage/API] Create Campaign Success',
  props<{ campaign: ICampaignResult }>(),
);

export const addEndedMeeting = createAction(
  '[MeetingManage/API] Add Ended Meeting',
  props<{ meeting: IMeeting }>(),
);

export const updateMeeting = createAction(
  '[Meeting Create] Update Meeting',
  props<{
    meetingId: string;
    meeting: IPatchMeeting;
  }>(),
);
export const updateMeetingSuccess = createAction(
  '[MeetingManage/API] Update Meeting for Success',
  props<{
    updatedMeeting: UpdateStr<IMeetingResult>;
    meeting: IMeetingResult;
  }>(),
);

export const changeMeetingRevieweeMeeting = createAction(
  '[MeetingManage Create] Change Reviewee for Other',
  props<{ meetingId: string; meeting: IPatchMeeting }>(),
);

export const changeMeetingRevieweeMeetingSuccess = createAction(
  '[MeetingManage/API] Update Meeting for Other Success',
  props<{ meeting: IPatchMeeting }>(),
);

export const deleteMeeting = createAction(
  '[MeetingManage] Delete Meeting',
  props<{ id: string }>(),
);
export const deleteMeetingSuccess = createAction(
  '[MeetingManage/API] Delete Meeting Success',
  props<{ id: string }>(),
);

export const getMeetingUpdatedProperties = createAction(
  '[Meeting] Get Meeting Updated properties',
  props<{ id: string; properties: Array<keyof IMeeting> }>(),
);
export const getMeetingUpdatedPropertiesSuccess = createAction(
  '[Meeting] Get Meeting Updated properties Success',
  props<UpdateStr<IMeeting>>(),
);

export const deleteCoachForMeetingSuccess = createAction(
  '[MeetingManage/API] Delete Coach of Meeting Success',
  props<UpdateNum<IMeeting>>(),
);

// Manage actions
export const loadManageMeetings = createAction(
  '[MeetingManage] Load Manage Meeting',
);
export const loadManageMeetingsSuccess = createAction(
  '[MeetingManage/API] Load Manage Meeting Success',
  props<{
    meetings: IMeetingResult[];
  }>(),
);
export const getCompareMeetings = createAction(
  '[MeetingManage] Get Manage Meeting Count',
  props<{
    endDates: { from?: string; to?: string };
    role: EParticipantRoles;
  }>(),
);
export const getCompareMeetingsSuccess = createAction(
  '[MeetingManage/API] Get Manage Meetings Count Success',
  props<{
    meetings: IMeetingResult[];
  }>(),
);

export const getManageMeetingsCount = createAction(
  '[MeetingManage] Get Manage Meeting Count',
);
export const getManageMeetingsCountSuccess = createAction(
  '[MeetingManage/API] Get Manage Meeting Count Success',
  props<{
    count: number;
  }>(),
);

export const forceStartMeeting = createAction(
  '[MeetingManage/API] Force Start Meeting',
  props<{
    meetingId: string;
  }>(),
);

export const forceStartMeetingSuccess = createAction(
  '[MeetingManage/API] Force Start Meeting Success',
);

// Errors
export const meetingFailure = createAction(
  '[Meeting/API] Meeting Failure',
  props<{ error: Error }>(),
);

// Errors
export const campaignFailure = createAction(
  '[Campaign/API] Campaign Failure',
  props<{ error: Error }>(),
);
