import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[success-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div
      class="custom-toast shadow-xl border-l-4 border-green-4 flex items-start mb-2.5"
    >
      <svg-icon
        icon="toast-success"
        class="solid text-base text-green-4 mr-2.5"
      ></svg-icon>

      <div class="grow" style="overflow: hidden">
        <h5 class="mb-1.25">
          {{ title || 'STATUS.' + successPresets.title | transloco }}
        </h5>
        <p *ngIf="message">{{ message }}</p>
        <ng-content #download></ng-content>
      </div>
      <i
        class="material-icons remove-btn text-2xl bigger-hitbox"
        (click)="close($event)"
        >clear</i
      >
    </div>
  `,
  animations: [toastAnim],
})
export class SuccessToastComponent extends Toast {
  @Input() toastId: number;
  @Input() icon: string;
  successPresets: ToastPreset;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.successPresets = toastPresets.success;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
