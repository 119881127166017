import { createAction, props } from '@ngrx/store';
import {
  UserNotification,
  NotificationContent,
} from '@models/notifications.model';
import { BleexoModule } from '@models/permissions.model';

export const loadNotificationsChunk = createAction(
  '[Notifications] Load New Chunk',
  props<{ chunkSize: number }>(),
);
export const loadNotificationsSuccess = createAction(
  '[Notifs/API] Load Notifications Success',
  props<{ meta: { total: number }; notifications: UserNotification[] }>(),
);
export const loadNotificationsFailure = createAction(
  '[Notifs/API] Load Notifications Failure',
  props<{ error: any }>(),
);
export const allNotificationsLoaded = createAction(
  '[Notifs/API] All Notifications Loaded',
);

export const refreshNotifications = createAction(
  '[Notifications Polling] Refresh Notifications',
  props<{ chunkSize: number }>(),
);
export const refreshNotificationsSuccess = createAction(
  '[Notifs/API] Refresh Notifications Success',
  props<{
    notifications: UserNotification[];
    total: number;
    unreadCount: number;
  }>(),
);
export const initNotifPolling = createAction('[Notifications] Init Polling');
export const stopNotifPolling = createAction('[Notifications] Stop Polling');
export const requestNotifPolling = createAction(
  '[Notifications] Request Polling',
);
export const startNotifPolling = createAction('[Notifications] Start Polling');

export const loadUnreadNotificationsCount = createAction(
  '[Notifications] Load Unread Count',
);
export const loadUnreadNotificationsCountSuccess = createAction(
  '[Notifs/API] Load Unread Count Success',
  props<{ count: number }>(),
);

// Notification Page
export const notificationClicked = createAction(
  '[Notifications Page] Notif Clicked',
  props<{ notification: UserNotification }>(),
);
export const markAsRead = createAction(
  '[Notifications Page] Mark as read',
  props<{ id: number }>(),
);
export const markMultipleAsRead = createAction(
  '[Notifications Page] Mark multiple as read by id',
  props<{
    ids?: number[];
    modules?: BleexoModule[];
    content?: NotificationContent;
  }>(),
);

export const markAllAsRead = createAction(
  '[Notifications Page] Mark all as read',
);

// Notification Widget
export const markAllAsReadWidget = createAction(
  '[Notifications Widget] Mark all as read',
);

// Mark As Read Successes
export const markAsReadSuccess = createAction(
  '[Notifs/API] Mark as read Success',
  props<{ id: number }>(),
);
export const markMultipleAsReadSuccess = createAction(
  '[Notifs/API] Mark multiple as read by id Success',
  props<{ ids: number[] }>(),
);
export const markAllAsReadSuccess = createAction(
  '[Notifs/API] Mark all as read Success',
);

export const initGenerationNotif = createAction(
  '[init Download Notification] Show toast for generation in progress',
  props<{ eventId: number }>(),
);

export const readyToDownloadNotif = createAction(
  '[ready to Download Notification] Show toast for successful download',
  props<{
    eventId: number;
    downloadableFile: {
      blob: Blob;
      fileName: string;
    };
    bleexoModule;
  }>(),
);

export const readyToDownloadNotifSuccess = createAction(
  '[ready to Download Notification Success] Show toast for successful download',
);
