import { UserNotification } from '@models/notifications.model';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/notifications.actions';

export const notificationsFeatureKey = 'notifications';

export interface NotificationsState {
  notifications: UserNotification[];
  unreadCount: number;
  total: number;
}

export const initialState: NotificationsState = {
  notifications: null,
  unreadCount: null,
  total: null,
};

export const reducer = createReducer(
  initialState,
  on(
    fromActions.loadNotificationsSuccess,
    (state, { meta, notifications }) => ({
      ...state,
      notifications: (state.notifications || []).concat(notifications),
      total: meta.total,
    }),
  ),
  on(fromActions.loadUnreadNotificationsCountSuccess, (state, { count }) => ({
    ...state,
    unreadCount: count,
  })),
  on(
    fromActions.refreshNotificationsSuccess,
    (state, { notifications, unreadCount, total }) => ({
      ...state,
      notifications,
      total,
      unreadCount,
    }),
  ),
  on(fromActions.markAsReadSuccess, (state, { id }) => ({
    ...state,
    notifications: state.notifications.map((n) =>
      n.id === id ? { ...n, seen: true } : n,
    ),
    unreadCount: state.unreadCount - 1,
  })),
  on(fromActions.markMultipleAsReadSuccess, (state, { ids }) => ({
    ...state,
    notifications: state.notifications.map((n) =>
      ids.includes(n.id) ? { ...n, seen: true } : n,
    ),
  })),
  on(fromActions.markAllAsReadSuccess, (state) => ({
    ...state,
    notifications: state.notifications.map((n) => ({
      ...n,
      seen: true,
    })),
    unreadCount: 0,
  })),
);
