import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromSolicitations from '../actions/solicitation.actions';
import { SolicitationsService } from '@core/services/solicitations.service';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class SolicitationsEffects {
  constructor(
    private actions$: Actions,
    private solicitations: SolicitationsService,
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSolicitations.loadSolicitations),
      exhaustMap((_) =>
        this.solicitations.loadAll().pipe(
          map((solicitations) =>
            fromSolicitations.loadSolicitationsSuccess({ solicitations }),
          ),
          catchError((error) =>
            of(fromSolicitations.loadSolicitationsFailure({ error })),
          ),
        ),
      ),
    ),
  );
}
