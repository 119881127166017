import { Injectable } from '@angular/core';
import { showError } from '@core/store/actions/toast.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { FeedbackParticipantsService } from '../../services/feedback-participants.service';
import * as fromParticipants from '../actions/feedback-participant.actions';
import { getFeedbackSurveyUpdatedProperties } from '../actions/feedback-survey.actions';

@Injectable()
export class FeedbackParticipantsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private participants: FeedbackParticipantsService,
  ) {}

  getParticipantsForSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipants.getFeedbackParticipants),
      exhaustMap(({ surveyId }) =>
        this.participants.get(surveyId).pipe(
          map((participants) =>
            fromParticipants.getFeedbackParticipantsSuccess({
              participants,
              surveyId,
            }),
          ),
        ),
      ),
      catchError((error: Error) => {
        this.store.dispatch(
          fromParticipants.feedbackParticipantsFailure({ error }),
        );
        return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
      }),
    ),
  );
  addParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromParticipants.addFeedbackParticipants),
      exhaustMap(({ surveyId, participantsPayload, participants }) =>
        this.participants.update(surveyId, participantsPayload).pipe(
          map((_) =>
            fromParticipants.addFeedbackParticipantsSuccess({
              surveyId,
              participants,
            }),
          ),
          tap((_) =>
            this.store.dispatch(
              getFeedbackSurveyUpdatedProperties({
                id: surveyId,
                properties: ['status', 'participation'],
              }),
            ),
          ),
        ),
      ),
      catchError((error: Error) => {
        this.store.dispatch(
          fromParticipants.feedbackParticipantsFailure({ error }),
        );
        return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
      }),
    ),
  );
}
