import { Injectable } from '@angular/core';
import { selectmatrixOrgaRollupFF } from '@core/store/selectors/core.selectors';
import { selectFiltersForAPICalls } from '@core/store/selectors/filters.selectors';
import { Filters } from '@models/filters.model';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FiltersBaseService {
  private _currentFilters;
  private _matrixOrgaRollupFF;

  constructor(public store: Store) {
    this.store
      .pipe(
        select(selectFiltersForAPICalls),
        filter((filters) => !!filters?.scope?.type),
      )
      .subscribe((filters) => {
        this._currentFilters = filters;
      });
    this.store
      .select(selectmatrixOrgaRollupFF)
      .subscribe(
        (matrixOrgaRollupFF) => (this._matrixOrgaRollupFF = matrixOrgaRollupFF),
      );
  }

  get matrixOrgaRollupFF(): boolean {
    return this._matrixOrgaRollupFF;
  }

  get filtersObject(): { filters: Filters } {
    return { filters: this._currentFilters };
  }
  get filters(): Filters {
    return this._currentFilters;
  }

  set filters(filters: Filters) {
    this._currentFilters = filters;
  }

  get filtersAsRawParams() {
    // used when we need to stringify filters with qs
    const raw = {
      scope: {
        type: null,
      },
      segmentation: [],
    };
    if (this.filters?.scope) {
      raw.scope = this.filters.scope;
    }
    if (this.filters.segmentation) {
      raw.segmentation = this.filters.segmentation;
    }

    return raw;
  }
}
