import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLayout from '../reducers/layout.reducer';

export const selectLayoutState = createFeatureSelector<fromLayout.LayoutState>(
  fromLayout.layoutFeatureKey,
);

export const selectShowBanner = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutState) => state.showBanner,
);

export const selectIsMobile = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutState) => state.isMobile,
);

export const selectLayoutScope = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutState) => state.scope,
);

export const selectLayoutId = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutState) => state.scope?.id,
);
