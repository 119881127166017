import { Injectable } from '@angular/core';
import { selectShowBanner } from '@core/store/selectors/layout.selectors';
import { BleexoLocale } from '@models/bleexo.models';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(private store: Store) {}

  pick<T>(obj: T, props: string[]): Partial<T> {
    // Return an object with only the selected arguments that are not null
    return Object.assign(
      {},
      ...props.map((prop) => {
        if (obj[prop]) {
          return { [prop]: obj[prop] };
        }
      }),
    );
  }
  generateHexaArray(arr, itemsPerTurn) {
    return arr.map((item, i) => {
      const turn = Math.floor(i / itemsPerTurn);
      const slices = Math.ceil(arr.length / itemsPerTurn);
      const h =
        (Math.ceil((i * 360) / itemsPerTurn) +
          Math.ceil((turn * 360) / slices) +
          200) %
        360;
      const s = 97;
      const l = 45;
      const hexa = this.hslToHex(h, s, l);
      return hexa;
    });
  }

  hslToHex(h: number, s: number, l: number) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r;
    let g;
    let b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  formatPromoters(driverData) {
    if (!driverData.confidentialityThresholdReached) {
      return driverData;
    }
    const totalScores = driverData.distribution.reduce((a, b) => a + b);
    const repartition = driverData.distribution.map(
      (score) => score / totalScores,
    );

    const formattedRepartition = driverData.distribution;

    const detractors = repartition.slice(0, 7).reduce((a, b) => a + b);
    const passives = repartition[7] + repartition[8];
    const promotors = repartition[9] + repartition[10];

    const formattedPromoters = Math.ceil(
      ((formattedRepartition[9] + formattedRepartition[10]) * 100) /
        totalScores,
    );
    const formattedPassives = Math.floor(
      ((formattedRepartition[7] + formattedRepartition[8]) * 100) / totalScores,
    );
    const formattedDetractors = 100 - formattedPromoters - formattedPassives;
    return {
      ...driverData,
      repartition,
      detractors,
      passives,
      promotors,
      formattedPromoters,
      formattedPassives,
      formattedDetractors,
    };
  }

  // TODO fix the need to pass the locales as args
  filterItemsLocalized<T extends any[]>(
    items: T,
    filter: { search: string; path: string[] },
    companyLocale: BleexoLocale,
    userLocale: BleexoLocale,
  ): T {
    const filteredItems = items.filter((item) => {
      const propertyToCheck = filter.path.reduce(
        (value, entry) => value[entry],
        item,
      );
      const localized =
        propertyToCheck[userLocale] || propertyToCheck[companyLocale];
      return localized.toLowerCase().includes(filter.search);
    });

    return filteredItems as T;
  }

  // TODO fix the need to pass the locales as args
  sortItems<T extends any[]>(
    items: T,
    path: string[],
    companyLocale: BleexoLocale,
    userLocale: BleexoLocale,
  ): T {
    const sorted = items.sort((a, b) => {
      const propertyToCheckA = path.reduce((value, entry) => value[entry], a);
      const propertyToCheckB = path.reduce((value, entry) => value[entry], b);
      if (propertyToCheckA[userLocale]) {
        return propertyToCheckA[userLocale].localeCompare(
          propertyToCheckB[userLocale],
        );
      } else if (propertyToCheckA[companyLocale]) {
        return propertyToCheckA[companyLocale].localeCompare(
          propertyToCheckB[companyLocale],
        );
      }
      return;
    });
    return sorted as T;
  }

  delayedScrollToId(id: string, timeout = 1000): void {
    this.store
      .pipe(select(selectShowBanner), take(1))
      .subscribe((showBanner) => {
        const yOffset = showBanner ? 400 : 200;
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset -
              yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        }, timeout);
      });
  }

  splitArrayIntoChunks<T extends any[]>(
    inputArray: T,
    maxPerChunk: number,
  ): Array<T> {
    return inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / maxPerChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  generateHexaColorsWithItemIds(
    itemIds: number[],
  ): Array<{ itemId: number; color: string }> {
    const itemColors = this.generateHexaArray(itemIds, 4);
    const colorsByItemId = itemIds.map((itemId) => ({
      itemId,
      color: itemColors[[...itemIds].indexOf(itemId)],
    }));
    return colorsByItemId;
  }
}
