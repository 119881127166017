import {
  selectCompanyDefaultLocale,
  selectUserLocale,
} from '@core/store/selectors/core.selectors';
import { templatesFeatureKey } from '@feedback/store/reducers/template.reducer';
import { BleexoLocale } from '@models/bleexo.models';
import { createSelector } from '@ngrx/store';
import { ITemplate } from '../models/meeting-template.model';
import { meetingTemplateAdapter } from '../reducers/meeting-template.reducer';
import { selectMeetingState } from './meeting.selectors';

export const selectMeetingTemplateState = createSelector(
  selectMeetingState,
  (meeting) => meeting[templatesFeatureKey],
);

export const {
  selectIds: selectMeetingTemplateIds,
  selectEntities: selectMeetingTemplateEntities,
  selectAll: selectAllMeetingTemplates,
  selectTotal: selectMeetingTemplatesTotal,
} = meetingTemplateAdapter.getSelectors(selectMeetingTemplateState);

export const selectMeetingTemplateLoadingState = createSelector(
  selectMeetingTemplateState,
  (state) => state.loading,
);

export const selectMeetingTemplateIsLoadedState = createSelector(
  selectMeetingTemplateState,
  (state) => state.isLoaded,
);

export const getSelectedMeetingTemplateId = createSelector(
  selectMeetingTemplateState,
  (state) => state.selectedTemplateId,
);

export const selectMeetingTemplatesByType = (id: string) =>
  createSelector(selectAllMeetingTemplates, (templates) =>
    templates.filter((t) => t.typeId === id),
  );

export const selectSortedMeetingTemplatesByType = (id: string) =>
  createSelector(
    selectMeetingTemplatesByType(id),
    selectCompanyDefaultLocale,
    selectUserLocale,
    (templates, companyLocale, userLocale) => {
      const sortedQuestions = templates.sort((a, b) => {
        if (a.title[userLocale]) {
          return _sortMeetingTemplatesByTitleLocale(userLocale, a, b);
        } else if (a.title[companyLocale]) {
          return _sortMeetingTemplatesByTitleLocale(companyLocale, a, b);
        }
        return;
      });

      return sortedQuestions;
    },
  );

export const selectFilteredAndSortedMeetingTemplatesByType = (
  id: string,
  searchTerm: string,
) =>
  createSelector(
    selectSortedMeetingTemplatesByType(id),
    selectCompanyDefaultLocale,
    selectUserLocale,
    (templates, companyLocale, userLocale) => {
      const filteredQuestions = templates.filter((t) => {
        const templateTitleLocalized =
          t.title[userLocale] || t.title[companyLocale] || '';
        return templateTitleLocalized
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      return filteredQuestions;
    },
  );

export const selectMeetingTemplateById = (id: string) =>
  createSelector(selectAllMeetingTemplates, (templates) => {
    return (templates ?? []).find((t) => t.id === id);
  });

export const selectMeetingTemplatesByIds = (id: string[]) =>
  createSelector(selectAllMeetingTemplates, (templates) => {
    return (templates ?? []).filter((t) => id.includes(t.id));
  });

const _sortMeetingTemplatesByTitleLocale = (
  locale: BleexoLocale,
  tA: ITemplate,
  tB: ITemplate,
) => {
  return Intl.Collator(locale).compare(tA.title[locale], tB.title[locale]);
};
