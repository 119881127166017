import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as commentsFormActions from '../actions/comments-form.actions';
import * as commentsPageActions from '../actions/comments-page.actions';
import {
  CommentByDriver,
  CommentsMenuSortingTypes,
  CommentsOrderDirection,
  CommentsOrderProperty,
  CommentsSorting,
} from '../models/comments-page.models';

export const commentsByDriversFeatureKey = 'commentsByDrivers';

export interface CommentsByDriverState extends EntityState<CommentByDriver> {
  selectedDriverId: number;
  sortings: CommentsSorting[];
  menuSorting: CommentsMenuSortingTypes;
  commentsReloaded: boolean;
  wordCloudReloaded: boolean;
  shouldLoadChunk: boolean;
  menuReloaded: boolean;
  scores: number[];
}

export const commentsByDriverAdapter: EntityAdapter<CommentByDriver> =
  createEntityAdapter<CommentByDriver>();

export const commentsByDriverInitialState: CommentsByDriverState =
  commentsByDriverAdapter.getInitialState({
    selectedDriverId: null,
    sortings: [
      {
        property: CommentsOrderProperty.Score,
        direction: CommentsOrderDirection.Desc,
      },
      {
        property: CommentsOrderProperty.Date,
        direction: CommentsOrderDirection.Desc,
      },
    ],
    menuSorting: CommentsMenuSortingTypes.Topic,
    commentsReloaded: false,
    wordCloudReloaded: false,
    shouldLoadChunk: false,
    menuReloaded: false,
    scores: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  });

export const commentsByDriverReducer = createReducer(
  commentsByDriverInitialState,
  on(commentsPageActions.loadInitialState, (state, { commentsByDriver }) =>
    commentsByDriverAdapter.addMany(commentsByDriver, {
      ...state,
    }),
  ),
  on(commentsPageActions.removeAllEntities, (state, _) =>
    commentsByDriverAdapter.removeAll(state),
  ),
  on(commentsPageActions.sortMenu, (state, { menuSorting }) => ({
    ...state,
    menuSorting,
    menuReloaded: false,
  })),
  on(commentsPageActions.setMenuReloaded, (state, _) => ({
    ...state,
    menuReloaded: true,
  })),
  on(commentsPageActions.sortComments, (state, { sortings }) => ({
    ...state,
    sortings,
    commentsReloaded: false,
  })),
  on(commentsPageActions.getCount, (state, _) => ({
    ...state,
    menuReloaded: false,
  })),
  on(
    commentsPageActions.getCountSuccess,
    (state, { commentsCountUpdates, scores }) =>
      commentsByDriverAdapter.updateMany(commentsCountUpdates, {
        ...state,
        menuReloaded: true,
        scores: scores || commentsByDriverInitialState.scores,
      }),
  ),
  on(commentsPageActions.getWordCloud, (state, _) => ({
    ...state,
    wordCloudReloaded: false,
  })),
  on(
    commentsPageActions.getWordCloudSuccess,
    (state, { wordCloudUpdate, scores }) =>
      commentsByDriverAdapter.updateOne(wordCloudUpdate, {
        ...state,
        wordCloudReloaded: true,
        scores: scores || commentsByDriverInitialState.scores,
      }),
  ),
  on(commentsPageActions.getComments, (state, _) => ({
    ...state,
    commentsReloaded: false,
    menuReloaded: false,
  })),
  on(
    commentsPageActions.getCommentsSuccess,
    (state, { commentsUpdate, scores, shouldLoadChunk }) =>
      commentsByDriverAdapter.updateOne(commentsUpdate, {
        ...state,
        commentsReloaded: true,
        menuReloaded: true,
        scores: scores || commentsByDriverInitialState.scores,
        shouldLoadChunk,
      }),
  ),
  on(
    commentsPageActions.loadCommentsChunkSuccess,
    (state, { commentsUpdate, scores, shouldLoadChunk }) =>
      commentsByDriverAdapter.updateOne(
        {
          id: commentsUpdate.id,
          changes: {
            comments: state.entities[commentsUpdate.id].comments.concat(
              commentsUpdate.changes.comments,
            ),
          },
        },
        { ...state, scores, shouldLoadChunk },
      ),
  ),
  on(commentsPageActions.setLoadingChunkToFalse, (state, _) => ({
    ...state,
    shouldLoadChunk: false,
  })),
  on(commentsPageActions.selectDriver, (state, { driverId }) => ({
    ...state,
    selectedDriverId: driverId,
    wordCloudReloaded: false,
  })),
  on(commentsFormActions.clearAllSelectedSurveys, (state, _) => ({
    ...state,
    selectedDriverId: null,
  })),
  on(commentsFormActions.updateFormDate, (state, _) => ({
    ...state,
    shouldLoadChunk: false,
    menuReloaded: false,
    ...(state.selectedDriverId ? { selectedDriverId: null } : {}),
  })),
  on(commentsFormActions.updateFormSearch, (state, _) => ({
    ...state,
    menuReloaded: false,
    commentsReloaded: false,
    wordCloudReloaded: false,
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  commentsByDriverAdapter.getSelectors();
