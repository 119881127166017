import {
  UntypedFormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

export class PasswordValidation {
  static matchPasswords(group: UntypedFormGroup) {
    const password = group.controls.password.value;
    const confirm = group.controls.confirmPassword.value;
    return password === confirm ? null : { noMatch: true };
  }
}

export function NoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    // messy but you get the idea
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}

export function NoSemiColonValidator(): ValidatorFn {
  const isSemicolonRegex = /[;]/;
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = isSemicolonRegex.test(control.value || '');
    return forbidden ? { semiColon: true } : null;
  };
}
