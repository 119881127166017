import { Company } from '@models/company.model';
import { CompanyLogo } from '@models/companyLogo.model';
import {
  Segment,
  SegmentType,
  SegmentTypeLegacyStore,
} from '@models/segments.model';
import { User, UserFull } from '@models/user.model';
import { createAction, props } from '@ngrx/store';

// Bootstrap
export const bootstrapApp = createAction(
  '[Auth] Bootstrap App Data',
  props<{ token: string }>(),
);
export const getInitialDataLegacy = createAction(
  '[Auth] Get Inital App Data LEGACY',
  props<{ company: Company; userId: number }>(),
);
export const getInitialData = createAction(
  '[Auth] Get Inital App Data',
  props<{ company: Company; userId: number }>(),
);
export const bootstrapAppSuccess = createAction(
  '[Auth/API] Fetch Bootstrap Data Success',
  props<{
    user: User;
    company: Company;
    segments: Segment[];
    segmentTypes: SegmentType[];
    companyLogo: CompanyLogo;
  }>(),
);
export const bootstrapAppLegacySuccess = createAction(
  '[Auth/API] Fetch Bootstrap Data Legacy Success',
  props<{
    user: UserFull;
    company: Company;
    segments: SegmentTypeLegacyStore[];
    companyLogo: CompanyLogo;
  }>(),
);

// Logout and reset
export const logoutFromNavbar = createAction('[Navbar] Logout');
export const logoutFromMobile = createAction('[Mobile Nav] Logout');
export const logoutAfterError = createAction(
  '[Error] Logout',
  props<{ error: any }>(),
);

export const resetStoreAfterLogout = createAction('[Auth] Reset Store');
export const redirectAfterLogout = createAction('[Auth] Redirect After Logout');
