import { Action, combineReducers } from '@ngrx/store';
import * as fromCategory from './category.reducer';
import * as fromQuestion from './question.reducer';
import * as fromTemplate from './template.reducer';
import * as fromActionableSurvey from './feedback-survey-actionable.reducer';
import * as fromEndedSurvey from './feedback-survey-ended.reducer';
import * as fromParticipants from './feedback-participants.reducer';
import * as fromCampaigns from './feedback-campaigns.reducer';

export const feedbackFeatureKey = 'feedback';

export interface FeedbackState {
  [fromCategory.categoriesFeatureKey]: fromCategory.State;
  [fromQuestion.questionsFeatureKey]: fromQuestion.State;
  [fromTemplate.templatesFeatureKey]: fromTemplate.State;
  [fromActionableSurvey.actionableSurveysFeatureKey]: fromActionableSurvey.State;
  [fromEndedSurvey.endedSurveysFeatureKey]: fromEndedSurvey.State;
  [fromParticipants.participantsFeatureKey]: fromParticipants.State;
  [fromCampaigns.feedbackCampaignsFeatureKey]: fromCampaigns.State;
}

export const initialState: FeedbackState = {
  [fromCategory.categoriesFeatureKey]: fromCategory.initialState,
  [fromQuestion.questionsFeatureKey]: fromQuestion.initialState,
  [fromTemplate.templatesFeatureKey]: fromTemplate.initialState,
  [fromActionableSurvey.actionableSurveysFeatureKey]:
    fromActionableSurvey.initialState,
  [fromEndedSurvey.endedSurveysFeatureKey]: fromEndedSurvey.initialState,
  [fromParticipants.participantsFeatureKey]: fromParticipants.initialState,
  [fromCampaigns.feedbackCampaignsFeatureKey]: fromCampaigns.initialState,
};

export function reducers(state: FeedbackState | undefined, action: Action) {
  return combineReducers({
    [fromCategory.categoriesFeatureKey]: fromCategory.reducer,
    [fromQuestion.questionsFeatureKey]: fromQuestion.reducer,
    [fromTemplate.templatesFeatureKey]: fromTemplate.reducer,
    [fromActionableSurvey.actionableSurveysFeatureKey]:
      fromActionableSurvey.reducer,
    [fromEndedSurvey.endedSurveysFeatureKey]: fromEndedSurvey.reducer,
    [fromParticipants.participantsFeatureKey]: fromParticipants.reducer,
    [fromCampaigns.feedbackCampaignsFeatureKey]: fromCampaigns.reducer,
  })(state, action);
}
