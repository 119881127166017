import { BleexoModule } from '@models/permissions.model';
import { createAction, props } from '@ngrx/store';

export const setCurrentModule = createAction(
  '[Shell] Set Current Module',
  props<{ currentModule: BleexoModule }>(),
);

export const setSurveyModule = createAction(
  '[Surveys] Set Survey Module',
  props<{ surveyModule: BleexoModule }>(),
);
