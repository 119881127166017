import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { selectCompanyLocales } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import { ApiData } from '@models/API.models';
import { BleexoLocale } from '@models/bleexo.models';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, retry, takeUntil } from 'rxjs/operators';
import {
  FeebackCoreQuestion,
  FeedbackTemplatingQuestion,
} from '../store/models/question.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackQuestionService implements OnDestroy {
  TEMPLATING_ENDPOINT_URL = `${environment.API_URL}/feedback/templating/questions`;
  private _companyLocales: BleexoLocale[];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.store
      .pipe(select(selectCompanyLocales), takeUntil(this.unsubscribe$))
      .subscribe((companyLocales) => (this._companyLocales = companyLocales));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadAll(): Observable<FeedbackTemplatingQuestion[]> {
    return this.http
      .get<
        ApiData<FeedbackTemplatingQuestion[], Meta>
      >(this.TEMPLATING_ENDPOINT_URL)
      .pipe(
        retry(1),
        map((apiRes) => {
          apiRes.data.forEach((q) => {
            Object.keys(q.text.reviewee).forEach((locale: BleexoLocale) => {
              if (!this._companyLocales.includes(locale)) {
                delete q.text.reviewee[locale];
                delete q.text.reviewer[locale];
              }
            });
            if (q.type === 'SELECT' || q.type === 'MULTISELECT') {
              q.content.options.forEach((o) => {
                Object.keys(o.text).forEach((locale: BleexoLocale) => {
                  if (!this._companyLocales.includes(locale)) {
                    delete o.text[locale];
                    if (o.description && o.description[locale]) {
                      delete o.description[locale];
                    }
                  }
                });
              });
            }
          });
          return apiRes.data;
        }),
      );
  }
  create(
    question: Omit<FeedbackTemplatingQuestion, 'id'>,
  ): Observable<Pick<FeedbackTemplatingQuestion, 'id' | 'defaultLocale'>> {
    return this.http
      .post<
        ApiData<Pick<FeedbackTemplatingQuestion, 'id' | 'defaultLocale'>, Meta>
      >(this.TEMPLATING_ENDPOINT_URL, question)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  update(
    questionToUpdate: FeedbackTemplatingQuestion,
  ): Observable<Pick<FeedbackTemplatingQuestion, 'id' | 'defaultLocale'>> {
    const { id, ...question } = questionToUpdate;
    return this.http
      .patch<
        ApiData<Pick<FeedbackTemplatingQuestion, 'id' | 'defaultLocale'>, Meta>
      >(`${this.TEMPLATING_ENDPOINT_URL}/${id}`, question)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
  delete(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.TEMPLATING_ENDPOINT_URL}/${id}`)
      .pipe(retry(1));
  }

  getSurveyQuestions(surveyId: number): Observable<FeebackCoreQuestion[]> {
    return this.http
      .get<
        ApiData<FeebackCoreQuestion[], Meta>
      >(`${environment.API_URL}/feedback/core/surveys/${surveyId}/questions`)
      .pipe(
        retry(1),
        map((apiRes) => apiRes.data),
      );
  }
}
