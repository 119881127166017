import {
  CompareTableFiltersEnum,
  FeedbackCompareRoleFiltersEnum,
} from '@feedback/pages/feedback-compare/feedback-compare.component';
import { Meta, OrderBy } from '@models/API.models';
import { CompareOrderBy } from '@models/compare.model';
import { createAction, props } from '@ngrx/store';
import {
  FeedbackCompareSegment,
  FeedbackCompareSurvey,
  FeedbackCompareUser,
} from '../models/compare.model';

export const getFeedbackCompareUsers = createAction(
  '[FeedbackCompare] Get Compare Users',
  props<{
    endDate: { from: string; to: string };
    skip: number;
    limit: number;
    orderBy: CompareOrderBy[];
    roles: FeedbackCompareRoleFiltersEnum[];
  }>(),
);

export const getFeedbackCompareUsersSuccess = createAction(
  '[FeedbackCompare] Get Compare Users Success',
  props<{ users: FeedbackCompareUser[]; meta: Meta }>(),
);

export const getFeedbackCompareSegments = createAction(
  '[FeedbackCompare] Get Compare Segments',
  props<{
    endDate: { from: string; to: string };
    roles: FeedbackCompareRoleFiltersEnum[];
    skip: number;
    limit: number;
    segmentTypeIds: number[];
    segmentIds?: number[];
    orderBy: CompareOrderBy[];
  }>(),
);

export const getFeedbackCompareSegmentsSuccess = createAction(
  '[FeedbackCompare/API] Get Compare Segments Success',
  props<{ segments: FeedbackCompareSegment[]; meta: Meta }>(),
);

export const getFeedbackCompareSurveyList = createAction(
  '[FeedbackCompareExpandedRow] Get Compare Surveys',
  props<{
    comparing: CompareTableFiltersEnum;
    ids: number[];
    endDate: { from: string; to: string };
    skip: number;
    limit: number;
    orderBy: OrderBy[];
    roles: FeedbackCompareRoleFiltersEnum[];
  }>(),
);

export const getFeedbackCompareSurveyListSuccess = createAction(
  '[FeedbackCompare/API] Get Compare Surveys Success',
  props<{
    surveys: FeedbackCompareSurvey[];
    meta: Meta;
  }>(),
);

// Errors
export const feedbackCompareFailure = createAction(
  '[FeedbackCompare/API] FeedbackCompare Failure',
  props<{ error: Error }>(),
);
