import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'horizontal-loader',
  template: `
    <div class="horiz-loader">
      <div class="ball1"></div>
      <div class="ball2"></div>
      <div class="ball3"></div>
    </div>
  `,
  styleUrls: ['./horizontal-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalLoaderComponent {
  constructor() {}
}
