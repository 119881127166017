import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { selectCompanyId } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import { ApiData, Meta } from '@models/API.models';
import { Company, CompanySettings } from '@models/company.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, retry, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminCompanyService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  // TODO, move to companyService and user core/companies
  patchCompany(payload: Partial<CompanySettings>): Observable<boolean> {
    return this.store
      .pipe(
        select(selectCompanyId),
        take(1),
        switchMap((id) =>
          this.http.patch<ApiData<Company, Meta>>(
            `${environment.API_URL}/admin/companies/${id}`,
            payload,
            { observe: 'response' },
          ),
        ),
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }
}
