import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CategoryActions from '../actions/meeting-category.actions';
import { IQuestionCategory } from '../models/meeting-question-category.model';

export const categoriesFeatureKey = 'categories';

export interface MeetingCategoryState extends EntityState<IQuestionCategory> {
  // additional entities state properties
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<IQuestionCategory> =
  createEntityAdapter<IQuestionCategory>();

export const meetingCategoryInitialState: MeetingCategoryState =
  adapter.getInitialState({
    // additional entity state properties
    isLoaded: false,
    loading: false,
  });

export const meetingCategoryReducer = createReducer(
  meetingCategoryInitialState,
  on(
    CategoryActions.MeetingCategoriesActions.addMeetingCategory,
    CategoryActions.MeetingCategoriesActions.updateMeetingCategory,
    CategoryActions.MeetingCategoriesActions.deleteMeetingCategory,
    CategoryActions.MeetingCategoriesActions.loadMeetingCategories,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    CategoryActions.MeetingCategoriesApiActions.addMeetingCategorySuccess,
    (state, { category }) =>
      adapter.addOne(category, { ...state, loading: false }),
  ),
  on(
    CategoryActions.MeetingCategoriesApiActions.updateMeetingCategorySuccess,
    (state, { category }) =>
      adapter.updateOne(category, { ...state, loading: false }),
  ),
  on(
    CategoryActions.MeetingCategoriesApiActions.deleteMeetingCategorySuccess,
    (state, { id }) => adapter.removeOne(id, { ...state, loading: false }),
  ),
  on(
    CategoryActions.MeetingCategoriesApiActions.loadMeetingCategoriesSuccess,
    (state, { categories }) =>
      adapter.setAll(categories, { ...state, loading: false, isLoaded: true }),
  ),
  on(
    CategoryActions.MeetingCategoriesErrorAction.errorMeetingCategory,
    (state) => ({ ...state, loading: false }),
  ),
);
