import { Action, combineReducers } from '@ngrx/store';
import * as fromSegmentTypes from './segment-type-v1.reducer';
import * as fromSegments from './segment-v1.reducer';

export const segmentationLegacyFeatureKey = 'segmentationLegacyNGRX';

export interface SegmentationState {
  [fromSegmentTypes.segmentTypesFeatureKey]: fromSegmentTypes.SegmentTypeState;
  [fromSegments.segmentsFeatureKey]: fromSegments.SegmentState;
}

export const segmentationInitialState: SegmentationState = {
  [fromSegmentTypes.segmentTypesFeatureKey]:
    fromSegmentTypes.segmentTypeInitialState,
  [fromSegments.segmentsFeatureKey]: fromSegments.segmentInitialState,
};

export function reducers(state: SegmentationState | undefined, action: Action) {
  return combineReducers({
    [fromSegmentTypes.segmentTypesFeatureKey]:
      fromSegmentTypes.segmentTypesReducer,
    [fromSegments.segmentsFeatureKey]: fromSegments.segmentsReducer,
  })(state, action);
}
