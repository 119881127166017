import { createAction, props } from '@ngrx/store';

export const showError = createAction(
  '[Error] Show Error Toast',
  props<{ error: string }>(),
);

export const showSuccess = createAction(
  '[Success] Show Success Toast',
  props<{ successMessage: string }>(),
);
