import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { PulseTopic } from '../models/topic.model';

export const loadTopics = createAction('[Pulse Component] Load Topics');

export const loadTopicsSuccess = createAction(
  '[Topic/API] Load Topics Success',
  props<{ topics: PulseTopic[] }>(),
);

export const addTopic = createAction(
  '[Topic/API] Add Topic',
  props<{ topic: PulseTopic }>(),
);

export const upsertTopic = createAction(
  '[Topic/API] Upsert Topic',
  props<{ topic: PulseTopic }>(),
);

export const addTopics = createAction(
  '[Topic/API] Add Topics',
  props<{ topics: PulseTopic[] }>(),
);

export const upsertTopics = createAction(
  '[Topic/API] Upsert Topics',
  props<{ topics: PulseTopic[] }>(),
);

export const updateTopic = createAction(
  '[Topic/API] Update Topic',
  props<{ topic: Update<PulseTopic> }>(),
);

export const updateTopics = createAction(
  '[Topic/API] Update Topics',
  props<{ topics: Update<PulseTopic>[] }>(),
);

export const deleteTopic = createAction(
  '[Topic/API] Delete Topic',
  props<{ id: number }>(),
);

export const deleteTopics = createAction(
  '[Topic/API] Delete Topics',
  props<{ ids: number[] }>(),
);

export const clearTopics = createAction('[Topic/API] Clear Topics');
