import { createSelector } from '@ngrx/store';
import { adapter } from '../reducers/meeting-category.reducer';
import { selectMeetingCategoryState } from './meeting.selectors';

export const getMeetingCategoryState = createSelector(
  selectMeetingCategoryState,
  (meeting) => meeting.categories,
);

export const {
  selectIds,
  selectEntities,
  selectAll: SelectAllCategories,
  selectTotal,
} = adapter.getSelectors(getMeetingCategoryState);

export const selectMeetingCategoryLoadingState = createSelector(
  getMeetingCategoryState,
  (state) => state.loading,
);

export const selectCategoryById = (id: string) =>
  createSelector(SelectAllCategories, (categories) => {
    return (categories ?? []).find((c) => c.id === id);
  });

export const selectMeetingCategoryIsLoadedState = createSelector(
  getMeetingCategoryState,
  (state) => state.isLoaded,
);
