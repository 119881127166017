import { createAction, props } from '@ngrx/store';
import { Permission } from '@models/permissions.model';
import { BleexoLocale, i18nObject } from '@models/bleexo.models';
import { HttpErrorResponse } from '@angular/common/http';
import { Segment } from '@models/segments.model';

export const updateUserPermissions = createAction(
  '[Admin Roles] Update user permissions',
  props<{ permissions: Permission[] }>(),
);

export const updateUserPinnedPulseDriver = createAction(
  '[Pulse] Update user pinned segments',
  props<{ pinnedPulseDriver: number }>(),
);

export const updateUserLocale = createAction(
  '[My Profile] Update User Locale',
  props<{ locale: BleexoLocale }>(),
);

export const updateUserLocaleSuccess = createAction(
  '[Api/Admin] Update User Locale Success',
  props<{ locale: BleexoLocale }>(),
);

export const GDPRConsentPrompt = createAction(
  '[Bootstrap Effect] Ask for GDPR Consent',
  props<{ userId: number; gdprText: i18nObject }>(),
);

export const showGDPRReminder = createAction(
  '[Nav/Mobile Nav] Show Company GDPR',
);

export const GDPRGiveConsent = createAction(
  '[GDPR Consent Modal] GDPR Consent was Given',
  props<{ userId: number }>(),
);
export const GDPRGiveConsentSuccess = createAction(
  '[GDPR Consent Modal] GDPR Consent Success',
);

export const updateUserFailure = createAction(
  '[Auth/API] Update User Failure',
  props<{
    error: HttpErrorResponse;
  }>(),
);

export const updateUserSegments = createAction(
  '[Admin] Update Current User',
  props<{ segmentIds: number[] }>(),
);

export const updateUserSegmentsSuccess = createAction(
  '[Admin] Update Current User Success',
  props<{ segments: Segment[] }>(),
);
