import {
  SegmentType,
  SegmentTypeSegmentsOrderTypeEnum,
} from '@models/segments.model';
import { UpdateNum } from '@ngrx/entity/src/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EditSegment, UpsertSegmentType } from '../admin-segments.model';

export const AdminSegmentTypesActions = createActionGroup({
  source: 'Admin Segments Page',
  events: {
    'Load Admin Segment Type': emptyProps(),
    'Create Segment Type': props<{
      segmentType: UpsertSegmentType;
    }>(),
    'Update Segment Type': props<{
      segmentType: UpdateNum<SegmentType>;
    }>(),
    'Delete Segment Type': props<{ id: number }>(),
    'Select Segment Type': props<{
      segmentType: SegmentType;
    }>(),
    'Get Can Disable Multi': props<{ id: number }>(),
    'Request Update Admin Segment Type': props<{
      segment?: UpdateNum<EditSegment>;
      segmentType: UpdateNum<SegmentType>;
    }>(),
  },
});

export const AdminSegmentTypeModalActions = createActionGroup({
  source: 'Admin Segment Type Modal',
  events: {
    'Request Change Order Segment Type Modal': props<{
      order: SegmentTypeSegmentsOrderTypeEnum;
    }>(),
    'Close Segment Type Modal': emptyProps(),
  },
});

export const AdminSegmentTypesSuccessActions = createActionGroup({
  source: 'AdminSegments/API',
  events: {
    'Load Admin Segment Type Success': props<{
      segmentTypes: SegmentType[];
    }>(),
    'Create Segment Type Success': props<{
      segmentType: SegmentType;
    }>(),
    'Update Segment Type Success': props<{
      segmentType: UpdateNum<SegmentType>;
    }>(),
    'Update Order Segment Type Success': props<{
      segmentType: UpdateNum<SegmentType>;
    }>(),
    'Delete Segment Type Success': props<{ id: number }>(),
    'Get Can Disable Multi Success': props<{ canDisableMulti: boolean }>(),
    'Reset To Previous Order On Cancel Success': props<{
      segmentType: UpdateNum<SegmentType>;
    }>(),
  },
});
