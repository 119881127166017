import { Comment } from '@models/comments.model';
import { PulseSurveyFullWithStatus } from '@models/pulse.model';
import { WordCloud } from '@models/wordcloud.model';

export enum CommentsMenuSortingTypes {
  Topic = 'topics',
  Comments = 'comments',
}

export enum CommentsOrderProperty {
  Date = 'survey.endDate',
  Score = 'score',
}

export enum CommentsOrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum CommentsDateTypes {
  LastSurvey = 'lastSurvey',
  SurveyIds = 'surveyIds',
  DateRange = 'range',
}

export interface CommentsSorting {
  property: CommentsOrderProperty;
  direction: CommentsOrderDirection;
}
export interface CommentByDriver {
  readonly id: number;
  readonly count: number;
  readonly wordCloud: WordCloud;
  readonly comments: Comment[];
}

export interface CommentsForm {
  surveyList: PulseSurveyFullWithStatus[];
  wordSearch: string;
  dateType: CommentsDateTypes;
  surveyIds?: number[];
  minDate?: string;
  maxDate?: string;
}

export interface CommentsFormOptions {
  wordSearch?: string;
  minDate?: string;
  maxDate?: string;
  surveyIds?: number[];
}
export interface CommentsCount {
  driverId: number;
  count: number;
}

export interface CommentsNpsFilter {
  label: string;
  value: number[] | null;
}
