import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminMeetingQuestionBankPageActions } from '@meeting/store/actions/meeting-question.actions';
import { selectMeetingQuestionLoadingState } from '@meeting/store/selector/meeting-question.selectors';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'blx-admin-meeting-delete-question-modal',
  templateUrl: './admin-meeting-delete-question-modal.component.html',
  styleUrls: [],
})
export class AdminMeetingDeleteQuestionModalComponent {
  loading$ = this.store.pipe(select(selectMeetingQuestionLoadingState));

  constructor(
    public dialogRef: MatDialogRef<AdminMeetingDeleteQuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store,
  ) {}

  protected delete(): void {
    this.store.dispatch(
      AdminMeetingQuestionBankPageActions.deleteMeetingQuestion({
        id: this.data.id,
      }),
    );
  }
}
