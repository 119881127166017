import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  ICreateTemplate,
  IPatchTemplate,
  ITemplateResult,
} from '@meeting/store/models/meeting-template.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeetingTemplateService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/templates`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<ITemplateResult[]> {
    return this.http.get<ITemplateResult[]>(this._ENDPOINT_URL);
  }

  create(template: ICreateTemplate): Observable<ITemplateResult> {
    return this.http.post<ITemplateResult>(this._ENDPOINT_URL, template);
  }

  patch(
    templateId: string,
    templateToPatch: IPatchTemplate,
  ): Observable<ITemplateResult> {
    return this.http.patch<ITemplateResult>(
      `${this._ENDPOINT_URL}/${templateId}`,
      templateToPatch,
    );
  }

  delete(id: string): Observable<ITemplateResult> {
    return this.http.delete<ITemplateResult>(`${this._ENDPOINT_URL}/${id}`);
  }
}
