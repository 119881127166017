import { FiltersScopeLayout } from '@models/filters.model';
import { createReducer, on } from '@ngrx/store';
import { LayoutActions, LayoutSuccessActions } from '../actions/layout.actions';
// import * as LayoutActions from '../actions/layout.actions'

export const layoutFeatureKey = 'layout';

export interface LayoutState {
  isMobile: boolean;
  showBanner: boolean;
  scope?: FiltersScopeLayout;
}

export const initialState: LayoutState = {
  isMobile: false,
  showBanner: false,
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.showBanner, (state) => ({
    ...state,
    showBanner: false,
  })),
  on(LayoutActions.setMobileView, (state, { isMobile }) => ({
    ...state,
    isMobile,
  })),
  on(LayoutActions.hideBanner, (state) => ({
    ...state,
    showBanner: false,
  })),
  on(LayoutSuccessActions.saveScopeSuccess, (state, { scope }) => ({
    ...state,
    scope: {
      ...state.scope,
      ...scope,
    },
  })),
  on(LayoutSuccessActions.getScopeSuccess, (state, { scope }) => ({
    ...state,
    scope: {
      ...state.scope,
      ...scope,
    },
  })),
);
