import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MeetingActions from '../actions/meeting.actions';
import { IMeeting } from '../models/meeting.model';

export const endedMeetingsFeatureKey = 'endedMeetings';

export interface State extends EntityState<IMeeting> {
  // additional entities state properties
  total: number;
  isLoaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<IMeeting> = createEntityAdapter<IMeeting>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  total: null,
  skip: 0,
  isLoaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(MeetingActions.loadEndedMeetings, (state) => ({
    ...state,
    loading: true,
  })),
  on(MeetingActions.loadEndedMeetingsSuccess, (state, { meetings }) =>
    adapter.addMany(meetings, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
  on(MeetingActions.refreshEndedMeetings, (state, { meetings }) =>
    adapter.setAll(meetings, {
      ...state,
      loading: false,
      isLoaded: true,
    }),
  ),
  on(MeetingActions.meetingFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(MeetingActions.addEndedMeeting, (state, action) =>
    adapter.addOne(action.meeting, { ...state, loading: false }),
  ),
);
