import { XOR } from '@models/API.models';
import { WordCloudParams } from '@models/wordcloud.model';
import { createAction, props } from '@ngrx/store';
import { CommentsForm } from '../models/comments-page.models';

export const postForm = createAction(
  '[Pulse Comments] Post Form',
  props<{ driverIds: number[]; commentsForm: CommentsForm }>(),
);

export const updateFormSearch = createAction(
  '[Pulse Comments] Update Form Search',
  props<{
    driverId: number | null;
    scores: number[];
    options: WordCloudParams;
  }>(),
);

export const updateFormDate = createAction(
  '[Pulse Comments] Update Form Date',
  props<{
    updates: XOR<
      { surveyIds: number[] },
      { minDate?: string; maxDate?: string }
    >;
  }>(),
);

export const clearAllSelectedSurveys = createAction(
  '[Pulse Comments] Clear all selected Surveys',
);
