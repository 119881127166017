import { Injectable } from '@angular/core';
import { selectUserLocale } from '@core/store/selectors/core.selectors';
import { BleexoLocale } from '@models/bleexo.models';
import { select, Store } from '@ngrx/store';
import {
  addDays,
  addHours,
  areIntervalsOverlapping,
  differenceInCalendarMonths,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  formatDistanceToNowStrict,
  formatISO,
  getMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
  subISOWeekYears,
  subMonths,
  subWeeks,
} from 'date-fns';
import {
  enUS,
  fr,
} from 'date-fns/esm/locale';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  private _datesLocales: { [locale in BleexoLocale]: Locale };
  private _dateLocale: BleexoLocale;
  private _overrideUserLocale = false;

  constructor(private store: Store) {
    this._datesLocales = {
      en: enUS,
      fr,
    };
  }

  // PARSING
  parseISO(date: string): Date {
    return parseISO(date);
  }

  // LOCALES
  getDateLocale(): Locale {
    // returns the local date object so it can be used with date-fns, ex :
    // "locale: this.datesService.getDateLocale()"
    if (!this._overrideUserLocale) {
      this.store
        .pipe(select(selectUserLocale), take(1))
        .subscribe((l) => (this._dateLocale = l));
    }

    return this._datesLocales[this._dateLocale] || this._datesLocales.fr;
  }
  // should ONLY be used to override the current users locale for example in PDF exports
  setDateLocale(locale: BleexoLocale): void {
    this._overrideUserLocale = true;
    this._dateLocale = locale;
  }

  // FORMATTING
  formatDate(date: string | Date, dateFormat: string): string {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return format(date, dateFormat, {
      locale: this.getDateLocale(),
    });
  }

  formatDateWithLocale(
    date: string | Date,
    dateFormat: string,
    locale: string,
  ): string {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return format(date, dateFormat, {
      locale: this._datesLocales[locale],
    });
  }

  // COMPARISONS
  isBefore(date: string | Date, dateToCompare: string | Date): boolean {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    if (typeof dateToCompare === 'string') {
      dateToCompare = this.parseISO(dateToCompare);
    }
    return isBefore(date, dateToCompare);
  }
  isAfter(date: string | Date, dateToCompare: string | Date): boolean {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    if (typeof dateToCompare === 'string') {
      dateToCompare = this.parseISO(dateToCompare);
    }
    return isAfter(date, dateToCompare);
  }
  isSameDay(date: string | Date, dateToCompare: string | Date): boolean {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    if (typeof dateToCompare === 'string') {
      dateToCompare = this.parseISO(dateToCompare);
    }
    return isSameDay(date, dateToCompare);
  }
  isBetween(
    date: string | Date,
    start: string | Date,
    end: string | Date,
  ): boolean {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    if (typeof start === 'string') {
      start = this.parseISO(start);
    }
    if (typeof end === 'string') {
      end = this.parseISO(end);
    }
    return isWithinInterval(date, { start, end });
  }

  // HOUR HELPERS
  addHours(date: string | Date, amount: number): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return addHours(date, amount);
  }

  // DAY HELPERS
  startOfDay(date: string | Date): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return startOfDay(date);
  }
  endOfDay(date: string | Date): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return endOfDay(date);
  }
  addDays(date: string | Date, amount: number): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return addDays(date, amount);
  }
  subDays(date: string | Date, amount: number): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return subDays(date, amount);
  }
  differenceInDays(date: string | Date, dateToCompare: string | Date): number {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    if (typeof dateToCompare === 'string') {
      dateToCompare = this.parseISO(dateToCompare);
    }
    return differenceInDays(date, dateToCompare);
  }

  // WEEK HELPERS
  subWeeks(date: string | Date, amount: number): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return subWeeks(date, amount);
  }

  // MONTHS HELPERS
  subMonths(date: string | Date, amount: number): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return subMonths(date, amount);
  }
  getMonth(date: Date | string): number {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return getMonth(date);
  }
  startOfMonth(date: Date | string): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return startOfMonth(date);
  }
  endOfMonth(date: Date | string): Date {
    if (typeof date === 'string') {
      date = this.parseISO(date);
    }
    return endOfMonth(date);
  }
  getLocalizedMonth(month) {
    return format(month, 'LLL');
  }
  differenceInCalendarMonths(date1: Date, date2: Date): number {
    return differenceInCalendarMonths(date1, date2);
  }
  isSameMonth(date1: Date | number, date2: Date | number): boolean {
    return isSameMonth(date1, date2);
  }

  subISOWeekYears(date: Date, weeksToSubstract: number): Date {
    return subISOWeekYears(date, weeksToSubstract);
  }

  // ISO HELPERS
  toIsoString(date: Date): string {
    return this.addHours(date, 12).toISOString().slice(0, 10);
  }
  toIsoStringWithHours(date: Date): string {
    return date.toISOString();
  }
  formatISO(
    date: Date,
    representation: 'date' | 'complete' = 'complete',
  ): string {
    // complete returns an ISO date with hours, ex: '2019-09-18T19:00:52Z'
    // date returns an ISO date, ex: '2019-09-18'
    return formatISO(date, { representation });
  }

  // FORMAT DISTANCE
  formatDistanceToNow(date: string) {
    return formatDistanceToNowStrict(new Date(date), {
      locale: this.getDateLocale(),
    });
  }

  // OVERLAPS
  areIntervalsOverlapping(
    start1: string | Date,
    end1: string | Date,
    start2: string | Date,
    end2: string | Date,
  ): boolean {
    if (typeof start1 === 'string') {
      start1 = this.parseISO(start1);
    }
    if (typeof end1 === 'string') {
      end1 = this.parseISO(end1);
    }
    if (typeof start2 === 'string') {
      start2 = this.parseISO(start2);
    }
    if (typeof end2 === 'string') {
      end2 = this.parseISO(end2);
    }
    return areIntervalsOverlapping(
      { start: start1, end: end1 },
      { start: start2, end: end2 },
    );
  }
}
