import { Component, HostBinding, Input } from '@angular/core';
/*
USE :
        <svg-icon icon="{{ icon name }}" class="{{ type: 'solid' | 'line' }} {{ classes }}"></svg-icon>
*/
@Component({
  selector: 'svg-icon',
  template: `
    <svg viewBox="0 0 24 24" [ngStyle]="{ color: color }">
      <use attr.xlink:href="assets/img/icon-defs.svg#{{ icon }}"></use>
    </svg>
  `,
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
  @Input() icon: string;
  @Input() color?: string;
  @HostBinding('attr.viewBox') viewbox = '0 0 24 24';
}
