import { Injectable } from '@angular/core';
import { showError } from '@core/store/actions/toast.actions';
import { FeedbackCompareService } from '@feedback/services/feedback-compare.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromCompare from '../actions/feedback-compare.actions';

@Injectable()
export class FeedbackCompareEffects {
  constructor(
    private actions$: Actions,
    private compare: FeedbackCompareService,
    private store: Store,
  ) {}

  compareUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompare.getFeedbackCompareUsers),
      switchMap(({ endDate, roles, skip, limit, orderBy }) => {
        return this.compare
          .compareUsers(endDate, roles, orderBy, skip, limit)
          .pipe(
            map(({ users, meta }) =>
              fromCompare.getFeedbackCompareUsersSuccess({
                users,
                meta,
              }),
            ),
            catchError((error: Error) => {
              this.store.dispatch(
                fromCompare.feedbackCompareFailure({ error }),
              );
              return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
            }),
          );
      }),
    ),
  );

  compareSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompare.getFeedbackCompareSegments),
      switchMap(({ type, ...options }) => {
        return this.compare.compareSegments(options).pipe(
          map(({ segments, meta }) =>
            fromCompare.getFeedbackCompareSegmentsSuccess({
              segments,
              meta,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromCompare.feedbackCompareFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );

  loadCompareSurveyChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompare.getFeedbackCompareSurveyList),
      switchMap(({ type, ...options }) => {
        return this.compare.loadSurveyChunk(options).pipe(
          map(({ surveys, meta }) =>
            fromCompare.getFeedbackCompareSurveyListSuccess({
              surveys,
              meta,
            }),
          ),
          catchError((error: Error) => {
            this.store.dispatch(fromCompare.feedbackCompareFailure({ error }));
            return of(showError({ error: 'ALERTS.GENERIC_ERROR' }));
          }),
        );
      }),
    ),
  );
}
