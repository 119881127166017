import { PulseSurveyFullWithStatus } from '@models/pulse.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as PulseSurveyActions from '../actions/pulse-survey.actions';

export const pulseSurveysFeatureKey = 'surveys';

export interface PulseSurveyState
  extends EntityState<PulseSurveyFullWithStatus> {
  // additional entities state properties
  loaded: boolean;
  loading: boolean;
}

export const pulseSurveyAdapter: EntityAdapter<PulseSurveyFullWithStatus> =
  createEntityAdapter<PulseSurveyFullWithStatus>();

export const pulseSurveyInitialState: PulseSurveyState =
  pulseSurveyAdapter.getInitialState({
    // additional entity state properties
    loaded: false,
    loading: false,
  });

export const pulseSurveyReducer = createReducer(
  pulseSurveyInitialState,
  on(
    PulseSurveyActions.loadPulseSurveysForAdmin,
    PulseSurveyActions.loadPulseSurveysForComments,
    PulseSurveyActions.loadPulseSurveysForDiagnostics,
    PulseSurveyActions.loadPulseSurveysForExcel,
    PulseSurveyActions.loadPulseSurveysForPowerpoint,
    PulseSurveyActions.loadPulseSurveysForPDF,
    (state) => ({
      ...state,
      loading: state.loaded ? false : true,
    }),
  ),

  on(PulseSurveyActions.loadPulseSurveysSuccess, (state, { surveys }) =>
    pulseSurveyAdapter.addMany(surveys, {
      ...state,
      loading: false,
      loaded: true,
    }),
  ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  pulseSurveyAdapter.getSelectors();
