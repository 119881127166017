import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { bleexoLocales } from '@core/constants';
import {
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(langPath: string) {
    if (langPath) {
      return this.http.get<Translation>(`/assets/i18n/${langPath}.json`);
    } else {
      return this.http.get<Translation>('/assets/i18n/fr.json');
    }
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: bleexoLocales,
        defaultLang: 'fr',
        reRenderOnLangChange: true,
        fallbackLang: ['fr'],
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: true,
          useFallbackTranslation: true,
          allowEmpty: false,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
