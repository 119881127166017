import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { i18nObject } from '@models/bleexo.models';

@Component({
  templateUrl: './gdpr-rules-modal.component.html',
})
export class GdprRulesModalComponent {
  constructor(
    public dialogRef: MatDialogRef<GdprRulesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { gdprText: i18nObject },
  ) {}
}
