import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BleexoLocale } from '@models/bleexo.models';
import { Company } from '@models/company.model';
import { CompanyLogo } from '@models/companyLogo.model';
import { Observable, of } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private _http: HttpClient) {}

  getCompany(companyId: number): Observable<Company> {
    return this._http
      .get<Company>(`${environment.API_URL}/core/companies/${companyId}`)
      .pipe(
        switchMap((company: Company) => {
          if (!company) return of(company);
          return this.getCompanyLocales(company.id).pipe(
            map((companyLocales) => {
              const companyWithLocales: Company = {
                ...company,
                locales:
                  companyLocales.length > 2 ? companyLocales : ['fr', 'en'],
              };
              return companyWithLocales;
            }),
          );
        }),
      );
  }

  getCompanyLogo(companyId: number): Observable<CompanyLogo> {
    return this._http
      .get<CompanyLogo>(
        `${environment.API_URL}/core/companies/${companyId}/logo`,
      )
      .pipe(retry(1));
  }

  getCompanyLocales(companyId: number): Observable<BleexoLocale[]> {
    return this._http.get<BleexoLocale[]>(
      `${environment.API_URL}/core/companies/${companyId}/locales`,
    );
  }
}
