import { Action, combineReducers, createReducer, on } from '@ngrx/store';
import * as fromSegmentTypes from './segment-type.reducer';
import * as fromSegments from './segment.reducer';
import * as fromFilters from '../../actions/filters.actions';

export const segmentationFeatureKey = 'segmentation';

export interface SegmentationState {
  [fromSegmentTypes.segmentTypesFeatureKey]: fromSegmentTypes.SegmentTypeState;
  [fromSegments.segmentsFeatureKey]: fromSegments.SegmentState;
  loadingSegments: boolean;
}

export const segmentationInitialState: SegmentationState = {
  [fromSegmentTypes.segmentTypesFeatureKey]:
    fromSegmentTypes.segmentTypeInitialState,
  [fromSegments.segmentsFeatureKey]: fromSegments.segmentInitialState,
  loadingSegments: false,
};

const segmentationStateReducer = createReducer(
  segmentationInitialState.loadingSegments,
  on(fromFilters.FiltersActions.getSegmentsUsersCount, (_state) => true),
  on(
    fromFilters.FiltersSuccessActions.getSegmentsUsersCountSuccess,
    (_state) => false,
  ),
);

export function reducers(state: SegmentationState | undefined, action: Action) {
  return combineReducers({
    [fromSegmentTypes.segmentTypesFeatureKey]:
      fromSegmentTypes.segmentTypesReducer,
    [fromSegments.segmentsFeatureKey]: fromSegments.segmentsReducer,
    loadingSegments: segmentationStateReducer,
  })(state, action);
}
