import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { selectLocales } from '@core/store/selectors/core.selectors';
import { BleexoLocale, i18nObject } from '@models/bleexo.models';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'localize',
})
@Injectable({
  providedIn: 'root',
})
export class LocalizePipe implements PipeTransform, OnDestroy {
  private _companyLocale: BleexoLocale;
  private _userLocale: BleexoLocale;
  private _unsubscribe$ = new Subject<void>();

  constructor(private store: Store) {
    this.store
      .pipe(select(selectLocales), takeUntil(this._unsubscribe$))
      .subscribe(([companyLocale, _userLocale]) => {
        this._companyLocale = companyLocale;
        this._userLocale = _userLocale;
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  transform(translations: i18nObject, snapshot?: BleexoLocale): string {
    if (typeof translations === 'string') {
      return translations;
    }

    if (
      translations === null ||
      !translations ||
      Object.keys(translations).length === 0
    ) {
      return;
    }

    return (
      translations[snapshot] ||
      translations[this._userLocale] ||
      translations[this._companyLocale] ||
      Object.values(translations)[0]
    );
  }
}
