import { Injectable } from '@angular/core';
import { PermissionsService } from '@core/services/permissions.service';
import { FiltersDepthEnum, FiltersScopeTypeEnum } from '@models/filters.model';
import { User } from '@models/user.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { LayoutService } from '@shared/services/layout.service';
import { PopulationService } from '@shared/services/population.service';
import { SegmentsService } from '@shared/services/segments.service';
import { UsersService } from '@shared/services/users.service';
import { EMPTY, combineLatest, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  FiltersActions,
  FiltersSuccessActions,
} from '../actions/filters.actions';
import { LayoutActions, LayoutSuccessActions } from '../actions/layout.actions';
import { showError, showSuccess } from '../actions/toast.actions';
import {
  selectUser,
  selectmatrixOrgaRollupFF,
} from '../selectors/core.selectors';
import { selectFiltersForAPICalls } from '../selectors/filters.selectors';
import { selectLayoutScope } from '../selectors/layout.selectors';
@Injectable()
export class FiltersEffects {
  constructor(
    private _actions$: Actions,
    private _population: PopulationService,
    private _segments: SegmentsService,
    private _layoutService: LayoutService,
    private _users: UsersService,
    private _permissions: PermissionsService,
    private _store: Store,
  ) {}

  getFiltersPopulationCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        FiltersActions.initializeFilters,
        FiltersActions.resetSegmentsFilter,
        FiltersActions.setScopeToDefaultSuccess,
        FiltersActions.setScopeDepth,
        FiltersActions.setScopeType,
        FiltersActions.setScopeManager,
        FiltersActions.setSelectedSegmentsForType,
      ),
      switchMap(() => this._store.pipe(select(selectmatrixOrgaRollupFF))),
      filter((matrixOrgaRollupFF) => matrixOrgaRollupFF),
      switchMap((_) => {
        return this._population
          .forFilters()
          .pipe(
            map((count) =>
              FiltersSuccessActions.getFiltersPopulationCountSuccess({ count }),
            ),
          );
      }),
    ),
  );

  getSegmentsUsersCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        FiltersActions.initializeFilters,
        FiltersActions.resetSegmentsFilter,
        FiltersActions.setScopeToDefaultSuccess,
        FiltersActions.setScopeDepth,
        FiltersActions.setScopeType,
        FiltersActions.setScopeManager,
        FiltersActions.setSelectedSegmentsForType,
      ),
      switchMap(() => {
        return combineLatest([
          this._store.pipe(select(selectmatrixOrgaRollupFF)),
          this._store.select(selectFiltersForAPICalls),
        ]);
      }),
      filter(([matrixOrgaRollupFF, _filters]) => matrixOrgaRollupFF),
      switchMap(([_matrixOrgaRollupFF, filters]) => {
        this._store.dispatch(FiltersActions.getSegmentsUsersCount());
        return this._segments.getSegmentsUsersCount$(filters).pipe(
          map((segmentsUsersCount) =>
            FiltersSuccessActions.getSegmentsUsersCountSuccess({
              segmentsUsersCount,
            }),
          ),
        );
      }),
    ),
  );

  setFiltersScopeFromUrl$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FiltersActions.setScopeFiltersFromUrl),
      switchMap(({ scope }) => {
        if (scope.type === 'HIERARCHY' && scope.viewAsUserId) {
          return this._users.get(scope.viewAsUserId).pipe(
            map((user) => {
              return FiltersSuccessActions.setScopeFiltersFromUrlSuccess({
                scope: { ...scope, viewAsUser: user },
              });
            }),
          );
        } else {
          return of(
            FiltersSuccessActions.setScopeFiltersFromUrlSuccess({ scope }),
          );
        }
      }),
    ),
  );

  getFiltersPopulationUsersList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FiltersActions.getPopulationUsersList),
      exhaustMap(({ skip }) =>
        this._users.findUsers(skip).pipe(
          map(({ data, meta }) =>
            FiltersSuccessActions.getPopulationUsersListSuccess({
              usersList: data,
              meta,
            }),
          ),
        ),
      ),
    ),
  );

  getDefaultScope$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LayoutActions.getScope, FiltersActions.initializeFilters),
      switchMap((action) =>
        this._store.pipe(
          select(selectmatrixOrgaRollupFF),
          map((m) => {
            return { action, m };
          }),
        ),
      ),
      filter((actionAndMatrix) => actionAndMatrix.m),
      map((actionAndMatrix) => actionAndMatrix.action),
      switchMap((action) => {
        // get scope from db
        return combineLatest([
          this._layoutService.getScope$(),
          this._store.select(selectLayoutScope),
        ]).pipe(
          take(1),
          switchMap(([scope, currentScope]) => {
            // prevent default scope to populate store if scope is already defined when initialiazing
            if (
              action.type === FiltersActions.initializeFilters.type &&
              !!currentScope
            ) {
              return EMPTY;
            }
            // get current user to check permission to use scope
            return this._store.select(selectUser).pipe(
              switchMap((user) => {
                if (!scope) return EMPTY;
                const scopesUserCanAccess = this._getScopePermissions(user);
                const hasPermission = scopesUserCanAccess.includes(scope.type);

                if (hasPermission) {
                  return of(scope);
                } else {
                  return this._resetScopeNoPermission$(
                    scopesUserCanAccess,
                    user.isManagerOfManagers,
                  );
                }
              }),
              tap((scope) => {
                // reset current scope with default scope
                if (FiltersActions.initializeFilters.type === action.type) {
                  this._store.dispatch(
                    FiltersActions.setScopeToDefaultSuccess({ scope }),
                  );
                }
              }),
            );
          }),
        );
      }),
      // update store
      map((scope) => LayoutSuccessActions.getScopeSuccess({ scope })),
      catchError(() => of(showError({ error: 'ALERTS.GENERIC_ERROR' }))),
    ),
  );

  setDefaultScope$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FiltersActions.setScopeToDefault),
      switchMap(() => this._store.pipe(select(selectmatrixOrgaRollupFF))),
      filter((matrixOrgaRollupFF) => matrixOrgaRollupFF),
      switchMap(() => this._store.select(selectLayoutScope)),
      map((scope) => FiltersActions.setScopeToDefaultSuccess({ scope })),
    ),
  );

  saveDefaultScope$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LayoutActions.saveScope),
      switchMap((action) => this._layoutService.saveScope$(action.scope)),
      map((newScope) =>
        LayoutSuccessActions.saveScopeSuccess({ scope: newScope }),
      ),
      catchError(() => of(showError({ error: 'ALERTS.GENERIC_ERROR' }))),
    ),
  );

  saveDefaultScopeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LayoutSuccessActions.saveScopeSuccess),
      map(() =>
        showSuccess({
          successMessage: 'FILTERS.SCOPE_SAVED_SUCCESS',
        }),
      ),
    ),
  );

  private _getScopePermissions(
    user: User & { isManagerOfManagers: boolean },
  ): FiltersScopeTypeEnum[] {
    const scopesUserCanAccess: FiltersScopeTypeEnum[] = [];

    // give access to scope entreprise && hierarchy if user has permission enterprise
    if (this._permissions.validateOneOfPermissions(['scope:company'])) {
      scopesUserCanAccess.push(FiltersScopeTypeEnum.COMPANY);
      scopesUserCanAccess.push(FiltersScopeTypeEnum.HIERARCHY);
    }

    // give access to scope hierarchy if user is manager
    if (user.isManager) {
      scopesUserCanAccess.push(FiltersScopeTypeEnum.HIERARCHY);
    }

    // give access to scope matrix if user is responsible of at least one segment
    if (user?.managedSegments?.length > 0) {
      scopesUserCanAccess.push(FiltersScopeTypeEnum.MATRIX);
    }
    return scopesUserCanAccess;
  }

  private _resetScopeNoPermission$(
    scopesUserCanAccess: FiltersScopeTypeEnum[],
    userIsManagerOfManager: boolean,
  ) {
    let defaultScope: FiltersScopeTypeEnum;

    if (scopesUserCanAccess.includes(FiltersScopeTypeEnum.COMPANY)) {
      defaultScope = FiltersScopeTypeEnum.COMPANY;
    } else if (scopesUserCanAccess.includes(FiltersScopeTypeEnum.HIERARCHY)) {
      defaultScope = FiltersScopeTypeEnum.HIERARCHY;
    } else if (scopesUserCanAccess.includes(FiltersScopeTypeEnum.MATRIX)) {
      defaultScope = FiltersScopeTypeEnum.MATRIX;
    }

    const defaultDepth =
      defaultScope === FiltersScopeTypeEnum.COMPANY ||
      (defaultScope === FiltersScopeTypeEnum.HIERARCHY &&
        userIsManagerOfManager)
        ? FiltersDepthEnum.TEAMS
        : FiltersDepthEnum.N1;

    return this._layoutService.saveScope$({
      type: defaultScope,
      depth: defaultDepth,
      viewAsUserId: null,
    });
  }
}
