import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromAuth from '../../actions/auth.actions';
import { SegmentTypeLegacyNGRX } from '@models/segments.model';

export const segmentTypesFeatureKey = 'segmentTypes';

export interface SegmentTypeState extends EntityState<SegmentTypeLegacyNGRX> {
  // additional entities state properties
}

export const segmentTypesAdapter: EntityAdapter<SegmentTypeLegacyNGRX> =
  createEntityAdapter<SegmentTypeLegacyNGRX>();

export const segmentTypeInitialState: SegmentTypeState =
  segmentTypesAdapter.getInitialState({
    // additional entity state properties
  });

export const segmentTypesReducer = createReducer(
  segmentTypeInitialState,
  on(fromAuth.bootstrapAppLegacySuccess, (state, action) => {
    const segmentTypes = [];
    action.segments.map((segmentType) =>
      segmentTypes.push({
        id: segmentType.id,
        name: segmentType.name,
        segmentIds: segmentType.segments.map((s) => s.id),
      }),
    );

    return segmentTypesAdapter.setAll(segmentTypes, state);
  }),
);
