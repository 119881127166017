import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  ICreateQuestion,
  IFindQuestion,
  IPatchQuestion,
  IQuestionResult,
} from '@meeting/store/models/meeting-question.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeetingQuestionService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/questions`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<IQuestionResult[]> {
    return this.http.get<IQuestionResult[]>(this._ENDPOINT_URL);
  }

  getOne(questionId: string): Observable<IQuestionResult> {
    return this.http.get<IQuestionResult>(
      this._ENDPOINT_URL + '/' + questionId,
    );
  }

  getQuestionFromCategory(
    questionToFind: IFindQuestion,
  ): Observable<IQuestionResult[]> {
    return this.http.get<IQuestionResult[]>(
      this._ENDPOINT_URL + '/' + questionToFind.categoryId,
    );
  }

  create(question: ICreateQuestion): Observable<IQuestionResult> {
    return this.http.post<IQuestionResult>(this._ENDPOINT_URL, question);
  }

  patch(
    questionId: string,
    questionToPatch: IPatchQuestion,
  ): Observable<IQuestionResult> {
    return this.http.patch<IQuestionResult>(
      `${this._ENDPOINT_URL}/${questionId}`,
      questionToPatch,
    );
  }

  delete(id: string): Observable<IQuestionResult> {
    return this.http.delete<IQuestionResult>(`${this._ENDPOINT_URL}/${id}`);
  }
}
