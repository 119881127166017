import { createReducer, on } from '@ngrx/store';
import { CommentsDateTypes } from '../models/comments-page.models';
import { PulseSurveyFullWithStatus } from '@models/pulse.model';
import * as commentsFormActions from '../actions/comments-form.actions';

export const commentsFormFeatureKey = 'commentsForm';

export interface CommentsFormState {
  readonly surveyList: PulseSurveyFullWithStatus[];
  readonly wordSearch: string;
  readonly dateType: CommentsDateTypes;
  readonly surveyIds: number[];
  readonly minDate: string;
  readonly maxDate: string;
}

export const commentsFormInitialState: CommentsFormState = {
  surveyList: [],
  wordSearch: '',
  dateType: CommentsDateTypes.LastSurvey,
  surveyIds: [],
  minDate: '',
  maxDate: '',
};

export const commentsFormReducer = createReducer(
  commentsFormInitialState,
  on(commentsFormActions.postForm, (state, { commentsForm }) => ({
    ...state,
    surveyList: commentsForm.surveyList,
    wordSearch: commentsForm.wordSearch,
    dateType: commentsForm.dateType,
    surveyIds: commentsForm.surveyIds,
    minDate: commentsForm.minDate,
    maxDate: commentsForm.maxDate,
  })),
  on(commentsFormActions.updateFormSearch, (state, { options }) => ({
    ...state,
    wordSearch: options.search,
  })),
);
